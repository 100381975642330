.mm-listview .mm-next {
  width: 100% !important;
}

.top-menu-loggedin {
  .sub-menu {
    right: 0;
    left: auto;
  }
  .sf-with-ul-pre {
    margin-top: -13px;
  }
  .nav-item-chip {
    .dropdown-toggle::after {
      display: none;
    }
  }
  .chip {
    display: inline-block;
    height: 40px;
    font-size: 17px;
    font-weight: 500;
    line-height: 40px;
    border-radius: 25px;
    max-width: 180px;
    vertical-align: middle;
    //color: $dark-gost;
    @include text-ellipsis();

    img, .media, .letter, .empty {
      float: left;
      margin: 0 12px 0 0;
      height: 40px;
      width: 40px;
      border-radius: 50% !important;
    }
    .media.empty {
      //background: 4px 2px no-repeat #ffffff99 url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiBmaWxsPSIjNDY0NjQ2IiByPSI0Ii8+PHBhdGggZD0iTTIwLDE5djFhMSwxLDAsMCwxLTEsMUg1YTEsMSwwLDAsMS0xLTFWMTlhNiw2LDAsMCwxLDYtNmg0QTYsNiwwLDAsMSwyMCwxOVoiIGZpbGw9IiM0NjQ2NDYiLz48L3N2Zz4=");
      //background-size: 80%;
      background: #254665;
      color: #fff;
      font-size: 20px;
      line-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

    }

    &-sm {
      min-width: 140px;
      max-width: 160px;
      height: 24px;
      img, .letter, .media {
        height: 24px;
        width: 24px;
        margin: 0;
      }
      .media {
        margin: 0 8px 0 0;
      }
      .title, .name {
        font-size: 12px;
        line-height: 12px;
        font-weight: normal;
        display: block;
      }
      .name {
        font-weight: 500;
      }
    }

    &-md {
      min-width: 240px;
      max-width: 300px;
      height: 80px;
      img, .letter, .media {
        height: 80px;
        width: 80px;
        margin: 0;
      }
      .media {
        margin: 0 24px 0 0;
      }
      .name {
        display: block;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin-top: 13px;
      }
      .title {
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
      }
      .school {
        display: block;
        font-size: 12px;
        color: #8E8E8E;
      }
    }

    &-fl {
      display: flex;
      align-items: center;
      min-width: auto;
      max-width: max-content;
      .titles {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        align-content: flex-start;
      }
      .name, .title, .school {
        margin: 1px 0;
        padding: 0;
        line-height: normal;
      }
    }
  }
  .profile-card {
    height: 70px;
    padding: 23px 1.5rem;
    min-width: 250px;
    box-sizing: content-box;
    .media {
      width: 70px;
      height: 70px;
      display: inline-block;
      margin: 0 21px 0 0;
      border-radius: .25rem;
      float: left;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
      }
      &.empty {
        //background: center center no-repeat #d2d2d2 url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiBmaWxsPSIjNDY0NjQ2IiByPSI0Ii8+PHBhdGggZD0iTTIwLDE5djFhMSwxLDAsMCwxLTEsMUg1YTEsMSwwLDAsMS0xLTFWMTlhNiw2LDAsMCwxLDYtNmg0QTYsNiwwLDAsMSwyMCwxOVoiIGZpbGw9IiM0NjQ2NDYiLz48L3N2Zz4=);
        //background-size: 80%;
        background: #254665;
        color: #fff;
        font-size: 20px;
        line-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    strong {
      display: block;
      font-weight: 700;
      font-size: 17px;
      line-height: 15px;
      margin-bottom: 23px;
      color: #bebebe;
    }
    span {
      display: block;
      font-weight: normal;
      font-size: 12px;
      line-height: 1;
      margin-bottom: 15px;
    }
    a {
      padding: 0.4rem 1.4rem;
      background-color: #bebebe !important;
      color: #000 !important;
      border-radius: 5px;
      font-weight: bold;
      &:hover {
        background-color: #fff !important;
      }
    }
  }
}
