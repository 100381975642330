/**
 * Table of Contents
 *
 * 1.0 - Reset
 * 2.0 - Header
 * 3.0 - Footer
 * 4.0 - Blog
 * 5.0 - Widget
 * 6.0 - Responsive
 * 7.0 - Infinite
 * 	   	- mmenu
 *		- superfish
 */

/**
 * 1.0 - Reset
 */

html,body,div,span,applet,object,iframe,
h1,h2,h3,h4,h5,h6,p,blockquote,
pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,
s,samp,small,strike,strong,sub,sup,tt,var,b,i,center,dl,dt,dd,ol,ul,li,
fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,
article,aside,audio,canvas,details,figcaption,figure,footer,header,hgroup,
mark,menu,meter,nav,output,progress,section,summary,time,video{ border:0; outline:0;
	vertical-align:baseline; background:transparent; margin:0;padding:0; }
article,aside,dialog,figure,footer,header,hgroup,nav,section,blockquote{ display:block; }
nav ul{ list-style:none; }
ol{ list-style:decimal; }
ul{ list-style:disc; }
ul ul{ list-style:circle; }
blockquote,q{ quotes:none; }
blockquote:before,blockquote:after,q:before,q:after{ content:none; }
ins{ text-decoration:underline; }
del{ text-decoration:line-through; }
mark{ background:none; }
abbr[title],dfn[title], acronym[title]{ border-bottom:1px dotted; cursor:help; }
table{ border-collapse:collapse;border-spacing:0; }
hr{ display:block; height:1px; border:0; border-top:1px solid #ccc; margin:1em 0; padding:0;}
input[type=submit],input[type=button],button{ margin:0; padding:0;}
input,select,a img{ vertical-align:middle; }
b, strong{ font-weight: bold; }
i, em{ font-style: italic; }

html{ box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; -moz-box-sizing: inherit; -webkit-box-sizing: inherit; }

/* generic class */
body{ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; word-wrap: break-word; word-break: break-word; }
p{ margin-bottom: 20px;  }
ul, ol{ margin-left: 35px; margin-bottom: 20px; }
dl{ margin-bottom: 20px; }
dl dt{ font-weight: 700; margin-top: 10px; }
dl dt:first-child{ margin-top: 0; }
dl dd{ margin-left: 20px; }
h1, h2, h3, h4, h5, h6{ line-height: 1.2; margin-bottom: 20px; }
figure{ max-width: 100%; }
pre{ overflow: auto; padding: 10px 10px; background: #f3f3f3; margin-bottom: 20px; }

.clear{ display: block; clear: both; visibility: hidden; line-height: 0; height: 0; zoom: 1; }
.clearfix{ zoom: 1; }
.clearfix:after{ content: " "; display: block; clear: both; visibility: hidden; line-height: 0; height: 0; }

/* wordpress core */
.alignnone{ margin: 5px 20px 20px 0; }
.aligncenter, div.aligncenter{ display: block; margin: 5px auto 5px auto; }
.alignright{ float:right; margin: 5px 0 20px 20px; }
.alignleft{ float: left; margin: 5px 20px 20px 0; }
a img.alignright{ float: right; margin: 5px 0 20px 20px; }
a img.alignnone{ margin: 5px 20px 20px 0; }
a img.alignleft{ float: left; margin: 5px 20px 20px 0; }
a img.aligncenter{ display: block; margin-left: auto; margin-right: auto }
.wp-caption { text-align: center; max-width: 100%; }
.wp-caption.alignnone{ margin: 5px 20px 20px 0; }
.wp-caption.alignleft{ margin: 5px 20px 20px 0; }
.wp-caption.alignright { margin: 5px 0 20px 20px; }
.wp-caption img { border: 0 none; height: auto; margin: 0; max-width: 98.5%; padding: 0; width: auto; }
.wp-caption p.wp-caption-text { font-size: 11px; line-height: 17px; margin: 0; padding: 0 4px 5px; }
figcaption.wp-caption-text {  margin-top: 20px; }
.bypostauthor{ max-width: 100%;  }
.gallery-caption{ max-width: 100%; }
.sticky{ max-width: 100%;  }

.single-format-gallery figcaption.wp-caption-text{ margin-bottom: 20px; }
.single-format-gallery figure.gallery-item{ margin-bottom: 20px; }

.navigation{ text-align: right; margin-bottom: 30px; }
.navigation .screen-reader-text{ display: none; }

.post-password-form input[type="password"]{ padding: 14px 20px; margin-left: 5px; margin-right: 6px; }

body .page-links > a,
body .nav-links > a, body .nav-links > span.dots{ background-color: #f0f0f0; color: #919191; }
body .page-links > span, body .page-links > a:hover,
body .nav-links > span.current, body .nav-links > a:hover{ background-color: #3db166; color: #fff; }

.page-links{ clear: left; padding-top: 30px; }
.page-links > span,
.page-links > a{ text-decoration: none; display: inline-block; font-size: 14px; padding: 9px 14px;
	margin-right: 7px; font-weight: bold;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; }

.nav-links{ clear: left; }
.nav-links > span,
.nav-links > a{ text-decoration: none; display: inline-block; font-size: 14px; padding: 9px 14px;
	margin-right: 3px; font-weight: bold;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; }

body .nav-links a{ text-transform: uppercase; font-weight: 600; letter-spacing: 1px; font-size: 13px; }
nav.navigation.comment-navigation{ display: inline-block; width: 100%; }
.nav-links .nav-previous{ float: left; }
.nav-links .nav-next{ float: right; }

.wp-block-gallery{ margin-left: 0; }

.gallery{ text-align: center; margin-bottom: 30px; }
.gallery:after{ content: " "; display: block; clear: both; visibility: hidden; line-height: 0; height: 0; }
.gallery-columns-2 .gallery-item{ float: left; width: 50%; }
.gallery-columns-3 .gallery-item{ float: left; width: 33.33%; }
.gallery-columns-4 .gallery-item{ float: left; width: 25%; }
.gallery-columns-5 .gallery-item{ float: left; width: 20%; }
.gallery-columns-6 .gallery-item{ float: left; width: 16.66%; }
.gallery-columns-7 .gallery-item{ float: left; width: 14.28%; }
.gallery-columns-8 .gallery-item{ float: left; width: 12.5%; }
.gallery-columns-9 .gallery-item{ float: left; width: 11.11%; }
.gallery-columns-10 .gallery-item{ float: left; width: 10%; }

.gallery-columns-2 .gallery-item:nth-child(2n + 1){ clear: left; }
.gallery-columns-3 .gallery-item:nth-child(3n + 1){ clear: left; }
.gallery-columns-4 .gallery-item:nth-child(4n + 1){ clear: left; }
.gallery-columns-5 .gallery-item:nth-child(5n + 1){ clear: left; }
.gallery-columns-6 .gallery-item:nth-child(6n + 1){ clear: left; }
.gallery-columns-7 .gallery-item:nth-child(7n + 1){ clear: left; }
.gallery-columns-8 .gallery-item:nth-child(8n + 1){ clear: left; }
.gallery-columns-9 .gallery-item:nth-child(9n + 1){ clear: left; }
.gallery-columns-10 .gallery-item:nth-child(10n + 1){ clear: left; }

cite{ padding-bottom: 20px; display: block; }
.wp-block-spacer{ clear: both; }
.infinite-body a.wp-block-button__link,
.infinite-body a.wp-block-button__link:hover{ color: #fff; }
.wp-block-media-text{ margin-bottom: 30px; }
.wp-block-media-text .wp-block-media-text__content{ padding-top: 20px; }

/* layout */
.infinite-body-outer-wrapper{ position: relative; overflow: hidden; }
.infinite-body-wrapper.infinite-with-frame{ position: relative; }
.infinite-page-wrapper{ clear: both; padding-top: 145px }
@media only screen and (max-width: 999px){
	.infinite-page-wrapper{ padding-top: 0 }
}
body.infinite-boxed .infinite-body-wrapper{ margin-left: auto; margin-right: auto; position: relative; }
body.infinite-boxed .infinite-body-wrapper.infinite-left{ margin-left: 0; }
body.infinite-boxed .infinite-body-wrapper.infinite-right{ margin-right: 0; }
body.infinite-boxed.infinite-boxed-border .infinite-body-wrapper{
	box-shadow: 0 -5px 9px rgba(0,0,0,0.25); -webkit-box-shadow: 0 -5px 9px rgba(0,0,0,0.25); }

body.infinite-boxed.infinite-background-pattern{ background-position: center; background-repeat: repeat; }
.infinite-body-background{ position: fixed; top: 0; right: 0;
	bottom: 0; left: 0; background-position: center; background-size: cover; }

.infinite-container{ margin-left: auto; margin-right: auto; }

.infinite-column-10{ float: left; width: 16.6666%; }
.infinite-column-12{ float: left; width: 20%; }
.infinite-column-15{ float: left; width: 25%; }
.infinite-column-20{ float: left; width: 33.3333%; }
.infinite-column-24{ float: left; width: 40%; }
.infinite-column-30{ float: left; width: 50%; }
.infinite-column-36{ float: left; width: 60%; }
.infinite-column-40{ float: left; width: 66.6666%; }
.infinite-column-45{ float: left; width: 75%; }
.infinite-column-48{ float: left; width: 80%; }
.infinite-column-50{ float: left; width: 83.3333%; }
.infinite-column-60{ float: left; width: 100%; }

.infinite-sidebar-right{ float: right; }
.infinite-sidebar-center{ float: none; display: inline-block; }

.infinite-content-area{ padding-top: 60px; padding-bottom: 40px; }
.infinite-sidebar-area{ padding-top: 60px; }

.infinite-line-height-0{ line-height: 0; }

a, button{ 	text-decoration: none;
	transition: background 300ms, color 300ms, border-color 300ms; -moz-transition: background 300ms, color 300ms, border-color 300ms;
	-o-transition: background 300ms, color 300ms, border-color 300ms; -webkit-transition: background 300ms, color 300ms, border-color 300ms; }
img{ max-width: 100%; height: auto; }
blockquote{ quotes: none; padding: 30px 30px 15px; font-size: 17px; margin-bottom: 35px;
	font-style: italic; margin-top: 35px; border-left-width: 2px; border-left-style: solid; }
.infinite-blockquote-style-2 blockquote{ font-size: 20px; font-weight: 800; padding-top: 10px; padding-left: 35px; padding-right: 0;
	font-style: normal; padding-bottom: 10px; border-left-width: 4px; }
.infinite-blockquote-style-2 blockquote p{ margin-bottom: 0; }
q{ margin-right: 15px; display: inline-block; padding: 10px 20px; vertical-align: middle; border-left-width: 2px; border-left-style: solid; }

.infinite-media-image{ line-height: 0; position: relative; }
.infinite-left-align{ text-align: left; }
.infinite-center-align{ text-align: center; }
.infinite-right-align{ text-align: right; }

.infinite-body-wrapper{ /* overflow: hidden; */ z-index: 1; position: relative; }

/* input */
input, textarea{ border-width: 1px 1px 2px 1px; border-style: solid; }
input:focus, textarea:focus{ outline: none; }

/* button */
.infinite-button, input[type="button"], input[type="submit"], input[type="reset"]{ font-size: 13px; font-weight: bold;
	text-transform: uppercase; letter-spacing: 1px; padding: 15px 33px; display: inline-block; -webkit-appearance: none; }
input[type="button"], input[type="submit"], input[type="reset"]{ border-width: 0; border-style: solid; cursor: pointer;
	transition: background 300ms, color 300ms, border-color 300ms; -moz-transition: background 300ms, color 300ms, border-color 300ms;
	-o-transition: background 300ms, color 300ms, border-color 300ms; -webkit-transition: background 300ms, color 300ms, border-color 300ms; }
input[type="button"]:focus, input[type="submit"]:focus, input[type="reset"]:focus{ outline: none; }

/**
 * 2.0 - Header
 */
 
/* preload */
.infinite-page-preload{ position: fixed; z-index: 999999; top: 0; right: 0; bottom: 0; left: 0;
	background-color: #fff; /*background-image: url('../images/preload.gif'); */background-position: center; background-repeat: no-repeat; }
.infinite-page-preload.infinite-out{ background-image: none; }

/* top bar */
.infinite-top-bar{ position: relative; border-bottom-style: solid; z-index: 102; }
.infinite-top-bar-background{ position: absolute; right: 0; left: 0; top: 0; bottom: 0; }
.infinite-top-bar-container{ margin-left: auto; margin-right: auto; }
.infinite-top-bar-container-inner{ position: relative; }
.infinite-top-bar-left{ float: left; }

.infinite-top-bar-right{ float: right; }
.infinite-top-bar-right-text{ display: inline; }
.infinite-top-bar-right-social{ display: inline; margin-left: 10px; }
.infinite-top-bar-right-social a{ margin-left: 25px; }

/* top bar menu */
.infinite-top-bar .infinite-top-bar-menu{ display: inline-block; margin-right: 23px; margin-bottom: 0; }
.infinite-top-bar .infinite-top-bar-menu > li{ float: none; display: inline-block; }
.infinite-top-bar .infinite-top-bar-menu > li > a{ display: inline; margin-right: 16px; }
.sf-menu.infinite-top-bar-menu > .infinite-normal-menu ul{ margin-left: 0; font-size: 13px; }
.sf-menu.infinite-top-bar-menu > .infinite-normal-menu:first-child ul{ margin-left: -17px; }
.sf-menu.infinite-top-bar-menu > .infinite-normal-menu ul li {padding: 0 18px 10px;}
.sf-menu.infinite-top-bar-menu > .infinite-normal-menu ul li:first-child{ padding-top: 10px; }
.sf-menu.infinite-top-bar-menu > .infinite-normal-menu ul li > a{ background: transparent;  }
.sf-menu.infinite-top-bar-menu > .infinite-normal-menu ul li > a:after{ margin-left: 10px; }

.sf-menu.infinite-top-bar-menu > .infinite-mega-menu .sf-mega-full{ left: 0; right: 0; padding-top: 18px; padding-bottom: 8px; }
.sf-menu.infinite-top-bar-menu > .infinite-mega-menu .sf-mega-section-inner ul{ font-size: 13px; }
.sf-menu.infinite-top-bar-menu > .infinite-mega-menu .sf-mega-section-inner ul li{ padding: 0 18px 10px; }

/* mobile menu */
.infinite-mm-menu-wrap{ display: none; }
.admin-bar .mm-menu{ margin-top: 32px; }

.infinite-mobile-button-hamburger-with-border{ font-size: 16px; padding: 6px; line-height: 0; width: 32px; height: 32px;
	text-align: center; display: inline-block; border-width: 2px; border-style: solid;
	border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px;
	box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; }

.infinite-right-menu-button{ display: inline-block; margin-left: 27px; }
.infinite-mobile-menu{ float: left; margin-top: -8px; margin-left: 30px; }
.infinite-mobile-menu-right{ position: absolute; right: 30px; top: 50%; margin-top: -9px; }
.infinite-mobile-menu-left{ position: absolute; left: 30px; top: 50%; margin-top: -9px; }
.infinite-mobile-menu-left .infinite-mobile-menu{ margin-left: 0; }
.infinite-mobile-header .infinite-mobile-logo-center .infinite-logo-inner{ margin-left: auto; margin-right: auto; }

/* hamburger */
.infinite-mobile-button-hamburger{ box-sizing: border-box; display: block; width: 32px; height: 32px; position: relative; }
.infinite-mobile-button-hamburger:before,
.infinite-mobile-button-hamburger:after,
.infinite-mobile-button-hamburger span{ content: ''; display: block; width: 22px; height: 2px; position: absolute; left: 5px; }
.infinite-mobile-button-hamburger:before{ top: 8px; }
.infinite-mobile-button-hamburger span{ top: 15px; }
.infinite-mobile-button-hamburger:after{ top: 22px; }

.infinite-mobile-button-hamburger:before{
	transition-duration: 0.4s;
	transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
.infinite-mobile-button-hamburger span{
	transition: opacity 0.15s 0.4s ease; }
.infinite-mobile-button-hamburger:after{
    transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.infinite-mobile-button-hamburger.infinite-active:before{
	-webkit-transform: translate3d(0, 7px, 0) rotate(135deg);
		    transform: translate3d(0, 7px, 0) rotate(135deg); transition-delay: 0.1s; }
.infinite-mobile-button-hamburger.infinite-active span{
    transition-delay: 0s; opacity: 0; }
.infinite-mobile-button-hamburger.infinite-active:after{ transition-delay: 0.1s;
    -webkit-transform: translate3d(0, -7px, 0) rotate(-135deg);
		    transform: translate3d(0, -7px, 0) rotate(-135deg); }

/* navigation menu */
.infinite-navigation .sf-menu{ display: inline-block; line-height: 1.7; text-align: left; margin-bottom: 0; }
.infinite-navigation .sf-menu > li:first-child{ padding-left: 0; }
.infinite-navigation .sf-menu > li:last-child{ padding-right: 0; }
.infinite-navigation .sf-menu > li > a{ letter-spacing: 1px; }
.infinite-navigation-submenu-indicator .sf-menu > li > a.sf-with-ul-pre:after{ display: inline-block; margin-left: 13px;
	content: "\f107"; font-family: fontAwesome; }

.infinite-navigation .sf-menu > .infinite-normal-menu li{ padding: 1px 11px; }
.infinite-navigation .sf-menu > .infinite-normal-menu li:first-child{ padding-top: 12px; }
.infinite-navigation .sf-menu > .infinite-normal-menu li:last-child{ padding-bottom: 12px; }
.infinite-navigation .sf-menu > .infinite-normal-menu li li{ /* padding-left: 0; */ }
.infinite-navigation .sf-menu > .infinite-normal-menu li > a{ display: block; padding: 6px 30px 6px 12px; position: relative; }
.infinite-navigation .sf-menu > .infinite-normal-menu li > a.sf-with-ul-pre:after{ position: absolute; right: 10px;
	content: "\f105"; font-family: fontAwesome; }
.infinite-navigation .sf-menu > .infinite-normal-menu .sub-menu{ font-size: 14px; }

.infinite-navigation .sf-menu > .infinite-mega-menu .sf-mega-full{ left: 0; right: 0; }
.infinite-navigation .sf-menu > .infinite-mega-menu .sf-mega-section-wrap{ overflow: hidden;
	border-top-width: 1px; border-top-style: solid; }
.infinite-navigation .sf-menu > .infinite-mega-menu .sf-mega-section-wrap:first-child{ border-top: none; }
.infinite-navigation .sf-menu > .infinite-mega-menu .sf-mega-section{ padding-bottom: 999px; margin-bottom: -999px;
    border-left-width: 1px; border-left-style: solid;  }
.infinite-navigation .sf-menu > .infinite-mega-menu .sf-mega-section:first-child{ border-left: none; }
.infinite-navigation .sf-menu > .infinite-mega-menu .sf-mega-section-inner{ padding: 31px 28px; }
.infinite-navigation .sf-menu > .infinite-mega-menu .sf-mega-section-inner > a{ font-size: 15px; display: block;
	margin-bottom: 12px; text-transform: uppercase; letter-spacing: 1px; font-weight: bold; padding: 0 12px; }
.infinite-navigation .sf-menu > .infinite-mega-menu .sf-mega-section-inner .sub-menu a{ font-size: 14px; padding: 5px 12px; display: block; }

.infinite-navigation .infinite-navigation-slide-bar{ border-bottom-width: 3px; border-bottom-style: solid; position: absolute;
	display: none; margin-top: -8px; padding-top: 5px; overflow: hidden; }
.infinite-navigation .infinite-navigation-slide-bar:before{ content: " "; display: block; width: 0; height: 0;
    position: absolute; left: 50%; margin-left: -3px; bottom: 0;
    border-width: 0 5px 5px 5px; border-style: solid; border-color: transparent; }

.infinite-navigation .sf-menu a i{ margin-right: 10px; }

.infinite-navigation .sf-vertical > li{ border-bottom-width: 1px; border-bottom-style: solid; }
.infinite-navigation .sf-vertical > li:first-child{ padding-top: 0;  }
.infinite-navigation .sf-vertical > li > a{ font-weight: 800; text-transform: uppercase; letter-spacing: 1px; display: block; }
.infinite-navigation .sf-vertical ul.sub-menu{ font-size: 14px;
	box-sizing: content-box; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; }
.infinite-navigation .sf-vertical ul.sub-menu li{ padding: 2px 11px; }
.infinite-navigation .sf-vertical ul.sub-menu li:first-child{ padding-top: 20px; }
.infinite-navigation .sf-vertical ul.sub-menu li:last-child{ padding-bottom: 20px; }
.infinite-navigation .sf-vertical ul.sub-menu li > a{ display: block; padding: 4px 12px; }
.infinite-navigation .sf-vertical ul.sub-menu li > a.sf-with-ul:after,
.infinite-navigation-submenu-indicator .sf-vertical > li > a.sf-with-ul:after{
	float: right; margin-left: 18px; content: "\f105"; font-family: fontAwesome; }

/* bullet anchor */
.infinite-bullet-anchor{ position: fixed; top: 50%; right: 30px; margin-top: -50px; z-index: 10; opacity: 0;
	transition: opacity 200ms; -moz-transition: opacity 200ms; -o-transition: opacity 200ms; -webkit-transition: opacity 200ms; }
.infinite-bullet-anchor.infinite-init{ opacity: 1; }
.infinite-bullet-anchor a{ display: block; width: 17px; height: 17px; margin-bottom: 5px; overflow: hidden;
	border-width: 1px; border-style: solid; border-color: transparent;
	border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; }
.infinite-bullet-anchor a:before{ content: " "; width: 9px; height: 9px; margin: 3px; display: block; overflow: hidden;
	border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%;
	transition: background-color 200ms; -moz-transition: background-color 200ms;
	-o-transition: background-color 200ms; -webkit-transition: background-color 200ms; }

/* breadcrumbs */
.infinite-breadcrumbs a{ color: #c0c0c0; }
.infinite-breadcrumbs,
.infinite-breadcrumbs a:hover{ color: #777; }

.infinite-breadcrumbs{ font-size: 14px; padding: 17px 0; border-bottom-width: 1px; border-bottom-style: solid; }
.infinite-breadcrumbs-item > span{ margin: 0 22px; }
.infinite-breadcrumbs-item > span:first-child{ margin-left: 0; }
.infinite-breadcrumbs-item a span{ transition: color 200ms; -moz-transition: color 200ms; -o-transition: color 200ms; -webkit-transition: color 200ms; }

/* top search */
.infinite-top-search-wrap input.search-field{ border-bottom-color: #303030; color: #fff; }
.infinite-top-search-wrap .infinite-top-search-submit,
.infinite-top-search-wrap .infinite-top-search-close{ color: #fff; }
.infinite-top-search-wrap input::-webkit-input-placeholder{  color: #fff; }
.infinite-top-search-wrap input::-moz-placeholder{  color: #fff; }
.infinite-top-search-wrap input:-ms-input-placeholder{  color: #fff; }
.infinite-top-search-wrap input:-moz-placeholder{  color: #fff; }

.infinite-top-search-row{ display: table; width: 100%; height: 100%; }
.infinite-top-search-cell{ display: table-cell; vertical-align: middle; padding-left: 30px; padding-right: 30px; }

.infinite-top-search-wrap{ position: fixed; left: 0; right: 0; top: 0; bottom: 0; z-index: 9999; overflow-y: auto; display: none; }
.infinite-top-search-wrap form{ max-width: 630px; margin-left: auto; margin-right: auto; position: relative; }
.infinite-top-search-wrap input.search-field{ background: transparent; width: 100%; border-width: 0 0 3px;
	font-size: 40px; padding: 0 120px 25px 0; line-height: 48px; letter-spacing: 1px; }
.infinite-top-search-wrap input.search-submit{  position: absolute; top: 0; right: 52px;
	width: 48px; height: 48px; padding: 0; opacity: 0; filter: alpha(opacity=0); }
.infinite-top-search-wrap .infinite-top-search-submit{ font-size: 30px; position: absolute; right: 52px; top: 0;
	line-height: 48px; width: 48px; text-align: center; }
.infinite-top-search-wrap .infinite-top-search-close{ font-size: 55px; position: absolute; right: 0; top: -4px;
	line-height: 48px; width: 48px; text-align: center; cursor: pointer; }

/* overlay menu */
.infinite-overlay-menu-row{ display: table; width: 100%; height: 100%; }
.infinite-overlay-menu-cell{ display: table-cell; vertical-align: middle; }

.infinite-overlay-menu-content{ position: fixed; left: 0; right: 0; top: 0; bottom: 0; z-index: 9999; overflow-y: auto; display: none; }
.infinite-overlay-menu-content ul.menu{ list-style: none; text-align: center; padding-top: 180px; padding-bottom: 140px;
	max-width: 570px; margin-left: auto; margin-right: auto; margin-bottom: 0; }
.infinite-overlay-menu-content ul.menu > li{ font-size: 23px; font-weight: bold; text-transform: uppercase; opacity: 0;
	margin-bottom: 17px; padding-bottom: 17px; letter-spacing: 1px; border-bottom-width: 1px; border-bottom-style: solid;
	-webkit-transform: translate3d(0, -30px, 0); transform: translate3d(0, -30px, 0);
	transition: opacity 300ms, transform 300ms; -moz-transition: opacity 300ms, -moz-transform 300ms;
	-o-transition: opacity 300ms, -o-transform 300ms; -webkit-transition: opacity 300ms, -webkit-transform 300ms; }
.infinite-overlay-menu-content.infinite-active ul.menu > li{ opacity: 1; filter: alpha(opacity=100);
	-webkit-transform: none; transform: none; }
.infinite-overlay-menu-content ul.sub-menu{ display: none; padding-top: 25px; padding-bottom: 10px; list-style: none; margin-left: 0; margin-bottom: 0; }
.infinite-overlay-menu-content ul.sub-menu > li{ font-size: 14px; text-transform: none; font-weight: normal; margin-bottom: 15px; }
.infinite-overlay-menu-content ul.sub-menu ul.sub-menu{ max-width: 380px; margin: 25px auto 25px;
    border-top-width: 1px; border-bottom-width: 1px; border-style: solid; }
.infinite-overlay-menu-content .infinite-overlay-menu-close{ line-height: 1; font-size: 48px;
	position: absolute; left: 50%; top: 75px; margin-left: -24px; cursor: pointer; }
.infinite-overlay-menu-content .infinite-overlay-menu-close:after{ content: "\4d"; font-family: elegantIcons; }
.infinite-overlay-menu-content ul li.menu-item-has-children > a:after{ content: "\f107"; font-family: "fontAwesome"; margin-left: 15px; }

body.admin-bar .infinite-overlay-menu-content{ margin-top: 32px; overflow-y: scroll; }

/* menu right area */
.infinite-main-menu-right-button{ float: left; padding: 8px 15px; font-size: 11px; font-weight: bold;
	text-transform: uppercase; letter-spacing: 1px; margin-top: -11px; margin-left: 30px; border-radius: 3px;
	border-width: 2px; border-style: solid; }
.infinite-main-menu-right-button.infinite-style-round{ padding: 13px 21px 14px; margin-top: -14px;
	border-radius: 30px; -moz-border-radius: 30px; -webkit-border-radius: 30px; }
.infinite-main-menu-right-button.infinite-style-round-with-shadow{ padding: 13px 21px 14px; margin-top: -14px;
	box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11); -webkit-box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11);
	border-radius: 30px; -moz-border-radius: 30px; -webkit-border-radius: 30px; }

.infinite-main-menu-right{ float: left; margin-top: -8px; }

.infinite-main-menu-search{ float: left; margin-top: -5px; margin-left: 25px; }
.infinite-main-menu-search i{ font-size: 14px; margin-top: -1px; cursor: pointer; }

.infinite-main-menu-cart{ float: left; margin-top: -4px; padding-left: 20px; margin-left: 5px; position: relative; }
.infinite-main-menu-cart i{ font-size: 16px; margin-top: -1px; cursor: pointer; }
.infinite-main-menu-cart > .infinite-top-cart-count{ font-size: 10px; font-weight: 700; vertical-align: super;
	width: 16px; height: 16px; line-height: 15px; position: absolute; top: -5px; right: -8px; text-align: center;
	border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; }

/* header */
.infinite-logo{ position: relative; }
.infinite-header-container{ position: relative; margin-left: auto; margin-right: auto; }
.infinite-header-container-inner{ position: relative; }

/* header plain */
.infinite-header-style-plain{ position: relative; border-bottom-style: solid; z-index: 101; }
.infinite-header-style-plain .infinite-navigation{ line-height: 0; }
.infinite-header-style-plain .infinite-header-background{ position: absolute; right: 0; left: 0; top: 0; bottom: 0; }
.infinite-header-background-transparent{ position: absolute; left: 0; right: 0; z-index: 3; }

.infinite-header-style-plain.infinite-style-menu-right .infinite-logo{ float: left; }
.infinite-header-style-plain.infinite-style-menu-right .infinite-navigation{ float: right; }
.infinite-header-style-plain.infinite-style-menu-right .infinite-main-menu{ display: inline-block; vertical-align: top; }
.infinite-header-style-plain.infinite-style-menu-right .infinite-main-menu-right-wrap{ display: inline-block; line-height: 1.7; margin-left: 10px; vertical-align: top; }

.infinite-header-style-plain.infinite-style-center-logo .infinite-logo-inner{ margin-left: auto; margin-right: auto; }
.infinite-header-style-plain.infinite-style-center-logo .infinite-navigation{ text-align: center; position: relative; }
.infinite-header-style-plain.infinite-style-center-logo .infinite-main-menu{ display: inline-block; vertical-align: top; }
.infinite-header-style-plain.infinite-style-center-logo .infinite-main-menu-right-wrap{ position: absolute; right: 0; line-height: 1.7; }

.infinite-header-style-plain.infinite-style-center-menu .infinite-logo{ position: absolute; }
.infinite-header-style-plain.infinite-style-center-menu .infinite-navigation{ text-align: center; }
.infinite-header-style-plain.infinite-style-center-menu .infinite-main-menu{ display: inline-block; vertical-align: top; }
.infinite-header-style-plain.infinite-style-center-menu .infinite-main-menu-right-wrap{ position: absolute; right: 0; line-height: 1.7; }

.infinite-header-style-plain.infinite-style-splitted-menu .infinite-logo{ padding-top: 0; padding-bottom: 0; }
.infinite-header-style-plain.infinite-style-splitted-menu .infinite-navigation{ text-align: center; }
.infinite-header-style-plain.infinite-style-splitted-menu .infinite-main-menu{ display: inline-block; vertical-align: top; }
.infinite-header-style-plain.infinite-style-splitted-menu .infinite-main-menu-right-wrap{ position: absolute; right: 0; line-height: 1.7; }
.infinite-header-style-plain.infinite-style-splitted-menu .infinite-main-menu-left-wrap{ position: absolute; left: 0; line-height: 1.7; }
.infinite-header-style-plain.infinite-style-splitted-menu .infinite-right-menu-button{ margin-left: 0; }

/* header box */
.infinite-header-boxed-wrap{ position: absolute; left: 0; right: 0; z-index: 3; }
.infinite-header-style-boxed .infinite-header-container-item{ position: relative; }

.infinite-header-style-boxed .infinite-navigation{ line-height: 0; }
.infinite-header-style-boxed .infinite-header-background{ position: absolute; right: 0; left: 0; top: 0; bottom: 0; }

.infinite-header-style-boxed.infinite-style-menu-right .infinite-logo{ float: left; }
.infinite-header-style-boxed.infinite-style-menu-right .infinite-navigation{ float: right; }
.infinite-header-style-boxed.infinite-style-menu-right .infinite-main-menu{ display: inline-block; vertical-align: top; }
.infinite-header-style-boxed.infinite-style-menu-right .infinite-main-menu-right-wrap{ display: inline-block; line-height: 1.7; margin-left: 10px; vertical-align: top; }

.infinite-header-style-boxed.infinite-style-center-menu .infinite-logo{ position: absolute; }
.infinite-header-style-boxed.infinite-style-center-menu .infinite-navigation{ text-align: center; }
.infinite-header-style-boxed.infinite-style-center-menu .infinite-main-menu{ display: inline-block; vertical-align: top; }
.infinite-header-style-boxed.infinite-style-center-menu .infinite-main-menu-right-wrap{ position: absolute; line-height: 1.7; }

.infinite-header-style-boxed.infinite-style-splitted-menu .infinite-logo{ padding-top: 0; padding-bottom: 0; }
.infinite-header-style-boxed.infinite-style-splitted-menu .infinite-navigation{ text-align: center; }
.infinite-header-style-boxed.infinite-style-splitted-menu .infinite-main-menu{ display: inline-block; vertical-align: top; }
.infinite-header-style-boxed.infinite-style-splitted-menu .infinite-main-menu-right-wrap{ position: absolute; line-height: 1.7; }
.infinite-header-style-boxed.infinite-style-splitted-menu .infinite-main-menu-left-wrap{ position: absolute; line-height: 1.7; }
.infinite-header-style-boxed.infinite-style-splitted-menu .infinite-right-menu-button{ margin-left: 0; }

/* header bar */
.infinite-header-style-bar .infinite-logo{ float: left; }
.infinite-header-style-bar .infinite-logo-right-text{ float: right; text-align: right; font-size: 13px; }
.infinite-header-style-bar .infinite-header-container-inner{ position: relative; }

.infinite-navigation-bar-wrap{ border-top-width: 1px; border-top-style: solid; position: relative; }
.infinite-navigation-bar-wrap.infinite-style-transparent{ position: absolute; left: 0; right: 0; z-index: 3; }
.infinite-navigation-bar-wrap .infinite-navigation{ line-height: 0; position: relative; }
.infinite-navigation-bar-wrap .infinite-navigation-background{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
.infinite-navigation-bar-wrap .infinite-navigation-container{ position: relative; }
.infinite-navigation-bar-wrap .infinite-main-menu{ display: inline-block; vertical-align: top; }

.infinite-navigation-bar-wrap.infinite-style-left .infinite-main-menu-right-wrap{ float: right; line-height: 1.7; margin-left: 10px; }
.infinite-navigation-bar-wrap.infinite-style-center{ text-align: center; }
.infinite-navigation-bar-wrap.infinite-style-center .infinite-main-menu-right-wrap{ position: absolute; right: 0; line-height: 1.7; }

.infinite-header-style-bar.infinite-style-center .infinite-logo{ float: none; }
.infinite-header-style-bar.infinite-style-center .infinite-logo-inner{ margin-left: auto; margin-right: auto; }
.infinite-header-style-bar.infinite-style-center .infinite-logo-right-text{ position: absolute; top: 0; right: 0; }

/* header side */
.infinite-header-side-content{ position: relative; z-index: 98; }

.infinite-header-side-nav{ position: fixed; top: 0; bottom: 0; z-index: 99; }
.infinite-header-side-nav.infinite-allow-slide{ position: absolute; bottom: auto; }
.infinite-header-side-nav.infinite-fix-bottom{ position: fixed; top: auto; bottom: 0; }
.infinite-header-side-nav.infinite-fix-top{ position: fixed; }
.infinite-header-side-nav.infinite-style-left{ left: 0; }
.infinite-header-side-nav.infinite-style-right{ right: 0; }
.infinite-header-side-nav .infinite-pos-bottom,
.infinite-header-side-nav.infinite-style-middle .infinite-pos-middle{ opacity: 0; filter: alpha(opacity=0);
	transition: opacity 300ms; -moz-transition: opacity 300ms; -o-transition: opacity 300ms; -webkit-transition: opacity 300ms; }
.infinite-header-side-nav .infinite-pos-bottom.infinite-active,
.infinite-header-side-nav.infinite-style-middle .infinite-pos-middle.infinite-active{ opacity: 1; filter: alpha(opacity=100); }

.infinite-header-side-nav.infinite-style-side .infinite-header-social{ padding-top: 20px; padding-bottom: 40px; }
.infinite-header-side-nav.infinite-style-side .infinite-header-social a{ margin-left: 25px; }
.infinite-header-side-nav.infinite-style-side .infinite-header-social a:first-child{ margin-left: 0; }
.infinite-header-side-nav.infinite-style-side .infinite-main-menu-right-wrap{ padding-top: 22px; }
.infinite-header-side-nav.infinite-style-side .infinite-main-menu-search{ float: none; display: inline-block; margin-top: 0; margin-left: 20px; }
.infinite-header-side-nav.infinite-style-side .infinite-main-menu-cart{ float: none; display: inline-block; margin-top: 0; margin-left: 0; }
.infinite-header-side-nav.infinite-style-side .infinite-main-menu-right-wrap > div:first-child{ margin-left: 0; }
.infinite-header-side-nav.infinite-style-left .sf-vertical ul.sub-menu{ text-align: left; margin-left: 0; }
.infinite-header-side-nav.infinite-style-right .sf-vertical ul.sub-menu{ left: auto; right: 100%; text-align: right; }
.infinite-header-side-nav.infinite-style-right .sf-vertical ul.sub-menu li > a.sf-with-ul:after{ content: "\f104"; float: left; margin-left: 0; margin-right: 18px; }

.infinite-header-style-side.infinite-center-align .infinite-logo-inner{ margin-right: auto; margin-left: auto; }
.infinite-header-style-side.infinite-right-align .infinite-logo-inner{ margin-right: 0; margin-left: auto; }

.infinite-header-side-nav.infinite-style-side-toggle { text-align: center; }
.infinite-header-side-nav.infinite-style-side-toggle .infinite-logo-inner{ margin-left: auto; margin-right: auto; }
.infinite-header-side-nav.infinite-style-side-toggle .infinite-main-menu-search,
.infinite-header-side-nav.infinite-style-side-toggle .infinite-main-menu-cart{ float: none; margin-bottom: 8px; padding-left: 0; margin-left: 0; margin-top: 0; }
.infinite-header-side-nav.infinite-style-side-toggle .infinite-header-icon{ padding-bottom: 20px; padding-top: 30px; }

body.admin-bar .infinite-header-side-nav{ padding-top: 32px; }
html.mm-opened body.admin-bar .infinite-header-side-nav{ padding-top: 0; }

/* fixed nav bar */
.infinite-logo-inner{ transition: max-width 200ms; -moz-transition: max-width 200ms;
	-o-transition: max-width 200ms; -webkit-transition: max-width 200ms; }
.infinite-logo-inner img{ width: 100%; /* for ie */ }
.infinite-fixed-nav-logo{ display: none; }
.infinite-sticky-navigation.infinite-fixed-navigation .infinite-fixed-nav-logo{ display: block; }
.infinite-sticky-navigation.infinite-fixed-navigation .infinite-orig-logo{ display: none; }

.infinite-sticky-navigation.infinite-style-fixed .infinite-logo,
.infinite-sticky-navigation.infinite-style-fixed .infinite-navigation,
.infinite-sticky-navigation.infinite-style-fixed .infinite-navigation .sf-menu > li > a{
	transition: background 300ms, color 300ms, border-color 300ms, padding 200ms; -moz-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms;
	-o-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms; -webkit-transition: background 300ms, color 300ms, border-color 300ms, padding 200ms; }
.infinite-sticky-navigation.infinite-style-fixed .infinite-navigation-top{
	transition: top 200ms; -moz-transition: top 200ms; -o-transition: top 200ms; -webkit-transition: top 200ms; }
.infinite-sticky-navigation.infinite-style-fixed.infinite-animate-fixed-navigation .infinite-logo-inner img{
	transition: height 200ms; -moz-transition: height 200ms; -o-transition: height 200ms; -webkit-transition: height 200ms; }

.infinite-fixed-navigation.infinite-style-fixed,
.infinite-fixed-navigation.infinite-style-slide{ position: fixed; top: 0; left: 0; right: 0; z-index: 99;
	box-shadow: 0 1px 2px rgba(0,0,0,0.1); -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.1); }
.infinite-fixed-navigation.infinite-style-slide .infinite-logo-inner img{ max-height: 35px !important; width: auto; }

body.infinite-sticky-navigation-no-logo .infinite-fixed-navigation .infinite-logo{ display: none; }
body.infinite-sticky-navigation-no-logo .infinite-mobile-header.infinite-fixed-navigation .infinite-logo{ display: block; }
body.admin-bar .infinite-fixed-navigation.infinite-style-fixed,
body.admin-bar .infinite-fixed-navigation.infinite-style-slide{ margin-top: 32px; }
@media screen and (max-width: 782px){ body.admin-bar .infinite-fixed-navigation.infinite-style-slide{ margin-top: 0; }  }


.infinite-animate-fixed-navigation.infinite-header-style-plain .infinite-navigation-top{ top: 30px; }
.infinite-animate-fixed-navigation.infinite-header-style-plain.infinite-style-splitted-menu .infinite-logo{ margin-top: -10px; }
.infinite-animate-fixed-navigation.infinite-header-style-plain.infinite-style-splitted-menu .infinite-navigation .sf-menu > li > a{ padding-top: 0; }
.infinite-animate-fixed-navigation.infinite-header-style-plain.infinite-style-splitted-menu .infinite-navigation-top{ top: 22px; }
.infinite-animate-fixed-navigation.infinite-header-style-plain.infinite-style-center-logo .infinite-logo{ position: absolute; left: 0; top: 0; z-index: 3; }

.infinite-animate-fixed-navigation.infinite-header-style-boxed.infinite-style-splitted-menu .infinite-logo{ margin-top: -10px; padding-top: 0; padding-bottom: 0; }
.infinite-animate-fixed-navigation.infinite-header-style-boxed.infinite-style-splitted-menu .infinite-navigation .sf-menu > li > a{ padding-top: 0; }

/* page title */
.infinite-page-title-wrap{ background-image: url('../images/page-title-background.jpg');
	background-position: center; background-size: cover; position: relative; overflow: hidden; }
.infinite-page-title-wrap .infinite-page-title-overlay{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
.infinite-page-title-wrap .infinite-page-title-top-gradient{ position: absolute; top: 0; right: 0;left: 0; }
.infinite-page-title-wrap .infinite-page-title-bottom-gradient{ position: absolute; right: 0; bottom: 0; left: 0; }
.infinite-page-title-wrap .infinite-page-title-container{ position: relative; }
.infinite-page-title-wrap .infinite-page-title{ text-transform: uppercase; letter-spacing: 2px; }

.infinite-page-title-wrap.infinite-style-small .infinite-page-title{ font-size: 37px; margin-bottom: 0; }
.infinite-page-title-wrap.infinite-style-small .infinite-page-caption{ font-size: 16px; margin-top: 13px; }
.infinite-page-title-wrap.infinite-style-small .infinite-page-title-content{ padding-top: 93px; padding-bottom: 87px; }

.infinite-page-title-wrap.infinite-style-medium .infinite-page-title{ font-size: 54px; margin-bottom: 0; }
.infinite-page-title-wrap.infinite-style-medium .infinite-page-caption{ font-size: 19px; margin-top: 13px; }
.infinite-page-title-wrap.infinite-style-medium .infinite-page-title-content{ padding-top: 126px; padding-bottom: 116px; }

.infinite-page-title-wrap.infinite-style-large .infinite-page-title{ font-size: 75px; margin-bottom: 0; }
.infinite-page-title-wrap.infinite-style-large .infinite-page-caption{ font-size: 25px; margin-top: 13px; }
.infinite-page-title-wrap.infinite-style-large .infinite-page-title-content{ padding-top: 184px; padding-bottom: 168px; }

.infinite-page-title-wrap.infinite-style-custom .infinite-page-title{ font-size: 37px; margin-bottom: 0; }
.infinite-page-title-wrap.infinite-style-custom .infinite-page-caption{ font-size: 16px; }

/**
 * 3.0 - Footer
 */
	
/* footer */
.infinite-footer-wrapper .infinite-footer-container{ overflow: hidden; }
.infinite-footer-wrapper .infinite-widget-title{ font-size: 13px; text-transform: uppercase; letter-spacing: 2px; margin-bottom: 35px; }
.infinite-footer-wrapper .infinite-widget-title .gdlr-core-flexslider-nav{ float: right; margin-left: 20px; }
.infinite-footer-wrapper .widget{ margin-bottom: 60px; }
.infinite-footer-wrapper .widget:last-child{ margin-bottom: 0; }

.infinite-footer-wrapper.infinite-with-column-divider .infinite-footer-column{ padding-bottom: 1029px; margin-bottom: -1020px;
	border-left: 1px; border-style: solid; border-color: inherit; padding-left: 30px; padding-right: 30px; }
.infinite-footer-wrapper.infinite-with-column-divider .infinite-footer-column:first-child{ border: none; }

.infinite-fixed-footer{ position: fixed; left: 0; right: 0; bottom: 0; }
body.infinite-boxed .infinite-fixed-footer .infinite-footer-wrapper,
body.infinite-boxed .infinite-fixed-footer .infinite-copyright-wrapper{ margin-left: auto; margin-right: auto; }

.infinite-footer-back-to-top-button{ position: fixed; z-index: 9; right: 30px; bottom: 30px; font-size: 18px;
	line-height: 20px; width: 45px; height: 45px; text-align: center; padding: 12px; cursor: pointer; opacity: 0;
	border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; pointer-events: none;
	transition: opacity 300ms; -moz-transition: opacity 300ms; -o-transition: opacity 300ms; -webkit-transition: opacity 300ms; }
.infinite-footer-back-to-top-button.infinite-scrolled{ opacity: 1; pointer-events: auto; }

/* copyright */
.infinite-footer-wrapper{ padding-top: 70px; padding-bottom: 50px; }
.infinite-copyright-container{ text-transform: uppercase; letter-spacing: 2px; }
.infinite-copyright-text{ text-align: center; font-size: 14px; }
.infinite-copyright-left{ float: left; }
.infinite-copyright-right{ float: right; }

/**
 * 4.0 - Blog
 */

/* single */
.infinite-single-article-date-wrapper{ float: left; width: 58px; border-right-width: 1px; border-right-style: solid;
	line-height: 1; margin-top: 8px; margin-right: 30px; padding-right: 20px; text-align: center; }
.infinite-single-article-date-wrapper .infinite-single-article-date-day{ font-size: 27px; font-weight: bold;
	margin-bottom: 8px; margin-top: -4px; letter-spacing: 1px; white-space: nowrap; }
.infinite-single-article-date-wrapper .infinite-single-article-date-month{ font-size: 14px; text-transform: uppercase; letter-spacing: 1px; }
.infinite-single-article-date-wrapper .infinite-single-article-date-year{ font-size: 12px; margin-top: 4px; }

.infinite-single-article{ padding-bottom: 35px; }
.infinite-single-article .infinite-single-article-content ul,
.infinite-single-article .infinite-single-article-content ol{ line-height: 2; }
.infinite-single-article .infinite-single-article-thumbnail{ margin-bottom: 42px; }
.infinite-single-article .infinite-single-article-head{ margin-bottom: 32px; }
.infinite-single-article .infinite-single-article-title{ font-size: 37px; font-weight: 700; margin-bottom: 16px; letter-spacing: 1px; }
.infinite-single-article .infinite-single-article-head-right{ overflow: hidden; }

.infinite-blog-info-wrapper .infinite-head{ margin-right: 13px; vertical-align: middle; }
.infinite-blog-info-wrapper .infinite-head i{ font-size: 15px; }
.infinite-blog-info-wrapper .infinite-blog-info { display: inline-block; margin-right: 25px; font-size: 11px;
	font-weight: bold; letter-spacing: 1px; text-transform: uppercase; }
.infinite-blog-info-wrapper .infinite-blog-info:last-child{ margin-right: 0; }

.infinite-sticky-banner{ line-height: 1;   display: inline-block; letter-spacing: 1px;
	padding: 18px 20px 17px; text-transform: uppercase; font-size: 11px; font-weight: bold; margin-bottom: 22px; }
.infinite-sticky-banner i{ font-size: 12px; margin-right: 10px; }
.infinite-single-article-thumbnail .infinite-sticky-banner{ position: absolute; left: 0; bottom: 0; margin-bottom: 0; }

body.single-post .infinite-sidebar-style-none{ margin-left: auto; margin-right: auto; }
body.blog .infinite-sidebar-style-none{ margin-left: auto; margin-right: auto; }
body.blog .infinite-single-article{ padding-bottom: 60px; }

.infinite-archive-taxonomy-description { margin-bottom: 60px; }

/* single post format */
.infinite-blog-aside-format .infinite-single-article-content{ font-size: 18px; font-style: italic; padding: 50px 60px 40px; }
.infinite-blog-aside-format .infinite-excerpt-read-more{ display: none; }

.infinite-blog-quote-format .infinite-single-article-content{ padding: 50px 45px 40px; position: relative; }
.infinite-blog-quote-format .infinite-blog-quote-background{ position: absolute; top: 0; right: 0; bottom: 0; left: 0;
	background-size: cover; background-position: center; opacity: 0.3; filter: alpha(opacity=30); }
.infinite-blog-quote-format .infinite-blog-quote{ float: left; font-size: 120px; margin-top: -10px; line-height: 1; margin-right: 35px; position: relative; }
.infinite-blog-quote-format .infinite-blog-content-wrap{ overflow: hidden; position: relative; }
.infinite-blog-quote-format .infinite-blog-quote-content{ font-size: 18px; font-style: italic; }
.infinite-blog-quote-format .infinite-blog-quote-author{ font-size: 16px; font-style: italic; margin-bottom: 20px; font-weight: bold; }
.infinite-blog-quote-format .infinite-blog-quote-author:before{ content: "-"; margin-right: 2px; }
.infinite-blog-quote-format .infinite-excerpt-read-more{ display: none; }
.infinite-blog-quote-format blockquote,
.infinite-blog-quote-format q{ background: transparent; border: none; padding: 0; margin-top: 0; margin-bottom: 0; font-size: 18px; }

.infinite-blog-link-format .infinite-blog-icon-link{ font-size: 23px; float: left; margin-right: 30px; margin-top: 5px; }
.infinite-blog-link-format .infinite-blog-content-wrap{ overflow: hidden; }
.infinite-blog-link-format .infinite-blog-title{ font-size: 20px; text-transform: none; letter-spacing: 0; margin-bottom: 9px; }
.infinite-blog-link-format .infinite-blog-content{ font-size: 14px; margin-bottom: 0; }
.infinite-blog-link-format .infinite-excerpt-read-more{ display: none; }

/* single social share */
.infinite-single-social-share{ padding-top: 10px; padding-bottom: 48px; }

/* single author */
.infinite-single-author{ text-align: center; margin-bottom: 55px; margin-top: 41px;
	border-top-width: 1px; border-top-style: solid; }
.infinite-single-author .infinite-single-author-wrap{ max-width: 80%; margin: -41px auto 0; }
.infinite-single-author .infinite-single-author-avartar{ max-width: 80px; margin: 0 auto 37px; overflow: hidden;
    border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; }
.infinite-single-author .infinite-single-author-caption{ font-size: 14px; font-style: italic; margin-bottom: 9px; letter-spacing: 1px; }
.infinite-single-author .infinite-single-author-title{ font-size: 26px; margin-bottom: 22px; }

/* single navigation */
.infinite-single-nav-area{ border-top: 1px; border-bottom: 1px; border-style: solid; padding: 33px 0; margin-bottom: 55px; }
.infinite-single-nav i{ font-size: 25px; }
.infinite-single-nav .infinite-text{ font-size: 15px; text-transform: uppercase; font-weight: bold; letter-spacing: 2px; }
.infinite-single-nav-left{ float: left; }
.infinite-single-nav-left i{ margin-right: 15px; vertical-align: middle; }
.infinite-single-nav-right{ float: right; }
.infinite-single-nav-right i{ margin-left: 15px; vertical-align: middle; }

/* blog tags */
.infinite-single-author-tags{ margin-bottom: 65px; margin-top: -18px; }
.infinite-single-author-tags a{ display: inline-block; font-size: 12px; padding: 5px 13px 4px;
	margin-right: 12px; border-width: 2px; border-style: solid; font-weight: bold;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; }

/* blog style 4 */
.infinite-blog-style-4 .infinite-single-article-head{ text-align: center; margin-bottom: 0; }
.infinite-blog-style-4 .infinite-single-article .infinite-single-article-title{ font-size: 50px; font-weight: 800; margin-bottom: 20px; }
.infinite-blog-style-4 .infinite-blog-info-wrapper .infinite-blog-info .infinite-head{ display: none; }
.infinite-blog-style-4 .infinite-blog-info-wrapper .infinite-blog-info{ margin-right: 0; text-transform: none; font-size: 15px; font-weight: normal; }
.infinite-blog-style-4 .infinite-blog-info-wrapper .infinite-blog-info .infinite-blog-info-sep{ margin: 0 15px; }
.infinite-blog-style-4 .infinite-blog-info-wrapper .infinite-blog-info:first-child .infinite-blog-info-sep{ display: none; }

.infinite-blog-style-4 .infinite-single-article-thumbnail img{ border-radius: 6px; -moz-border-radius: 6px; -webkit-border-radius: 6px; }
.infinite-blog-style-4 .infinite-single-nav-area{ border-top: 0; padding-top: 0; border-bottom: 0; padding-bottom: 0;}
.infinite-blog-style-4 .infinite-single-nav .infinite-text{ font-size: 12px; letter-spacing: 3px; display: block; margin-bottom: 9px; }
.infinite-blog-style-4 .infinite-single-nav-title{ font-size: 17px; font-weight: bold; letter-spacing: 1px; line-height: 1; }
.infinite-blog-style-4 .infinite-single-nav-left{ max-width: 50%; }
.infinite-blog-style-4 .infinite-single-nav-right{ text-align: right; max-width: 50%; }

.infinite-blog-style-4 .gdlr-core-social-share-item{ float: left; }
.infinite-blog-style-4 .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-count{ font-size: 13px; font-weight: bold; }
.infinite-blog-style-4 .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-suffix{ font-size: 13px; font-weight: bold; letter-spacing: 3px; }
.infinite-blog-style-4 .gdlr-core-social-share-item .gdlr-core-divider{ display: none; }
.infinite-blog-style-4 .gdlr-core-social-share-item .gdlr-core-social-share-count{ margin-right: 32px; }
.infinite-blog-style-4 .infinite-single-social-share{ border-top-width: 1px; border-top-style: solid; padding-top: 19px; }
.infinite-blog-style-4 .infinite-single-magazine-author-tags{ padding-bottom: 0; float: right; }
.infinite-blog-style-4 .infinite-single-magazine-author-tags a{ font-size: 11px; letter-spacing: 2px; font-weight: 600;
    text-transform: uppercase; padding: 8px 17px 10px; display: inline-block; margin-right: 5px; margin-bottom: 5px; }
.infinite-blog-style-4 .infinite-comments-area .comment-author { font-size: 16px; }
.infinite-blog-style-4  section.comment-content{ font-size: 15px; }
.infinite-blog-style-4 .infinite-single-related-post-title,
.infinite-blog-style-4 .infinite-comments-area .infinite-comments-title,
.infinite-blog-style-4 .infinite-comments-area .comment-reply-title{ font-size: 22px; margin-bottom: 30px; font-weight: 700; text-align: left; letter-spacing: 0; text-transform: none; }

.infinite-blog-style-4 .infinite-single-author{ border-top: none; background: #fff; box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); padding: 45px; margin-top: 30px; margin-bottom: 80px; }
.infinite-blog-style-4 .infinite-single-author .infinite-single-author-avartar{ float: left; margin-bottom: 0; margin-right: 33px; }
.infinite-blog-style-4 .infinite-single-author .infinite-single-author-wrap{ max-width: 100%; margin: 0; }
.infinite-blog-style-4 .infinite-single-author-content-wrap{ overflow: hidden; text-align: left; }
.infinite-blog-style-4 .infinite-single-author .infinite-single-author-title{ font-size: 18px; margin-bottom: 16px; font-weight: 700; }
.infinite-blog-style-4 .infinite-comments-area{ border-top-width: 1px; border-top-style: solid; padding-top: 45px; margin-top: 5px; }
.infinite-blog-style-4 .infinite-comments-area .comment-time{ font-style: normal; }
.infinite-blog-style-4 .infinite-comments-area .edit-link,
.infinite-blog-style-4 .infinite-comments-area .comment-reply{ font-size: 14px; font-weight: normal; letter-spacing: 1px; text-transform: uppercase; text-decoration: underline }

.infinite-blog-style-4 .infinite-comments-area .comment-respond{ background: transparent; padding: 10px 0 30px; margin-bottom: 40px; }
.infinite-blog-style-4 .infinite-comments-area textarea{ border-bottom-width: 1px;}
.infinite-blog-style-4 .infinite-comments-area input[type="text"]{ border-bottom-width: 1px; }
.infinite-blog-style-4 .infinite-comments-area .infinite-comment-form-author{ width: 33.33%; }
.infinite-blog-style-4 .infinite-comments-area .infinite-comment-form-email{ width: 33.33%; padding-right: 7px; }
.infinite-blog-style-4 .infinite-comment-form-url{ width: 33.33%; float: left; padding-left: 7px; }

.infinite-blog-style-4 .infinite-comments-area .form-submit{ text-align: left; }
.infinite-blog-style-4 .infinite-comments-area .form-submit input[type="submit"]{ font-size: 12px; border-radius: 30px; -moz-border-radius: 30px; -webkit-border-radius: 30px; }

.infinite-blog-style-4 .infinite-single-related-post-wrap .gdlr-core-blog-grid .gdlr-core-blog-title{ margin-bottom: 6px; font-weight: 700; }
.infinite-blog-style-4 .infinite-button,
.infinite-blog-style-4 input[type="button"],
.infinite-blog-style-4 input[type="submit"],
.infinite-blog-style-4 input[type="reset"]{ font-size: 13px; font-weight: bold; text-transform: uppercase; letter-spacing: 1px; padding: 21px 35px 23px; }

/* blog style 2 */
.infinite-blog-style-2 .infinite-blog-title-wrap .infinite-single-article-title{ margin-bottom: 0; margin-top: 5px; }
.infinite-blog-style-2 .infinite-blog-info-wrapper .infinite-blog-info .infinite-head{ display: none; }
.infinite-blog-style-2 .infinite-blog-info-wrapper .infinite-blog-info{ margin-right: 0; text-transform: none; font-size: 13px; }
.infinite-blog-style-2 .infinite-blog-info-wrapper .infinite-blog-info .infinite-blog-info-sep{ margin: 0 15px; }
.infinite-blog-style-2 .infinite-blog-info-wrapper .infinite-blog-info:first-child .infinite-blog-info-sep{ display: none; }

.infinite-blog-style-2 .infinite-single-author .infinite-single-author-wrap{ margin: 0; }
.infinite-blog-style-2 .infinite-single-author{ border-top-width: 0; margin-top: 0; text-align: left; }
.infinite-blog-style-2 .infinite-single-author .infinite-single-author-avartar{ margin: 0; float: left; margin-right: 30px; max-width: 100px;
	border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; }
.infinite-blog-style-2 .infinite-single-author .infinite-single-author-caption{ display: none; }
.infinite-blog-style-2 .infinite-single-author .infinite-single-author-content-wrap{ overflow: hidden; padding-top: 12px; }
.infinite-blog-style-2 .infinite-single-author .infinite-single-author-title{ font-size: 20px; margin-bottom: 10px; }

.infinite-blog-style-2 .gdlr-core-social-share-item{ text-align: left; }
.infinite-blog-style-2 .gdlr-core-social-share-item .gdlr-core-social-share-count{ margin-right: 25px; font-weight: bold; }
.infinite-blog-style-2 .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-count{ font-size: 16px; }
.infinite-blog-style-2 .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-suffix{ font-size: 16px; text-transform: none; letter-spacing: 0; }
.infinite-blog-style-2 .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-divider{ display: none; }

.infinite-blog-style-2 .infinite-single-article{ padding-bottom: 0; }
.infinite-blog-style-2 .infinite-single-nav-area{ border: none; padding: 30px 0 0; margin-bottom: 0; }
.infinite-blog-style-2 .infinite-single-nav-area a,
.infinite-blog-style-2 .infinite-single-nav-area a:hover{ color: #fff; }
.infinite-blog-style-2 .infinite-single-nav-area-left{ float: left; width: 50%; padding: 50px 80px; position: relative; overflow: hidden; }
.infinite-blog-style-2 .infinite-single-nav-area-right{ float: left; width: 50%; padding: 50px 80px; position: relative; overflow: hidden; }
.infinite-blog-style-2 .infinite-single-nav-area-background{ position: absolute;
	top: 0; right: 0; bottom: 0; left: 0; background-size: cover; background-position: center;
	transition: transform 300ms ease-out; -moz-transition: transform 300ms ease-out;
	-o-transition: transform 300ms ease-out; -webkit-transition: transform 300ms ease-out; }
.infinite-blog-style-2 .infinite-single-nav-area-left:hover .infinite-single-nav-area-background,
.infinite-blog-style-2 .infinite-single-nav-area-right:hover .infinite-single-nav-area-background{ -webkit-transform: scale(1.1); transform: scale(1.1); }
.infinite-blog-style-2 .infinite-single-nav-left i{ font-size: 20px; }
.infinite-blog-style-2 .infinite-single-nav-left,
.infinite-blog-style-2 .infinite-single-nav-right{ float: none; }
.infinite-blog-style-2 .infinite-single-nav{ position: relative; }
.infinite-blog-style-2 .infinite-single-nav .infinite-text{ letter-spacing: 0; text-transform: none; }
.infinite-blog-style-2 .infinite-single-nav-title{ font-size: 22px; font-weight: 800; display: block; margin-top: 2px; }
.infinite-blog-style-2 .infinite-single-nav-area-overlay{ opacity: 0.7; filter: alpha(opacity=62);
	position: absolute; top: 0; right: 0; bottom: 0; left: 0; }

.infinite-blog-style-2 .infinite-comment-wrapper{ padding-top: 80px; }
.infinite-blog-style-2 .infinite-comment-content{ margin: 0 auto; }
.infinite-blog-style-2 .infinite-comments-area .infinite-comments-title{ text-align: left; text-transform: none;
	letter-spacing: 0; font-weight: 800; font-size: 20px; }
.infinite-blog-style-2 .infinite-comments-area .comment-avatar{ max-width: 90px; border-radius: 3px; }
.infinite-blog-style-2 .infinite-comments-area .comment-author{ font-size: 18px; }
.infinite-blog-style-2 .infinite-comments-area .comment-time{ font-size: 13px; font-style: normal; }
.infinite-blog-style-2 .infinite-comments-area .comment-content{ font-size: 15px; }
.infinite-blog-style-2 .infinite-comments-area .comment-meta{ margin-bottom: 16px; }
.infinite-blog-style-2 .infinite-comments-area .comment-reply{ text-transform: none; font-weight: 800; letter-spacing: 0; }
.infinite-blog-style-2 .infinite-comments-area .comment-article{ padding-bottom: 0; border-bottom-width: 0; }
.infinite-blog-style-2 .infinite-comments-area .comment-reply-title{ font-size: 20px; letter-spacing: 0;
	text-align: left; text-transform: none; font-weight: 800; margin-bottom: 35px; }
.infinite-blog-style-2 .infinite-comments-area .comment-respond{ padding: 0 0 85px; background: transparent; margin-bottom: 0; }
.infinite-blog-style-2 .infinite-comments-area textarea{ font-size: 14px;padding: 20px 22px; margin-bottom: 22px; }
.infinite-blog-style-2 .infinite-comments-area input[type="text"]{ font-size: 14px; padding: 20px 22px; margin-bottom: 22px; }
.infinite-blog-style-2 .infinite-comments-area .infinite-comment-form-author{ width: 33.33%; padding-right: 13px; }
.infinite-blog-style-2 .infinite-comments-area .infinite-comment-form-email{ width: 33.33%; padding-right: 7px; }
.infinite-blog-style-2 .infinite-comments-area .infinite-comment-form-url{ width: 33.33%; float: left; padding-left: 14px; }
.infinite-blog-style-2 .infinite-comments-area .form-submit{ text-align: left; padding-top: 0; }
.infinite-blog-style-2 .infinite-comments-area .form-submit input[type="submit"]{ font-size: 13px; text-transform: none; padding: 16px 30px;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; }
.infinite-blog-style-2 .infinite-single-related-post-wrap{ padding: 80px 0 50px; }
.infinite-blog-style-2 .infinite-single-related-post-container{ padding-left: 80px; padding-right: 80px; }
.infinite-blog-style-2 .infinite-single-related-post-title{ font-size: 20px; margin-bottom: 40px; font-weight: 800; }
.infinite-blog-style-2 .infinite-single-related-post-container{ margin: 0 auto; }
.infinite-blog-style-2 .gdlr-core-blog-grid .gdlr-core-blog-title{ font-size: 21px; }
.infinite-blog-style-2 .infinite-single-related-post-wrap .gdlr-core-sticky-banner{ display: none; }

/* blog style 3 */
.infinite-blog-style-3 .infinite-single-nav-left,
.infinite-blog-style-3 .infinite-single-nav-right{ max-width: 50%; }
.infinite-blog-style-3 .infinite-single-nav-right{ text-align: right; }
.infinite-blog-style-3 .infinite-single-nav .infinite-text{ font-size: 14px; text-transform: uppercase; letter-spacing: 1px; font-weight: 700; display: block; margin-bottom: 8px; }
.infinite-blog-style-3 .infinite-single-nav .infinite-single-nav-title{ font-size: 18px; font-weight: 800; }

.infinite-blog-style-3 .infinite-single-social-share{ padding-bottom: 27px; }
.infinite-blog-style-3 .gdlr-core-social-share-item{ position: relative; }
.infinite-blog-style-3 .gdlr-core-social-share-item .gdlr-core-social-share-count{ position: absolute; right: 0; }
.infinite-blog-style-3 .gdlr-core-social-share-item .gdlr-core-social-share-wrap{ margin-right: 25px; float: left; }
.infinite-blog-style-3 .gdlr-core-social-share-item .gdlr-core-divider{ display: none; }
.infinite-blog-style-3 .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-count{ font-size: 15px; margin-right: 6px; }
.infinite-blog-style-3 .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-suffix{ font-size: 15px; text-transform: none; font-weight: 600; letter-spacing: 0; }

.infinite-blog-style-3 .infinite-single-author .infinite-single-author-avartar{ margin: 0; float: left; margin-right: 40px; border-width: 1px; border-style: solid; max-width: 116px; padding: 8px; }
.infinite-blog-style-3 .infinite-single-author .infinite-single-author-wrap{ max-width: 100%; margin: 0; }
.infinite-blog-style-3 .infinite-single-author{ border: none; }
.infinite-blog-style-3 .infinite-single-author-content-wrap{ overflow: hidden; text-align: left; }
.infinite-blog-style-3 .infinite-single-author .infinite-single-author-title{ font-size: 18px; margin-bottom: 15px; }
.infinite-blog-style-3 .infinite-single-author .infinite-single-author-avartar img{ border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; }

.infinite-blog-style-3 .infinite-comments-area .infinite-comments-title { font-size: 25px; text-align: left; letter-spacing: 0; text-transform: none; font-weight: 800; margin-bottom: 30px; }
.infinite-blog-style-3 .infinite-comments-area .comment-respond{ background: transparent; padding: 0; }
.infinite-blog-style-3 .infinite-comments-area textarea,
.infinite-blog-style-3 .infinite-comments-area input[type="text"]{ border-bottom-width: 1px; font-size: 14px; font-weight: 500; }
.infinite-blog-style-3 .infinite-comments-area input[type="checkbox"]{vertical-align: middle;margin: 0 10px 3px 0;}
.infinite-blog-style-3 .infinite-comments-area .form-submit{ text-align: left; }
.infinite-blog-style-3 .infinite-comments-area .form-submit input[type="submit"]{ text-transform: none; font-size: 14px; letter-spacing: 0; padding: 15px 33px 19px; }
.infinite-blog-style-3 .infinite-comments-area .comment-reply-title{ text-align: left; text-transform: none; letter-spacing: 0; font-size: 22px; }
.infinite-blog-style-3 .infinite-single-magazine-author-tags{ margin-bottom: 30px; }
.infinite-blog-style-3 .infinite-comments-area .comment-time{ display: inline; margin-right: 12px; }
.infinite-blog-style-3 .infinite-comments-area .comment-time time{ text-transform: uppercase; font-weight: 400; letter-spacing: 0; }
.infinite-blog-style-3 .infinite-comments-area .comment-reply{ position: static; display: inline; font-size: 14px; letter-spacing: 0; text-decoration: underline; }
.infinite-blog-style-3 .infinite-comments-area .edit-link { font-size: 14px; text-decoration: underline; text-transform: uppercase; letter-spacing: 1px; }

.infinite-blog-style-3 .infinite-single-related-post-wrap{ margin-bottom: 20px; }
.infinite-blog-style-3 .infinite-single-related-post-title{ font-size: 22px; margin-bottom: 40px; font-weight: 700; }
.infinite-blog-style-3 .infinite-single-related-post-wrap .gdlr-core-blog-info-wrapper .gdlr-core-blog-info { font-size: 13px; }

/* blog magazine */
.infinite-blog-magazine .infinite-single-article{ padding-bottom: 0; }
.infinite-blog-magazine .infinite-single-article-content .infinite-single-social-share{ padding-bottom: 30px; }
.infinite-blog-magazine .gdlr-core-social-share-item .gdlr-core-divider{ display: none; }
.infinite-blog-magazine .gdlr-core-social-share-item .gdlr-core-social-share-count{ margin-left: 20px; }
.infinite-blog-magazine .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-count,
.infinite-blog-magazine .gdlr-core-social-share-item .gdlr-core-social-share-count .gdlr-core-suffix{ font-size: 13px; font-weight: normal; }
.infinite-blog-magazine .infinite-single-social-share{ padding-top: 0; padding-bottom: 23px; }
.infinite-single-magazine-author-tags{ padding-bottom: 23px; }
.infinite-single-magazine-author-tags a{ font-size: 13px; font-weight: 500; padding: 7px 12px; display: inline-block; margin-right: 5px; margin-bottom: 5px; }
.infinite-blog-magazine .infinite-single-nav-area{ margin-top: 60px; margin-bottom: 45px; border: none; padding-top: 5px; padding-bottom: 5px; position: relative; }
.infinite-blog-magazine .infinite-single-author{ border: none; text-align: left; padding: 40px; margin-top: 50px; }
.infinite-blog-magazine .infinite-single-author .infinite-single-author-wrap{ margin: 0; }
.infinite-blog-magazine .infinite-single-author .infinite-single-author-avartar{ float: left; margin-right: 30px; }
.infinite-blog-magazine .infinite-single-author-content-wrap{ overflow: hidden; }
.infinite-blog-magazine .infinite-single-author .infinite-single-author-caption{ display: none; }
.infinite-blog-magazine .infinite-single-author .infinite-single-author-title{ font-size: 16px; margin-bottom: 18px; }
.infinite-blog-magazine .infinite-single-author-description{ font-size: 15px; }
.infinite-blog-magazine .infinite-single-nav-left,
.infinite-blog-magazine .infinite-single-nav-right{ max-width: 50%; }
.infinite-blog-magazine .infinite-single-nav-right{ text-align: right; }
.infinite-blog-magazine .infinite-single-nav-area-divider{ position: absolute; left: 50%; top: 0; bottom: 0; border-left-width: 1px; border-left-style: solid; }
.infinite-blog-magazine .infinite-single-nav .infinite-text{ font-size: 13px; font-weight: 500; letter-spacing: 1px; display: block; margin-bottom: 8px; }
.infinite-blog-magazine .infinite-single-nav .infinite-single-nav-title{ font-size: 15px; font-weight: bold; }
.infinite-blog-magazine .infinite-single-related-post-wrap .infinite-single-related-post-title{ font-size: 16px; border-bottom-width: 1px; border-bottom-style: solid; padding-bottom: 10px; margin-bottom: 35px; }
.infinite-blog-magazine .infinite-comment-wrapper{ margin-top: 45px; }
.infinite-blog-magazine .infinite-single-related-post-wrap .gdlr-core-blog-grid .gdlr-core-blog-info-wrapper{ padding-top: 0; }
.infinite-blog-magazine .infinite-single-related-post-wrap .gdlr-core-blog-grid .gdlr-core-blog-title{ margin-bottom: 10px; }
.infinite-blog-magazine .infinite-single-related-post-wrap .gdlr-core-blog-info-wrapper .gdlr-core-blog-info{ font-size: 11px; }
.infinite-blog-magazine .infinite-single-related-post-wrap .gdlr-core-blog-grid-with-frame .gdlr-core-blog-grid-frame { padding: 20px; }

/* comment area */
.infinite-comments-area .infinite-comments-title{ font-size: 16px; text-align: center;
	text-transform: uppercase; font-weight: bold; letter-spacing: 2px; margin-bottom: 55px; }
.infinite-comments-area ol.comment-list{ list-style: none; margin-bottom: 55px; margin-left: 0; }
.infinite-comments-area ol.comment-list ol{ margin-left: 50px; list-style: none; }
.infinite-comments-area ol.comment-list .comment-content ol{ list-style: decimal; }
.infinite-comments-area .comment-article{ padding-bottom: 20px; margin-bottom: 40px; border-bottom-width: 1px; border-bottom-style: solid; }
.infinite-comments-area .comment-avatar{ max-width: 80px; margin-right: 35px; float: left; overflow: hidden;
    border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; }
.infinite-comments-area .comment-avatar img{ display: block; }
.infinite-comments-area .comment-meta{ position: relative; margin-bottom: 20px; }
.infinite-comments-area .comment-author{ font-size: 19px; font-weight: bold; margin-bottom: 5px; }
.infinite-comments-area .comment-body{ overflow: hidden; }
.infinite-comments-area .comment-time{ font-size: 14px; font-style: italic; }
.infinite-comments-area .comment-reply{ font-size: 15px; text-transform: uppercase; font-weight: bold;
	letter-spacing: 2px; position: absolute; right: 0; top: 4px; }

.infinite-comments-area .comment-respond{ padding: 60px 55px 40px; margin-bottom: 55px; }
.infinite-comments-area ol.comment-list .comment-respond{ margin-top: 55px; }
.infinite-comments-area .comment-reply-title{ font-size: 16px; font-weight: bold;
	text-transform: uppercase; letter-spacing: 2px; text-align: center; margin-bottom: 40px; }
.infinite-comments-area .comment-reply-title small{ margin-left: 10px; }
.infinite-comments-area textarea{ width: 100%; padding: 18px 22px; display: block; font-size: 14px;
    border-width: 1px 1px 2px 1px; border-style: solid; margin: 0; margin-bottom: 14px; }
.infinite-comments-area input[type="text"]{ font-size: 14px; width: 100%; display: block; border-width: 1px 1px 2px 1px; border-style: solid; padding: 16px 22px; margin-bottom: 14px; }
.infinite-comments-area input[type="text"]:focus,
.infinite-comments-area textarea:focus{ outline: none; }
.infinite-comments-area .infinite-comment-form-author{ width: 50%; padding-right: 7px; float: left; }
.infinite-comments-area .infinite-comment-form-email{ width: 50%; padding-left: 7px; float: left; }
.infinite-comments-area .form-submit{ text-align: center; padding-top: 14px; }

/* blog title */
.infinite-blog-title-wrap{ background-image: url('../images/page-title-background.jpg'); background-position: center; position: relative; background-size: cover; overflow: hidden; }
.infinite-blog-title-wrap .infinite-blog-title-container{ position: relative; }
.infinite-blog-title-wrap .infinite-blog-title-overlay{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; opacity: 0.8; }
.infinite-blog-title-wrap.infinite-feature-image .infinite-blog-title-top-overlay{ position: absolute; top: 0; right: 0; left: 0; height: 413px;
	opacity: 1; background: url("../images/blog-title-top-gradient.png") center top repeat-x; }
.infinite-blog-title-wrap.infinite-feature-image .infinite-blog-title-bottom-overlay{ position: absolute; right: 0; bottom: 0; left: 0; height: 413px;
	opacity: 1; background: url("../images/blog-title-gradient.png") center bottom repeat-x; }

.infinite-blog-title-wrap.infinite-style-small .infinite-blog-title-content{ padding-top: 93px; padding-bottom: 87px; }
.infinite-blog-title-wrap.infinite-style-large .infinite-blog-title-content{ padding-top: 184px; padding-bottom: 168px; }

.infinite-blog-title-wrap .infinite-single-article-title{ font-size: 50px; font-weight: 700; margin-bottom: 11px; letter-spacing: 1px; }
.infinite-blog-title-wrap .infinite-single-article-head-right{ overflow: hidden; }
.infinite-blog-title-wrap .infinite-single-article-date-wrapper{ margin-top: 11px; }

/* 404 */
.infinite-not-found-wrap{ text-align: center; position: relative; }
.infinite-not-found-wrap .infinite-not-found-background{ position: absolute; top: 0; right: 0; bottom: 0; left: 0; opacity: 0.27; filter: alpha(opacity=27);
	background-position: center; background-size: cover; background-image: url('../images/404-background.jpg'); }
.infinite-not-found-wrap .infinite-not-found-content{ position: relative; padding: 60px 0 65px; }
.infinite-not-found-wrap .infinite-not-found-head{ font-size: 150px; line-height: 1; margin-bottom: 40px; }
.infinite-not-found-wrap .infinite-not-found-title{ font-size: 35px; line-height: 1; font-weight: 800; text-transform: uppercase; margin-bottom: 14px; }
.infinite-not-found-wrap .infinite-not-found-caption{ font-size: 18px; }
.infinite-not-found-wrap .infinite-not-found-back-to-home a{ font-size: 14px; text-transform: uppercase; letter-spacing: 1px; }
.infinite-not-found-wrap form.search-form{ max-width: 430px; margin: 58px auto 0; position: relative; margin-bottom: 13px; }
.infinite-not-found-wrap form.search-form input.search-field{ font-size: 15px; width: 100%; border: none;
	padding: 22px 60px 22px 25px; height: 64px;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; }
.infinite-not-found-wrap form.search-form input.search-submit{ position: absolute; right: 0; top: 0; width: 64px; height: 64px; opacity: 0; filter: alpha(opacity=0); }
.infinite-not-found-wrap form.search-form .infinite-top-search-submit{ position: absolute; right: 20px; top: 50%; margin-top: -15px; font-size: 18px;  }

body.search .infinite-not-found-wrap .infinite-not-found-head{ font-size: 100px; text-transform: uppercase; margin-bottom: 18px; }
body.search .infinite-not-found-wrap form.search-form{ margin-top: 50px; }

/* Not Found */
body.search .infinite-not-found-wrap {
    background-color: #bd584e;
}
body.search .infinite-not-found-wrap .infinite-not-found-caption {
    color: #ffe6e4;
}

/**
 * 5.0 - Widget
 */

/* sidebar */
.infinite-sidebar-area .infinite-widget{ margin-bottom: 55px; }
.infinite-sidebar-area .infinite-widget-title{ font-size: 13px; text-transform: uppercase; font-weight: 800;
    letter-spacing: 1px; position: relative; margin-bottom: 28px; overflow: hidden; }
.infinite-sidebar-area .infinite-widget-title .gdlr-core-flexslider-nav{ float: right; margin-left: 20px; }
.infinite-sidebar-area .infinite-widget-head-text{ display: block; float: left; margin-right: 28px; }
.infinite-sidebar-area .infinite-widget-head-divider{ display: block; border-top-width: 1px; border-top-style: solid; overflow: hidden; margin-top: 0.6em; }

/* list */
.textwidget select{ max-width: 100%; }

.widget_categories .screen-reader-text, .widget_archive .screen-reader-text{ display: none; }

.widget_rss .rss-widget-icon{ margin-right: 10px; margin-top: -3px; }

.widget_product_categories ul,
.widget_archive ul, .widget_categories ul, .widget_nav_menu ul,
.widget_meta ul, .widget_pages ul, .widget_recent_comments ul,
.widget_recent_entries ul, .widget_rss ul{ position: relative; list-style: none; margin-left: 0; }

.widget_product_categories ul li,
.widget_archive ul li, .widget_categories ul li, .widget_nav_menu ul li,
.widget_meta ul li, .widget_pages ul li, .widget_recent_comments ul li,
.widget_recent_entries ul li, .widget_rss ul li{ font-size: 14px; margin-bottom: 15px; padding-left: 28px;
	line-height: 20px; padding-bottom: 15px; border-bottom-width: 1px; border-bottom-style: solid; }

.widget_product_categories ul ul,
.widget_archive ul ul, .widget_categories ul ul, .widget_nav_menu ul ul,
.widget_meta ul ul, .widget_pages ul ul, .widget_recent_comments ul ul,
.widget_recent_entries ul ul, .widget_rss ul ul{ margin-top: 20px; margin-bottom: 0; }

.widget_product_categories ul ul ul,
.widget_archive ul ul ul, .widget_categories ul ul ul, .widget_nav_menu ul ul ul,
.widget_meta ul ul ul, .widget_pages ul ul ul, .widget_recent_comments ul ul ul,
.widget_recent_entries ul ul ul, .widget_rss ul ul ul{ margin-left: 5px; }

.widget_product_categories ul ul li,
.widget_archive ul ul li, .widget_categories ul ul li, .widget_nav_menu ul ul li,
.widget_meta ul ul li, .widget_pages ul ul li:last-child, .widget_recent_comments ul ul li,
.widget_recent_entries ul ul li, .widget_rss ul ul li{ border-top-width: 1px; border-top-style: solid;
	margin-bottom: 0; padding-bottom: 0; border-bottom: 0; padding-top: 15px; margin-top: 15px; }

.widget_product_categories ul li:before,
.widget_archive ul li:before, .widget_categories ul li:before, .widget_nav_menu ul li:before,
.widget_meta ul li:before, .widget_pages ul li:before, .widget_recent_comments ul li:before,
.widget_recent_entries ul li:before, .widget_rss ul li:before{ content: "\f105"; font-family: FontAwesome;
	text-align: center; position: absolute; left: 0; display: block; height: 20px; width: 20px; margin-top: -1px; }

.widget_archive select, .widget_categories select, .widget_text select{ font-size: 14px; width: 100%; height: 50px; padding-left: 15px; outline: none;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; border-width: 1px; border-style: solid;
	background: url('../images/dropdown.png') no-repeat 97% center;
	-moz-appearance: none; -webkit-appearance: none;  appearance: none; }

.widget_rss span.rss-date{ margin-bottom: 17px; margin-top: 3px; display: block; text-transform: uppercase; font-weight: 700; letter-spacing: 2px; font-size: 12px; }
.widget_rss .rssSummary{ line-height: 1.7; margin-bottom: 15px; }
.widget_rss a.rsswidget{ font-size: 16px; font-weight: 700; }
.widget_rss cite{ font-size: 14px; margin-bottom: 14px; display: block; }
.widget_rss ul li{ margin-bottom: 27px; }

/* search widget */
.widget_search form{ display: block; position: relative; }
.widget_search form:after{ content: "\f002"; font-family: "fontAwesome"; font-size: 14px; line-height: 14px;
	position: absolute; right: 20px; top: 50%; margin-top: -7px; }
.widget_search label .screen-reader-text{ display: none; }
.widget_search .search-form input.search-field{ font-size: 13px; line-height: 17px; height: 51px;
    border-width: 1px; border-style: solid; width: 100%; padding: 16px 20px;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; }
.widget_search input.search-field:focus{ outline: none; }
.widget_search input.search-submit{ position: absolute; right: 0; top: 0; bottom: 0; width: 51px; padding: 0; opacity: 0; filter: alpha(opacity=0); z-index: 1; }

/* tag cloud */
.tagcloud{ zoom: 1; }
.tagcloud:after{ content: " "; display: block; clear: both; visibility: hidden; line-height: 0; height: 0; }
.tagcloud a{ font-size: 11px !important; text-transform: uppercase; font-weight: 600; float: left;
    padding: 7px 16px 8px; border-width: 1px; border-style: solid; margin-right: 10px; margin-bottom: 10px; }

/* calendar */
.widget_calendar table{ width: 100%; margin-bottom: 0; }
.widget_calendar table thead th{ font-size: 13px; font-weight: bold; padding: 10px 0; }
.widget_calendar table tbody td{ text-align: center; padding: 10px 0; }
.widget_calendar table tfoot td{ text-align: center; padding: 10px 0; }
.widget_calendar caption{ font-size: 14px; margin-bottom: 20px; text-transform: uppercase; font-weight: bold; letter-spacing: 1px; }

.widget ul.menu .sub-menu .sub-menu li{ padding-left: 20px; }
.widget ul.menu .sub-menu .sub-menu li:before{ left: -10px; }
.widget ul.menu .sub-menu .sub-menu .sub-menu .sub-menu li{ padding-left: 15px; }
.widget ul.menu .sub-menu .sub-menu .sub-menu .sub-menu li:before{ left: -15px }
.widget ul.menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu li{ padding-left: 5px; }
.widget ul.menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu .sub-menu li:before{ left: -25px; }

/* table */
table{ width: 100%; text-align: center; border-spacing: 2px; border-collapse: separate; margin-bottom: 20px; }
table tr td{ padding: 15px 0; }
table tr th{ padding: 13px 0; font-size: 16px; font-weight: normal; }

/* for goodlayer core plugin */
body.infinite-boxed .gdlr-core-column-extend-left .gdlr-core-pbf-background-wrap{ margin-left: -50px; }
body.infinite-boxed .gdlr-core-column-extend-right .gdlr-core-pbf-background-wrap{ margin-right: -50px; }

/**
 * 6.0 - Responsive
 */
.infinite-mobile-header-wrap{ display: none; }
.infinite-mobile-header{ padding-top: 15px; padding-bottom: 15px; z-index: 99; position: relative;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); }
.infinite-mobile-header.infinite-fixed-navigation{ display: none; }
.infinite-mobile-header .infinite-mobile-header-container{ position: relative; }
.infinite-mobile-header .infinite-logo{ padding: 0 15px; }
.infinite-mobile-header .infinite-logo-inner{ line-height: 45px; margin-right: 80px; }
.infinite-mobile-header .infinite-logo-inner img{ vertical-align: middle; width: auto; }

/*--- tablet ---*/
@media only screen and (max-width: 1140px){
	
	html .widget_product_search input[type="search"]#woocommerce-product-search-field{ width: 100%; margin-bottom: 10px; }

}

@media only screen and (max-width: 999px){
	
	body{ width: 100%; overflow-x: hidden; }
	body.infinite-boxed div.infinite-body-wrapper{ margin-top: 0; margin-bottom: 0; }

	.infinite-bullet-anchor{ display: none; }

	/* remove header */
	.infinite-top-bar{ display: none; }
	.infinite-header-wrap.infinite-header-style-plain,
	.infinite-header-wrap.infinite-header-style-bar, .infinite-navigation-bar-wrap,
	.infinite-header-background-transparent, .infinite-header-boxed-wrap, .infinite-header-side-nav,
	.infinite-header-transparent-substitute, .infinite-sticky-navigation{ display: none !important; }

	.infinite-mobile-header-wrap{ display: block; }
	.infinite-mobile-header-wrap .infinite-top-bar{ display: block; }
	.infinite-mobile-header-wrap .infinite-top-bar{ font-size: 13px; }
	.infinite-mobile-header-wrap .infinite-top-bar-left,
	.infinite-mobile-header-wrap .infinite-top-bar-right{ padding-top: 0; padding-bottom: 0; }
	.infinite-mobile-header.infinite-fixed-navigation{ display: block; }
	.infinite-sticky-menu-placeholder{ display: none !important; }

	body .infinite-header-side-content.infinite-style-left{ margin-left: 0; }
	body .infinite-header-side-content.infinite-style-right{ margin-right: 0; }

	body.single-product.woocommerce div.product form.cart{ float: none; margin-bottom: 30px; width: auto; margin-right: 0; }
	body.single-product.woocommerce div.product .product_meta{ margin-top: 0; }

	.infinite-blog-style-2 .infinite-single-nav-area-left,
	.infinite-blog-style-2 .infinite-single-nav-area-right{ padding: 30px 40px; }
	.infinite-blog-style-2 .infinite-single-related-post-container{ padding-left: 30px; padding-right: 30px; }
}

/*--- mobile landscape style ---*/
@media only screen and (max-width: 767px){
	body.infinite-body-front .infinite-container{ max-width: 500px; padding-left: 15px; padding-right: 15px; }
	body.infinite-body-front .gdlr-core-container{ max-width: 500px; padding-left: 15px; padding-right: 15px; }

	.infinite-column-10, .infinite-column-12, .infinite-column-15, .infinite-column-20,
	.infinite-column-24, .infinite-column-30, .infinite-column-36, .infinite-column-40,
	.infinite-column-45, .infinite-column-48, .infinite-column-50, .infinite-column-60{ width: 100%; }

	/* header */
	.infinite-mobile-header-wrap .infinite-top-bar-left,
	.infinite-mobile-header-wrap .infinite-top-bar-right{ float: none; text-align: center; }

	.infinite-top-bar-right-text{ display: block; }
	.infinite-top-bar-right-social{ margin-left: 0; }
	.infinite-top-bar-right-social a:first-child{ margin-left: 0; }

	.infinite-page-title-wrap .infinite-page-title-content{ padding-top: 60px !important; padding-bottom: 50px !important; }
	.infinite-blog-title-wrap .infinite-blog-title-content{ padding-top: 60px !important; padding-bottom: 50px !important; }
	.infinite-single-article-date-wrapper{ display: none; }

	.infinite-overlay-menu-content ul.menu > li{ font-size: 18px; padding-bottom: 10px; margin-bottom: 10px; }
	.infinite-overlay-menu-content ul.menu{ padding-top: 120px; padding-bottom: 100px; max-width: 80%; }
	.infinite-overlay-menu-content .infinite-overlay-menu-close{ top: 35px; }
	body.admin-bar .infinite-overlay-menu-content{ margin-top: 0; }
	body.admin-bar .infinite-overlay-menu-content ul.menu{ padding-top: 160px; }
	body.admin-bar .infinite-overlay-menu-content .infinite-overlay-menu-close{ top: 80px; }
	
	/* single*/
	.infinite-comments-area .comment-respond{ padding: 45px 30px; }
	.infinite-comments-area .infinite-comment-form-author{ width: 100%; padding-right: 0; }
	.infinite-comments-area .infinite-comment-form-email{ width: 100%; padding-left: 0; }
	.infinite-comments-area .comment-avatar{ float: none; margin-right: 0; margin-bottom: 30px; }
	.infinite-comments-area ol.comment-list ol{ margin-left: 10px; }
	.infinite-blog-style-2 .infinite-comments-area .infinite-comment-form-author{ width: 100%; padding-right: 0; }
	.infinite-blog-style-2 .infinite-comments-area .infinite-comment-form-email{ width: 100%; padding-right: 0; }
	.infinite-blog-style-2 .infinite-comments-area .infinite-comment-form-url{ width: 100%; padding-left: 0; }
	.infinite-blog-style-2 .infinite-single-nav-area{ padding-top: 0; }
	.infinite-blog-style-2 .infinite-single-nav-area-left,
	.infinite-blog-style-2 .infinite-single-nav-area-right{ float: none; width: 100%; display: block; text-align: center; }
	.infinite-blog-style-2 .infinite-single-nav-area a i{ display: none; }
	.infinite-blog-style-2 .infinite-single-related-post-container{ max-width: 500px; }

	.infinite-blog-style-4 .gdlr-core-social-share-item .gdlr-core-social-share-count{ margin-right: 0; }
	.infinite-blog-style-4 .infinite-single-nav-left{ float: none; text-align: center; display: block; margin-bottom: 30px; max-width: none; }
	.infinite-blog-style-4 .infinite-single-nav-right{ float: none; text-align: center; display: block;  max-width: none; }
	.infinite-blog-style-4 .gdlr-core-social-share-item{ float: none; }
	.infinite-blog-style-4 .infinite-single-magazine-author-tags{ float: none; margin-top: 30px; text-align: center; }
	.infinite-blog-style-4 .infinite-comments-area .infinite-comment-form-author,
	.infinite-blog-style-4 .infinite-comments-area .infinite-comment-form-email,
	.infinite-blog-style-4 .infinite-comment-form-url{ width: 100%; padding-left: 0; padding-right: 0; }
	.infinite-blog-style-4 .infinite-single-author .attorna-single-author-avartar{ float: none; max-width: 90px; margin: 0 auto 30px; }
	.infinite-blog-style-4 .infinite-single-author-content-wrap{ text-align: center; }
	
	/* footer */
	.infinite-footer-wrapper{ padding-bottom: 7px; }
	.infinite-footer-column{ margin-bottom: 60px; }
	.infinite-footer-wrapper.infinite-with-column-divider .infinite-footer-column{ padding: 0 15px; margin-bottom: 60px; border: none; }
	.infinite-footer-wrapper.infinite-with-column-divider .infinite-footer-column:last-child{ margin-bottom: 20px; }

	.infinite-fixed-footer-placeholder{ display: none; }
	.infinite-fixed-footer{ position: static; }

	.infinite-copyright-left,
	.infinite-copyright-right{ float: none; text-align: center; }

	body .woocommerce .col2-set, .woocommerce-page .col2-set { width: 100%; float: none; margin-right: 0; }
	body div#order_review{ width: 100%; }
}

/*--- mobile portrait style ---*/
@media only screen and (max-width: 419px){

	body{ min-width: 320px; }
	.infinite-top-search-wrap input.search-field{ font-size: 30px; padding-right: 90px; }
	.infinite-top-search-wrap .infinite-top-search-submit{ right: 48px; font-size: 24px; margin-top: -2px; }
	.infinite-top-search-wrap input.search-submit{ right: 48px; }
	.infinite-top-search-wrap .infinite-top-search-close{ font-size: 40px; }
	.infinite-top-search-wrap input.search-field{ padding-bottom: 10px; }

}

/**
 * 7.0 - Infinite
 */
 
/*------------------------*/
/*--- infinite lightbox ---*/
/*------------------------*/
.infinite-lightbox-wrapper{ background: #000; background: rgba(0, 0, 0, 0.78); position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 10001; overflow: auto; }
.infinite-lightbox-wrapper .infinite-lightbox-content-row{ display: table; width: 100%; height: 100%; position: relative; z-index: 10003; }
.infinite-lightbox-wrapper .infinite-lightbox-content-cell{ display: table-cell; vertical-align: middle; padding: 20px; overflow-y: auto; }
.infinite-lightbox-content-wrap{ display: none; background: #fff; }
.infinite-lightbox-wrapper .infinite-lightbox-content-wrap{ display: block; max-width: 835px; margin: 0 auto; }

.infinite-lightbox-wrapper .infinite-lightbox-title{ color: #0e0e0e; font-size: 14px; text-align: center; margin-bottom: 0; text-transform: uppercase; font-weight: 600; letter-spacing: 1px; }
.infinite-lightbox-wrapper .infinite-lightbox-head{ position: relative; padding-bottom: 24px; border-bottom-width: 2px; border-bottom-style: solid; margin-bottom: 45px; }
.infinite-lightbox-wrapper .infinite-lightbox-content-wrap{ padding: 30px; overflow: hidden; position: relative; }
.infinite-lightbox-wrapper .infinite-lightbox-close{ cursor: pointer; font-size: 25px; line-height: 25px; position: absolute; right: 20px; top: 20px; }

html.infinite-lightbox-on,
html.infinite-lightbox-on body{ position: relative; overflow: hidden; width: 100%; height: 100%; }

.infinite-top-cart-content-wrap .infinite-top-cart-title{ font-size: 17px; font-weight: 600; text-align: center; margin-top: 22px; margin-bottom: 45px; }
.infinite-top-cart-item{ margin-bottom: 35px; position: relative; }
.infinite-top-cart-item .infinite-top-cart-item-thumbnail{ max-width: 78px; float: left; margin-right: 28px; }
.infinite-top-cart-item .infinite-top-cart-item-content{ overflow: hidden; padding-top: 8px; }
.infinite-top-cart-item .infinite-top-cart-item-title{ font-size: 17px; font-weight: 800; margin-bottom: 2px; }
.infinite-top-cart-item .infinite-top-cart-item-info{ font-size: 15px; }
.infinite-top-cart-item .infinite-top-cart-item-remove{ position: absolute; right: 0; top: 50%; margin-top: -9px; font-size: 17px; cursor: pointer; }
.infinite-top-cart-item-wrap{ margin: 0 30px; }
.infinite-top-cart-item-wrap .infinite-top-cart-item-divider{ margin: 38px 0 30px; border-bottom-width: 1px; border-bottom-style: solid; }
.infinite-top-cart-item-wrap .infinite-top-cart-price-wrap{ font-size: 17px; }
.infinite-top-cart-item-wrap .infinite-top-cart-price-wrap .infinite-tail{ font-weight: 800; margin-left: 10px; }
.infinite-top-cart-button-wrap .infinite-button{ margin: 0 6px 20px; }

.infinite-top-cart-content-wrap .infinite-highlight,
.infinite-top-cart-item-wrap .infinite-top-cart-price-wrap .woocommerce-Price-amount.amount{ color: #fd2e2e; }

/*-------------------*/
/*--- wpml        ---*/
/*-------------------*/
ul.sf-menu > .menu-item-language img.iclflag{ vertical-align: baseline; margin-right: 10px; }
ul.sf-menu > .menu-item-language ul.submenu-languages{ display: none; position: absolute; z-index: 99;
	list-style: none; margin: 0 0 0 -15px; }
ul.sf-menu > .menu-item-language li{ padding: 1px 11px; }
ul.sf-menu > .menu-item-language li a{ padding: 6px 12px; display: block; }
ul.sf-menu > .menu-item-language li:first-child{ padding-top: 12px; }
ul.sf-menu > .menu-item-language li:last-child{ padding-bottom: 12px; }

.infinite-custom-wpml-flag{ margin-right: 26px; }
.infinite-custom-wpml-flag .infinite-custom-wpml-flag-item{ margin-left: 20px; }
.infinite-custom-wpml-flag .infinite-custom-wpml-flag-item:first-child{ margin-left: 0; }

.infinite-dropdown-wpml-flag{ position: relative; padding-top: 19px; padding-bottom: 19px; }
.infinite-dropdown-wpml-flag .infinite-dropdown-wpml-flag-background{ position: absolute; top: 0; bottom: 0; left: -27px; right: 0; }
.infinite-dropdown-wpml-flag .infinite-dropdown-wpml-current-language{ font-weight: bold; }
.infinite-dropdown-wpml-flag .infinite-dropdown-wpml-list{ position: absolute; left: -27px; min-width: 120px; top: 100%;
	z-index: 99; padding: 10px 18px; display: none; }
.infinite-dropdown-wpml-flag .infinite-dropdown-wpml-item{ display: block; white-space: nowrap; }
.infinite-dropdown-wpml-flag .infinite-dropdown-wpml-current-language{ margin-right: 25px; position: relative; }
.infinite-dropdown-wpml-current-language:after{ content: "\f0d7"; font-family: fontAwesome; margin-left: 12px; font-size: 12px; }

.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-head-wrap,
.infinite-dropdown-wpml-flag .infinite-dropdown-wpml-list{ background: #f3f3f3; }

/*-------------------*/
/*--- wpcf7       ---*/
/*-------------------*/
.infinite-body span.wpcf7-not-valid-tip{ font-size: 13px; margin-bottom: 15px; }
.infinite-body div.wpcf7-response-output{ font-size: 13px; padding: 6px 20px; clear: both; margin: 0; }

.wpcf7 input,
.wpcf7 select{ font-size: 15px; padding: 15px 22px; width: 100%; display: block; }
.wpcf7 input[type="button"], .wpcf7 input[type="submit"], input[type="reset"]{ width: auto; }
.wpcf7 input[type="checkbox"], .wpcf7 input[type="radio"]{ width: auto; display: inline; }
.wpcf7 .wpcf7-form-control .wpcf7-list-item{ display: block; margin-bottom: 5px; }
.wpcf7 textarea{ font-size: 15px; padding: 18px 22px; height: 120px; width: 100%; }

/*-------------------*/
/*--- woocommerce ---*/
/*-------------------*/

/* style */
figure.woocommerce-product-gallery__wrapper{ max-width: none; }
body.woocommerce .woocommerce-error, body.woocommerce .woocommerce-info, body.woocommerce .woocommerce-message{ padding: 0.7em 2em 1em 3.5em !important; }
.woocommerce .woocommerce-error, .woocommerce .woocommerce-info, .woocommerce .woocommerce-message{ padding: 0.7em 2em 1em 3.5em !important; }
.woocommerce .woocommerce-error:before, .woocommerce .woocommerce-info:before, .woocommerce .woocommerce-message:before{ top: 0.7em; }
.woocommerce .woocommerce-error .button, .woocommerce .woocommerce-info .button, .woocommerce .woocommerce-message .button{ margin-top: 5px; padding: 0; background-color: transparent; }
.woocommerce .woocommerce-error .button:hover, .woocommerce .woocommerce-info .button:hover, .woocommerce .woocommerce-message .button:hover{ background-color: transparent; }
.woocommerce div.product form.cart .variations td.label{ padding-right: 1.3em; padding-left: 1.3em; }
.woocommerce div.product form.cart .variations td.value, .woocommerce div.product form.cart .variations th.value{ min-width: 210px; }
.woocommerce div.product form.cart .variations select{ float: left; margin-top: 0.6em; margin-left: 2em; }
a.reset_variations{ margin-right: 15px; margin-left: 7px; }
.woocommerce div.product form.cart .variations select{ min-width: 59%; }
.related.products h2{ font-size: 23px; margin-bottom: 42px; }
.woocommerce table.shop_attributes th{ background: transparent; padding-top: 16px; padding-bottom: 16px; }
.woocommerce table.shop_attributes td, .woocommerce table.shop_attributes th{ border-bottom: 0; }
.woocommerce table.shop_attributes{ border-top: 0; }
.single-product.woocommerce #review_form #respond p.comment-form-comment{ margin-bottom: 25px; }
.woocommerce table.shop_table{ border: 0; }
.woocommerce table.shop_table.shop_table_responsive.cart{ border-spacing: 0; }
.woocommerce table.shop_table th{ padding: 17px 12px; background: none; }
.woocommerce table.shop_table.shop_table_responsive.cart tr:nth-child(odd), .woocommerce table.shop_table.shop_table_responsive.cart tr:nth-child(even){ background-color: transparent; }
.woocommerce table.shop_table td{ padding: 19px 20px; }
.woocommerce-cart table.cart img{ width: 70px; }
.woocommerce td.product-name dl.variation dd, .woocommerce td.product-name dl.variation dt{ margin-bottom: 0; }
.woocommerce a.remove{ width: auto; }
.cart_item input.input-text.qty.text{ padding: 15px 0 15px 10px; border-bottom-width: 1px; }
.woocommerce-cart table.cart td.actions .coupon .input-text{ padding: 15px 23px 15px; height: 49px; }
.woocommerce .cart input.button[name="apply_coupon"] { height: 49px; }
.woocommerce #content table.cart td.actions .input-text, .woocommerce table.cart td.actions .input-text, .woocommerce-page #content table.cart td.actions .input-text, .woocommerce-page table.cart td.actions .input-text{ width: 180px; }
.woocommerce .cart .button, .woocommerce .cart input.button{ padding: 19px 20px 18px; border-radius: 0; font-size: 12px; }
.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button{ padding: 15px 20px; border-radius: 0; font-size: 12px; }
.woocommerce #respond input#submit.disabled, .woocommerce #respond input#submit:disabled, .woocommerce #respond input#submit:disabled[disabled], .woocommerce a.button.disabled, .woocommerce a.button:disabled, .woocommerce a.button:disabled[disabled], .woocommerce button.button.disabled, .woocommerce button.button:disabled, .woocommerce button.button:disabled[disabled], .woocommerce input.button.disabled, .woocommerce input.button:disabled, .woocommerce input.button:disabled[disabled]{ padding: inherit; opacity: inherit; padding-left: 23px; padding-right: 23px; }
.woocommerce .cart_totals h2{ font-size: 18px; }
.woocommerce-cart .cart-collaterals .cart_totals tr td, .woocommerce-cart .cart-collaterals .cart_totals tr th{ border-top: 0; }
.woocommerce-cart .cart-collaterals .cart_totals table{ border-spacing: 0; margin: 0; }
.woocommerce-cart .wc-proceed-to-checkout{ padding: 0; }
.woocommerce table.shop_table tbody:first-child tr:first-child td, .woocommerce table.shop_table tbody:first-child tr:first-child th{ padding-left: 20px; padding-right: 20px; }
.woocommerce-cart .cart-collaterals .cart_totals tr td, .woocommerce-cart .cart-collaterals .cart_totals tr th{ padding-left: 20px; padding-right: 20px; }
.woocommerce a.showcoupon{ margin-left: 20px; color: #baf2ff; }
.woocommerce .woocommerce-billing-fields h3, .woocommerce .woocommerce-shipping-fields h3, #order_review_heading{ font-size: 17px; }
.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea{ padding: 13px 15px; border-bottom-width: 1px; }
.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1{ margin-bottom: 35px; }
.woocommerce .col2-set, .woocommerce-page .col2-set { width: 47%; float: left; margin-right: 3%; }
.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1{ width: 100%; }
.woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2{ width: 100%; }
div#order_review{ float: left; width: 50%; }
.woocommerce form .form-row{ margin: 0 0 14px; }
.woocommerce form p.form-row.form-row-first{ margin: 0; }
.select2-container .select2-choice { padding: 6px 1px 6px 13px; }
.woocommerce form .form-row-first, .woocommerce form .form-row-last, .woocommerce-page form .form-row-first, .woocommerce-page form .form-row-last{ width: 49%; }
.shop_table.woocommerce-checkout-review-order-table{ border-spacing: 0; }
.woocommerce table.shop_table th{ padding: 17px 20px 17px 20px; }
.woocommerce .cart_item td.product-total{ padding-right: 20px; padding-left: 20px; }
.woocommerce .cart_item td.product-name{ padding-left: 20px; }
.woocommerce table.shop_table td{ padding-right: 20px; }
.checkout_coupon .form-row.form-row-last{ margin-bottom: 0; }
table tr.cart_item{ background: transparent; }
.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea{ font-size: 13px; }
html .woocommerce a.remove{ border-radius: 100%; color: #333 !important; }
.woocommerce-checkout table tr.cart_item{ background: transparent; }
li.woocommerce-MyAccount-navigation-link { padding: 13px 0; padding-bottom: 13px; border-bottom-width: 1px; border-bottom-style: solid; }
.woocommerce-account .woocommerce-MyAccount-navigation { width: 28%; }
li.woocommerce-MyAccount-navigation-link.is-active { font-weight: 600; }
header.woocommerce-Address-title.title h3 { font-size: 18px; }
.woocommerce-Address address { padding: 25px;}
.u-columns.woocommerce-Addresses.col2-set.addresses { width: 100%; margin-bottom: 40px; }
.woocommerce-account .woocommerce-MyAccount-content { padding: 31px; }

.woocommerce-Address address,
.woocommerce-account .woocommerce-MyAccount-content { background: #f5f5f5; }

.woocommerce form .select2-selection{ border-width: 1px; border-style: solid; border-radius: 0; -moz-border-radius: 0; -webkit-border-radius: 0; height: 43px; padding: 7px 6px; font-size: 13px; }
.woocommerce form .select2-selection .select2-selection__arrow{ top: 8px; right: 6px; }

/*-- Widget --*/
.woocommerce ul.cart_list li dl dd, .woocommerce ul.cart_list li dl dt, .woocommerce ul.product_list_widget li dl dd, .woocommerce ul.product_list_widget li dl dt { margin-bottom: 0; }
.woocommerce ul.cart_list li dl, .woocommerce ul.product_list_widget li dl { padding-left: 0; border-left: 0; }
.woocommerce .widget_shopping_cart .cart_list li, .woocommerce.widget_shopping_cart .cart_list li { margin-bottom: 20px; border-bottom-width: 1px; border-bottom-style: solid; padding-bottom: 15px; }
.woocommerce .widget_shopping_cart .cart_list li:last-child, .woocommerce.widget_shopping_cart .cart_list li:last-child { margin-bottom: 15px; }
.woocommerce .widget_shopping_cart .total, .woocommerce.widget_shopping_cart .total { border-top: 0;  font-size: 17px; }
.widget_shopping_cart_content .total span.woocommerce-Price-amount.amount { margin-left: 5px;}
.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li img { width: 47px;}
.widget_shopping_cart a.button.wc-forward { padding: 19px 28px; font-size: 14px; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; }
.woocommerce .widget_price_filter .price_slider_amount .button { float: left; font-size: 14px; padding: 16px 26px; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; }
.price_slider_amount .price_label { margin-top: 24px; font-size: 17px; font-weight: 700; }
.woocommerce ul.cart_list li, .woocommerce ul.product_list_widget li { padding: 15px 0; border-bottom-style: solid; border-bottom-width: 1px; }
.woocommerce ul.cart_list li, .woocommerce ul.product_list_widget li { padding: 15px 0; margin: 0; list-style: none; border-bottom-style: solid; border-bottom-width: 1px; }
html ul.cart_list li a, html .woocommerce ul.product_list_widget li a { font-weight: 400; }
html .woocommerce ul.cart_list li a, html .woocommerce ul.product_list_widget li a { font-weight: 400; }
html .woocommerce ul.cart_list li .star-rating{ float: left; margin-top: 10px; }
html .woocommerce ul.product_list_widget li .star-rating { float: left; margin-top: 3px; margin-right: 10px; }
html .woocommerce ul.product_list_widget li del{ margin-right: 10px; }
html .product_list_widget span.reviewer { margin-top: 4px; margin-left: 12px; float: left; }
html .widget_product_search label.screen-reader-text { display: none; }
html .widget_product_search input[type="submit"]{ height: 47px; }
html .widget_product_search input#woocommerce-product-search-field { height: 47px; padding: 14px 12px; border-bottom-width: 1px; width: 62%; font-size: 13px; }
.widget.woocommerce ul.cart_list li:first-child, .widget.woocommerce ul.product_list_widget li:first-child{ padding-top: 0; }

/*-- Fixed Color --*/
.woocommerce .woocommerce-message:before { color: #fff; }
.woocommerce .woocommerce-error{ background-color: #bd584e; border-top: 3px solid #bd584e; color: #fff; }
.woocommerce .woocommerce-info{ background-color: #46a5ca; border-top: 3px solid #46a5ca; color: #fff; }
.woocommerce .woocommerce-message{ background-color: #46ca7b; border-top: 3px solid #46ca7b; color: #fff; }
.woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button{ color: #ffffff; }
.single-product.woocommerce div.product .quantity .qty{ background: #f3f3f3; }
.woocommerce .woocommerce-info:before{ color: #fff }
.woocommerce .woocommerce-error:before, .woocommerce .woocommerce-info:before, .woocommerce .woocommerce-message:before{ color: #fff !important; top: inherit; }

html .woocommerce a.remove:hover { color: #afafaf !important; background: transparent !important;}
html .woocommerce form .form-row .required { color: #c5c5c5; }
html .woocommerce form .form-row.woocommerce-invalid .select2-container, html .woocommerce form .form-row.woocommerce-invalid input.input-text, html .woocommerce form .form-row.woocommerce-invalid select {  border-color: #d4d4d4; }
html .woocommerce form .form-row.woocommerce-invalid label { color: #9e9e9e; }
html .woocommerce form .form-row.woocommerce-validated .select2-container, html .woocommerce form .form-row.woocommerce-validated input.input-text, html .woocommerce form .form-row.woocommerce-validated select { border-color: #d4d4d4; }

/* single product */
html .woocommerce span.onsale{ font-size: 13px; font-weight: 600; line-height: 48px; width: 48px; padding: 2px 0 0; height: 48px; }

.single-product.woocommerce .infinite-page-wrapper{ border-top-width: 1px; border-top-style: solid; }
.single-product.woocommerce .woocommerce-breadcrumb{ font-size: 14px; }
.single-product.woocommerce .product_title{ font-size: 40px; margin-bottom: 2px; }
.single-product.woocommerce div.product .woocommerce-product-rating{ margin-bottom: 2px; }
.single-product.woocommerce div.product .star-rating{ font-size: 14px; padding-right: 15px; box-sizing: content-box; margin-right: 10px; }
.single-product.woocommerce div.product .star-rating:before,
.single-product.woocommerce div.product .star-rating span:before{ letter-spacing: 3px; }
.single-product.woocommerce div.product .woocommerce-review-link{ font-size: 13px; }
.single-product.woocommerce div.product p.price{ padding-top: 27px; margin-bottom: 28px; }
.single-product.woocommerce div.product p.price ins{ text-decoration: none; }
.single-product.woocommerce div.product p.price .woocommerce-Price-amount{ font-size: 26px; font-weight: bold; }
.single-product.woocommerce div.product p.price del .woocommerce-Price-amount{ font-size: 23px; margin-right: 9px; }
.single-product.woocommerce div.product span.onsale{ position: static; display: inline-block; margin-right: 15px; vertical-align: middle; }
.single-product.woocommerce div.product .quantity .qty{ height: 60px; font-size: 16px;
	width: 75px; padding-left: 15px; border: none; }
.single-product.woocommerce div.product form.cart{ margin: 25px 0 55px 0; }
.single-product.woocommerce div.product form.cart.variations_form{ width: 300px; }
.single-product.woocommerce div.product form.cart .button { font-size: 13px; height: 60px; text-transform: uppercase;
	border-radius: 0; -moz-border-radius: 0; -webkit-border-radius: 0; padding: 10px 30px; }
.single-product.woocommerce div.product .product_meta{ margin-top: 50px; }
.single-product.woocommerce div.product .product_meta{ overflow: hidden; padding-top: 5px; }
.single-product.woocommerce div.product .product_meta > span{ display: block; font-size: 13px; margin-bottom: 5px; }
.single-product.woocommerce div.product .product_meta > span .infinite-head{ font-weight: 600; }
.single-product.woocommerce div.product .woocommerce-variation-add-to-cart-disabled{ display: none; }
.single-product.woocommerce div.product .infinite-woocommerce-social-share{ clear: both; padding-top: 15px; margin-top: 50px;
	border-top-width: 2px; border-top-style: solid; }
.single-product.woocommerce div.product .gdlr-core-social-share-item a{ font-size: 14px; }

.single-product.woocommerce div.product .infinite-woocommerce-tab{ clear: both; padding-top: 60px; margin-bottom: 50px; }
.single-product.woocommerce div.product .infinite-woocommerce-tab .gdlr-core-tab-item-title{ font-size: 16px;
		text-transform: none; letter-spacing: 0; font-weight: 600; padding-bottom: 18px; }
.single-product.woocommerce div.product .infinite-woocommerce-tab .gdlr-core-tab-item-title-line{ border-bottom-width: 1px; bottom: -1px; }
.single-product.woocommerce div.product .infinite-woocommerce-tab .gdlr-core-tab-item-title-wrap{ border-bottom-width: 1px; }
.single-product.woocommerce div.product .infinite-woocommerce-tab .gdlr-core-tab-item-content{ text-align: left; margin: 0 auto; }

.single-product.woocommerce .woocommerce-Reviews-title{ display: none; }
.single-product.woocommerce #reviews{ padding-top: 43px; }
.single-product.woocommerce #reviews .comment-respond{ text-align: left; }
.single-product.woocommerce #reviews #comments ol.commentlist li img.avatar{ border: none; width: 85px;
	position: static; padding: 0; border-radius: 50%; margin-right: 30px; }
.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text{ overflow: hidden; margin-left: 0;
	padding: 0; border: none; text-align: left; }
.single-product.woocommerce #reviews #comments ol.commentlist li{ padding: 39px 49px; margin-bottom: 30px; }
.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta{ margin-bottom: 18px; }
.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta strong[itemprop="author"]{ display: block; font-size: 17px; text-transform: capitalize; }
.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta time[itemprop="datePublished"]{ display: block; font-size: 15px; }
.single-product.woocommerce #reviews #comments ol.commentlist li .comment-text .star-rating{ float: none; margin-top: 24px; }
.single-product.woocommerce #reviews #review_form_wrapper{ padding-top: 35px; }
.single-product.woocommerce #reviews .comment-reply-title{ font-size: 22px; margin-bottom: 30px; }
.single-product.woocommerce #review_form #respond label{ font-size: 15px;font-weight: 600; }
.single-product.woocommerce #review_form #respond p{ margin-bottom: 30px; }
.single-product.woocommerce #review_form #respond label[for="rating"]{ display: inline-block; margin-right: 30px; vertical-align: middle; }
.single-product.woocommerce #review_form #respond p.stars{ display: inline-block; vertical-align: middle; margin-bottom: 0; font-size: 19px; line-height: 1; }
.single-product.woocommerce #review_form #respond p.stars a{ margin-right: 5px; }
.single-product.woocommerce #review_form #respond input[type="text"],
.single-product.woocommerce #review_form #respond input[type="email"]{ display: block; margin-top: 20px; border-bottom-width: 1px; width: 100%; height: 55px; }
.single-product.woocommerce #review_form #respond textarea{ display: block; margin-top: 20px; border-bottom-width: 1px; width: 100%; height: 150px; }
.single-product.woocommerce #review_form #respond .form-submit input{ font-size: 13px; height: 58px; text-transform: uppercase;
		border-radius: 0; -moz-border-radius: 0; -webkit-border-radius: 0; padding: 10px 38px; }
.woocommerce.single-product .related.products{ clear: left; }
.woocommerce-account .woocommerce-MyAccount-navigation > ul{ margin-left: 0; }
@media only screen and (max-width: 767px){
.woocommerce-account .woocommerce-MyAccount-navigation { width: auto; float: none; }
}

.woocommerce div.product form.cart .group_table td { padding: 0;  vertical-align: middle; }
table.woocommerce-grouped-product-list.group_table tr:nth-child(odd), table.woocommerce-grouped-product-list.group_table tr:nth-child(even){ background-color: transparent; }
body.woocommerce div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label{ padding-left: 25px; font-weight: 700; text-align: left; }
td.woocommerce-grouped-product-list-item__price{ text-align: left; font-weight: 600; }
.single-product.woocommerce div.product .quantity .qty{ height: 48px; font-size: 16px; width: 87px; background: transparent !important; border: 1px solid #e5e5e5; }
table.woocommerce-grouped-product-list.group_table{ border-spacing: 0 15px; }
.single-product.woocommerce div.product form.cart .button{ height: 47px; padding: 10px 40px; }
.single-product.woocommerce div.product p.price{ font-size: 32px !important; color: #bbb; }
.single-product.woocommerce div.product p.price{ margin-bottom: 0; }
.single-product.woocommerce div.product p.price{ padding-top: 20px; }
.single-product.woocommerce div.product form.cart{ margin: 25px 0 30px 0; }
.single-product.woocommerce div.product .product_meta{ margin-top: 30px; }
.single-product.woocommerce div.product .logisco-woocommerce-social-share{ clear: both; padding-top: 15px; margin-top: 30px; }
.single-product.woocommerce div.product .product_meta>span { font-size: 15px; }

/*-------------*/
/*--- mmenu ---*/
/*-------------*/
.mm-menu,.mm-panels,.mm-panels>.mm-panel{margin:0;left:0;right:0;top:0;bottom:0;z-index:0;box-sizing:border-box}
.mm-btn,.mm-menu{box-sizing:border-box}
.mm-listview a,.mm-listview a:hover,.mm-navbar a,.mm-navbar a:hover{text-decoration:none}
.mm-hidden{display:none!important}
.mm-wrapper{overflow-x:hidden;position:relative}
.mm-menu{display:block;padding:0;position:absolute}
.mm-panels,.mm-panels>.mm-panel{background:inherit;border-color:inherit;position:absolute}
.mm-btn,.mm-panel.mm-highest{z-index:1}
.mm-panels{overflow:hidden}
.mm-panel{transition:-webkit-transform .4s ease;transition:transform .4s ease;transition:transform .4s ease,-webkit-transform .4s ease;-webkit-transform:translate3d(100%,0,0);transform:translate3d(100%,0,0)}
.mm-panel.mm-opened{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
.mm-panel.mm-subopened{-webkit-transform:translate3d(-30%,0,0);transform:translate3d(-30%,0,0)}
.mm-panels>.mm-panel{-webkit-overflow-scrolling:touch;overflow:scroll;overflow-x:hidden;overflow-y:auto;padding:0 20px}
.mm-listview .mm-divider,.mm-listview>li>a,.mm-listview>li>span,.mm-navbar .mm-title{text-overflow:ellipsis;white-space:nowrap;overflow:hidden}
.mm-panels>.mm-panel.mm-hasnavbar{padding-top:40px}
.mm-vertical .mm-panel{-webkit-transform:none!important;transform:none!important}
.mm-listview .mm-vertical .mm-panel,.mm-vertical .mm-listview .mm-panel{display:none;padding:10px 0 10px 10px}
.mm-listview .mm-vertical .mm-panel .mm-listview>li:last-child:after,.mm-vertical .mm-listview .mm-panel .mm-listview>li:last-child:after{border-color:transparent}
.mm-vertical li.mm-opened>.mm-panel,li.mm-vertical.mm-opened>.mm-panel{display:block}
.mm-listview>li.mm-vertical>.mm-next,.mm-vertical .mm-listview>li>.mm-next{height:40px;bottom:auto}
.mm-listview>li.mm-vertical>.mm-next:after,.mm-vertical .mm-listview>li>.mm-next:after{top:16px;bottom:auto}
.mm-listview>li.mm-vertical.mm-opened>.mm-next:after,.mm-vertical .mm-listview>li.mm-opened>.mm-next:after{-webkit-transform:rotate(45deg);transform:rotate(45deg);right:19px}
.mm-btn{width:40px;height:40px;position:absolute;top:0}
.mm-clear:after,.mm-clear:before,.mm-close:after,.mm-close:before{content:'';border:2px solid transparent;display:block;width:5px;height:5px;margin:auto;position:absolute;top:0;bottom:0;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.mm-clear:before,.mm-close:before{border-right:none;border-bottom:none;right:18px}
.mm-clear:after,.mm-close:after{border-left:none;border-top:none;right:25px}
.mm-arrow:after,.mm-next:after{content:'';border:1px solid transparent;display:block;width:6px;height:6px;margin:auto;position:absolute;top:0;bottom:0;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.mm-prev:before{border-right:none;border-bottom:none;left:0
}
.mm-arrow:after,.mm-next:after{border-top:none;border-left:none;right:2px}
.mm-navbar{text-align:center;line-height:20px;height:40px;margin:0;}
.mm-navbar .mm-btn:last-child{text-align:right;padding-right:20px;right:0}
.mm-panel .mm-navbar{display:none}
.mm-panel.mm-hasnavbar .mm-navbar{display:block;margin: 0 25px 30px;position:relative;}
.mm-listview,.mm-listview>li{list-style:none;display:block;padding:0;margin:0}
.mm-listview{font:inherit;font-size:14px;line-height:20px}
.mm-listview>li{position:relative}
.mm-listview>li,.mm-listview>li .mm-next,.mm-listview>li .mm-next:before,.mm-listview>li:after{border-color:inherit}
.mm-listview>li>a,.mm-listview>li>span{color:inherit;display:block;margin:0}
.mm-listview>li>a.mm-arrow,.mm-listview>li>span.mm-arrow{padding-right:50px}
.mm-listview .mm-next{background:rgba(3,2,1,0);width:50px;padding:0;position:absolute;right:0;top:0;bottom:0;z-index:2}
.mm-listview .mm-next.mm-fullsubopen{width:100%}
.mm-listview .mm-next.mm-fullsubopen:before{border-left:none}
.mm-listview .mm-next.mm-fullsubopen+a,.mm-listview .mm-next.mm-fullsubopen+span{padding-right:50px;margin-right:0}
.mm-listview .mm-inset{list-style:disc inside;padding:0 10px 15px 40px;margin:0}
.mm-listview .mm-inset>li{padding:5px 0}
.mm-listview .mm-divider{font-size:10px;text-transform:uppercase;text-indent:20px;line-height:25px}
.mm-listview .mm-spacer{padding-top:40px}
.mm-listview .mm-spacer>.mm-next{top:40px}
.mm-listview .mm-spacer.mm-divider{padding-top:25px}
.mm-page{box-sizing:border-box;position:relative}
.mm-slideout{transition:-webkit-transform .4s ease;transition:transform .4s ease;transition:transform .4s ease,-webkit-transform .4s ease;z-index:2}
html.mm-opened{overflow-x:hidden;position:static;}
#mm-blocker{background:rgba(3,2,1,0);display:none;width:100%;height:100%;position:fixed;top:0;left:0;z-index:100}
html.mm-blocking #mm-blocker{display:block}
.mm-menu.mm-offcanvas{z-index:0;display:none;position:fixed}
.mm-menu.mm-offcanvas.mm-current{display:block}
.mm-menu{width:350px}
@media all and (max-width:550px){.mm-menu{width:250px}}
html.mm-opening .mm-slideout{-webkit-transform:translate3d(350px,0,0);transform:translate3d(350px,0,0)}
@media all and (max-width:550px){html.mm-opening .mm-slideout{-webkit-transform:translate3d(250px,0,0);transform:translate3d(250px,0,0)}}
/* right effect */
html.mm-right.mm-opened .mm-menu.mm-effect-menu-zoom{-webkit-transform:scale(.7,.7) translate3d(30%,0,0);transform:scale(.7,.7) translate3d(30%,0,0);-webkit-transform-origin:right center;transform-origin:right center}
html.mm-right.mm-opening .mm-menu.mm-effect-menu-zoom{-webkit-transform:scale(1,1) translate3d(0,0,0);transform:scale(1,1) translate3d(0,0,0)}
html.mm-right.mm-opened .mm-menu.mm-effect-menu-slide{-webkit-transform:translate3d(30%,0,0);transform:translate3d(30%,0,0)}
html.mm-right.mm-opening .mm-menu.mm-effect-menu-slide{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
html.mm-right.mm-opening.mm-fullscreen .mm-slideout{-webkit-transform:translate3d(-100%,0,0);transform:translate3d(-100%,0,0)}
.mm-menu.mm-pageshadow.mm-right:after{left:auto;right:100%}
.mm-menu.mm-right{left:auto;right:0}
html.mm-right.mm-opening .mm-slideout{-webkit-transform:translate3d(-350px,0,0);transform:translate3d(-350px,0,0)}
@media all and (max-width:550px){html.mm-right.mm-opening .mm-slideout{-webkit-transform:translate3d(-250px,0,0);transform:translate3d(-250px,0,0)}}
.mm-menu.mm-front.mm-right,.mm-menu.mm-next.mm-right{-webkit-transform:translate3d(100%,0,0);transform:translate3d(100%,0,0)}
/* page dim */
html.mm-pagedim #mm-blocker,html.mm-pagedim-black #mm-blocker,html.mm-pagedim-white #mm-blocker{opacity:0}
html.mm-pagedim-black.mm-opening #mm-blocker,html.mm-pagedim-white.mm-opening #mm-blocker,html.mm-pagedim.mm-opening #mm-blocker{opacity:.3;transition: ease .4s}
html.mm-pagedim #mm-blocker{background:inherit}
html.mm-pagedim-white #mm-blocker{background:#fff}
html.mm-pagedim-black #mm-blocker{background:#000}

/* custom */
ul.mm-listview li{ margin: 0 25px; border-bottom-width: 1px; border-bottom-style: solid; }
ul.mm-listview li a, ul.mm-listview li span.infinite-mm-menu-blank{ font-size: 12px; text-transform: uppercase; font-weight: 700; letter-spacing: 1px; padding: 17px 0; }
.mm-navbar .mm-btn{ line-height: 40px; font-size: 30px; display: block; text-align: left; left: -8px; padding: 0; }
.mm-navbar .mm-btn:before{ content: "\34"; font-family: elegantIcons; }
.mm-navbar .mmenu-custom-close{ line-height: 40px; font-size: 30px; display: block; text-align: left; margin-left: -8px; cursor: pointer; }
.mm-navbar .mmenu-custom-close:after{ content: "\4d"; font-family: elegantIcons; }
.mm-navbar .mm-title{ display: none; }
.mm-navbar .mm-title:first-child{ display: block; }

html.infinite-mmenu-left .infinite-sticky-navigation.infinite-fixed-navigation,
html.infinite-mmenu-right .infinite-sticky-navigation.infinite-fixed-navigation{ transition: -webkit-transform .4s ease; transition: transform .4s ease; transition: transform .4s ease,-webkit-transform .4s ease; }
html.mm-opening.infinite-mmenu-left .infinite-sticky-navigation.infinite-fixed-navigation{ -webkit-transform: translate3d(350px,0,0); transform: translate3d(350px,0,0); }
html.mm-opening.infinite-mmenu-right .infinite-sticky-navigation.infinite-fixed-navigation{ -webkit-transform: translate3d(-350px,0,0); transform: translate3d(-350px,0,0); }
html.infinite-mmenu-left .infinite-mobile-header-wrap .infinite-top-bar,
html.infinite-mmenu-left .infinite-mobile-header,
html.infinite-mmenu-right .infinite-mobile-header-wrap .infinite-top-bar,
html.infinite-mmenu-right .infinite-mobile-header{ transition: -webkit-transform .4s ease; transition: transform .4s ease; transition: transform .4s ease,-webkit-transform .4s ease; }
html.mm-opening.infinite-mmenu-left .infinite-mobile-header-wrap .infinite-top-bar,
html.mm-opening.infinite-mmenu-left .infinite-mobile-header{ -webkit-transform: translate3d(350px,0,0); transform: translate3d(350px,0,0); }
html.mm-opening.infinite-mmenu-right .infinite-mobile-header-wrap .infinite-top-bar,
html.mm-opening.infinite-mmenu-right .infinite-mobile-header{ -webkit-transform: translate3d(-350px,0,0); transform: translate3d(-350px,0,0); }
@media all and (max-width:550px){
	html.mm-opening.infinite-mmenu-left .infinite-sticky-navigation.infinite-fixed-navigation{ -webkit-transform: translate3d(250px,0,0); transform: translate3d(250px,0,0); }
	html.mm-opening.infinite-mmenu-right .infinite-sticky-navigation.infinite-fixed-navigation{ -webkit-transform: translate3d(-250px,0,0); transform: translate3d(-250px,0,0); }
	html.mm-opening.infinite-mmenu-left .infinite-mobile-header-wrap .infinite-top-bar,
	html.mm-opening.infinite-mmenu-left .infinite-mobile-header{ -webkit-transform: translate3d(250px,0,0); transform: translate3d(250px,0,0); }
	html.mm-opening.infinite-mmenu-right .infinite-mobile-header-wrap .infinite-top-bar,
	html.mm-opening.infinite-mmenu-right .infinite-mobile-header{ -webkit-transform: translate3d(-250px,0,0); transform: translate3d(-250px,0,0); }
}

/*-----------------*/
/*--- superfish ---*/
/*-----------------*/

/* top level */
.sf-menu{ list-style: none; margin-left: 0; }
.sf-menu > li{ float: left; cursor: pointer; }
.sf-menu > li > a{ display: block; text-decoration: none; position: relative; z-index: 9; }

/* normal menu */
.sf-menu > .infinite-normal-menu ul{ list-style: none; position: absolute; display: none;
	z-index: 99; min-width: 220px; }
.sf-menu > .infinite-normal-menu ul{ margin-left: -15px; }
.sf-menu > .infinite-normal-menu ul ul{ margin-left: 0; }

.sf-menu > .infinite-normal-menu.sfHover > ul,
.sf-menu > .infinite-normal-menu.sfHover > ul:hover,
.sf-menu > .infinite-normal-menu li.sfHover > ul{ display: block; }
.sf-menu > .infinite-normal-menu ul ul{ top: 0; left: 100%; }
.sf-menu > .infinite-normal-menu ul ul.sub-menu-right{ left: auto; right: 100%; }
.sf-menu > .infinite-normal-menu li{ position: relative; white-space: nowrap; }
.sf-menu > .infinite-normal-menu li a{ text-decoration: none; }
.sf-menu > .infinite-normal-menu li > a.sf-with-ul-pre{}
.sf-menu > .infinite-normal-menu li > a.sf-with-ul-pre:after{ content: '>'; }

/* mega menu */
.sf-menu > .infinite-mega-menu .sf-mega{ position: absolute; display: none; z-index: 99;}
.sf-menu > .infinite-mega-menu.sfHover .sf-mega,
.sf-menu > .infinite-mega-menu.sfHover .sf-mega:hover{ display: block; }
.sf-menu > .infinite-mega-menu .sf-mega-section-inner a{ text-decoration: none; }
.sf-menu > .infinite-mega-menu .sf-mega-section-inner ul{ list-style: none; margin-left: 0; margin-bottom: 0; }

/* vertical menu */
.sf-vertical{ list-style: none; margin-left: 0; left: 100%; top: 0; margin-bottom: 0; }
.sf-vertical li{ position: relative; }
.sf-vertical ul.sub-menu{ list-style: none; position: absolute; display: none; left: 100%; top: 0; z-index: 9; min-width: 190px; }
.sf-vertical ul.sub-menu li{ white-space: nowrap; }