.footer-logos {
  display: flex;
  align-items: center;
  img {
    max-height: 80px;
    width: auto;
    height: auto;
  }
  .state-logo {
    border-radius: 100%;
    margin-right: 30px;
  }
  .bridge-usa-logo {
    background-color: #fff;
    border-radius: 4px;
  }
  @media (max-width: 420px) {
    flex-direction: column;
    align-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    img {
      margin: auto;
      max-height: 120px;
      max-width: 100%;
    }
    .state-logo {
      margin-right: auto;
      margin-bottom: 20px;
    }
  }

}