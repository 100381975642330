.contact-page-logo-cont {
    padding: 0 !important;

    .logo-bottom-block {
        .head {
            font-size: 18px;
            letter-spacing: 1px;
            text-align: center;
            color: #383838;
            font-weight: 800;
            display: block;
            background: #cecfd1;
            line-height: 44px;
            font-family: "Raleway", sans-serif;
        }
    }

    .proud-members {
        background: #d7d8dc;
        padding: 20px 0;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
}

.gravity-blog-detail {
    .infinite-blog-title-wrap {
        .infinite-blog-title-container {
            height: 845px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .infinite-blog-title-content {
            padding: 0 110px;
        }
    }

    .infinite-single-article-title {
        font-size: 74px;
        margin-bottom: 10px;
        letter-spacing: 0;
    }

    .infinite-blog-info {
        text-transform: none;

        div.tag {
            margin: 0;
            display: inline-block;
            font-size: 20px;

            &:before {
                content: "/";
                display: inline-block;
                margin: 0 10px;
                vertical-align: middle;
                font-weight: 400;
            }

            &:first-child {
                margin-left: 0;

                &:before {
                    display: none;
                }
            }

            a {
                font-size: 17px;
                font-weight: 400;
                letter-spacing: 0;
            }
        }
    }

    .infinite-sidebar-left {
        text-align: center;
        padding-top: 100px;

        .author-info {
            display: inline-block;
            margin: auto;

            .author-img {
                border-radius: 100%;
                border: 3px solid #143655;
            }

            .author-text {
                margin-top: 10px;

                .name {
                    color: #9fa1a0;
                    font-size: 12px;

                    a {
                        font-size: 15px;
                        color: #2c2b30;
                    }
                }

                .title {
                    color: #9fa1a0;
                    font-size: 15px;
                }
            }
        }
    }

    .infinite-content-area {
        padding-top: 100px;
    }
}

.infinite-body {
    .gravity-blog-detail {
        h2, h3, h4 {
            font-size: 21px;
            letter-spacing: normal;
            margin-bottom: 25px;
        }
    }

    .gdlr-core-text-box-item-content {
        font-size: 15px;
    }

    blockquote {
        border-color: #143655;
        color: inherit;
        font-family: inherit;
        background-color: transparent;
    }
}

.block-team-members {
    padding: 4rem 0 0 0;

    .gdlr-core-personnel-gv {
        max-width: 1400px;
        margin: auto;
    }

    h2 {
        text-align: center;
        margin: auto;
        font-weight: 400;
        font-size: 40px;
        margin-bottom: 1rem;
    }
}

.gv-html {
    .gdlr-core-text-box-item-content {
        text-transform: none;
    }
}

#rev_slider_1_1_wrapper {
    .tparrows {
        width: 64px;
        height: 64px;
        background: transparent !important;

        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background: url("../images/icon-ring-arrow.png") transparent no-repeat center center !important;
        }

        &.tp-leftarrow {
            &:before {
                transform: rotate(180deg);
            }
        }
    }
}

.slider-btn {
    background: #f26e29;
    text-transform: uppercase;
    color: #fff !important;
    border-width: 0;
    margin: 0;
    padding: 15px 50px !important;
    letter-spacing: 3px !important;
    font-weight: 400;
    font-size: 13px !important;
}

.main-page-news {
    background: #00294b;

    .main-page-news-container {
        max-width: 1600px;
        margin: auto;
        padding: 15px 20px;
        color: #fff;
        min-height: 230px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    h3 {
        font-size: 30px;
        color: #fff;
        max-width: 300px;
        margin: 0;
        padding: 0 20px 0 0;
    }

    .slider-side {
        flex: 1;
    }

    a {
        color: #fff;

        &:hover {
            color: #e1e1e1;
        }
    }

    .slide {
        position: relative;
        display: inline-block;

        span.time {
            position: absolute;
            right: 10px;
            top: 0;
            background: #00294b;
            padding: 14px 22px;
            font-weight: bold;
            text-align: center;
            z-index: 5;
        }

        span.title {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            background: rgba(0, 41, 75, 0.37);
            line-height: 34px;
            height: 34px;
            font-weight: bold;
            text-align: center;
            z-index: 5;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 10px;
        }
    }

    .flex-nav-prev, .flex-nav-next {
        right: -20px !important;
        left: auto !important;

        a {
            width: 64px;
            height: 64px;
            background: url("../images/icon-ring-arrow.png") transparent no-repeat center center !important;
        }

        .arrow_carrot-right, .arrow_carrot-left {
            display: none;
        }
    }

    .flex-nav-prev {
        margin-top: -60px !important;

        a {
            transform: rotate(180deg);
        }
    }

    .flex-nav-next {
        margin-top: 20px !important;
    }
}

@media (max-width: 767px) {
    .gravity-blog-detail {
        .infinite-blog-title-wrap {

            .infinite-blog-title-container {
                height: auto;
                max-width: none;
            }

            .infinite-blog-title-content {
                padding: 0 25px;
            }
        }

        .infinite-blog-info {
            div.tag {
                display: block;
            }

            div.tag:before {
                display: none;
            }
        }
    }
}


