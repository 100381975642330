.infinite-body h1, .infinite-body h2, .infinite-body h3, .infinite-body h4, .infinite-body h5, .infinite-body h6{ margin-top: 0; margin-bottom: 20px; line-height: 1.2; font-weight: 700; }
#poststuff .gdlr-core-page-builder-body h2{ padding: 0; margin-bottom: 20px; line-height: 1.2; font-weight: 700; }
#poststuff .gdlr-core-page-builder-body h1{ padding: 0; font-weight: 700; }

.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder .flex-control-nav li a{ width: 27px; height: 7px; }
.gdlr-core-newsletter-item.gdlr-core-style-rectangle .gdlr-core-newsletter-email input[type="email"]{ line-height: 17px; padding: 30px 20px; height: 65px; }
.gdlr-core-newsletter-item.gdlr-core-style-rectangle .gdlr-core-newsletter-submit input[type="submit"]{ height: 65px; font-size: 13px; }
body.infinite-boxed .infinite-body-wrapper{ margin-top: 0; margin-bottom: 0; }
.infinite-body-wrapper.infinite-with-frame, body.infinite-full .infinite-fixed-footer{ margin: 0; }
.infinite-body-background{ opacity: 1; }
.infinite-background-pattern .infinite-body-outer-wrapper{ background-image: url(https://gravityintprog.com/wp-content/plugins/goodlayers-core/include/images/pattern/pattern-1.png); }
.infinite-item-pdlr, .gdlr-core-item-pdlr{ padding-left: 20px; padding-right: 20px; }.infinite-item-rvpdlr, .gdlr-core-item-rvpdlr{ margin-left: -20px; margin-right: -20px; }.gdlr-core-metro-rvpdlr{ margin-top: -20px; margin-right: -20px; margin-bottom: -20px; margin-left: -20px; }.infinite-item-mglr, .gdlr-core-item-mglr, .infinite-navigation .sf-menu > .infinite-mega-menu .sf-mega,.sf-menu.infinite-top-bar-menu > .infinite-mega-menu .sf-mega{ margin-left: 20px; margin-right: 20px; }.infinite-body .gdlr-core-personnel-item .gdlr-core-flexslider.gdlr-core-with-outer-frame-element .flex-viewport, .infinite-body .gdlr-core-hover-box-item .gdlr-core-flexslider.gdlr-core-with-outer-frame-element .flex-viewport,.infinite-body .gdlr-core-blog-item .gdlr-core-flexslider.gdlr-core-with-outer-frame-element .flex-viewport{ padding-top: 20px; margin-top: -20px; padding-right: 20px; margin-right: -20px; padding-left: 20px; margin-left: -20px; padding-bottom: 20px; margin-bottom: -20px; }.gdlr-core-pbf-wrapper-container-inner{ width: calc(100% - 20px - 20px); }
.infinite-container, .gdlr-core-container, body.infinite-boxed .infinite-body-wrapper, body.infinite-boxed .infinite-fixed-footer .infinite-footer-wrapper, body.infinite-boxed .infinite-fixed-footer .infinite-copyright-wrapper{ max-width: 1320px; }
.infinite-body-front .gdlr-core-container, .infinite-body-front .infinite-container{ padding-left: 35px; padding-right: 35px; }.infinite-body-front .infinite-container .infinite-container, .infinite-body-front .infinite-container .gdlr-core-container, .infinite-body-front .gdlr-core-container .gdlr-core-container{ padding-left: 0; padding-right: 0; }
.infinite-top-bar-container.infinite-top-bar-custom-container{ max-width: 1140px; }
.infinite-top-bar-container.infinite-top-bar-full{ padding-right: 15px; padding-left: 15px; }
.infinite-top-bar{ padding-top: 10px; }
.infinite-top-bar{ padding-bottom: 10px; }.infinite-top-bar .infinite-top-bar-menu > li > a{ padding-bottom: 10px; }.sf-menu.infinite-top-bar-menu > .infinite-mega-menu .sf-mega, .sf-menu.infinite-top-bar-menu > .infinite-normal-menu ul{ margin-top: 10px; }
.infinite-top-bar{ font-size: 15px; }
.infinite-top-bar{ border-bottom-width: 0; }
.infinite-top-bar{ box-shadow: 0 0 rgba(0, 0, 0, 0.1); -webkit-box-shadow: 0 0 rgba(0, 0, 0, 0.1); -moz-box-shadow: 0 0 rgba(0, 0, 0, 0.1); }
.infinite-header-style-plain{ border-bottom-width: 0; }
.infinite-header-background-transparent .infinite-top-bar-background{ opacity: 0.5; }
.infinite-header-background-transparent .infinite-header-background{ opacity: 1; }
.infinite-navigation-bar-wrap.infinite-style-transparent .infinite-navigation-background{ opacity: 0.5; }
.infinite-header-boxed-wrap .infinite-top-bar-background{ opacity: 0; }
.infinite-header-boxed-wrap .infinite-top-bar-background{ margin-bottom: -0px; }
.infinite-header-style-boxed{ margin-top: 0; }
.infinite-header-container.infinite-header-custom-container{ max-width: 1140px;}
.infinite-header-container.infinite-header-full{ padding-right: 30px; padding-left: 30px; }
.infinite-header-boxed-wrap .infinite-header-background{ border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; }
.infinite-header-style-boxed .infinite-header-container-item{ padding-left: 30px; padding-right: 30px; }.infinite-navigation-right{ right: 30px; } .infinite-navigation-left{ left: 30px; }
.infinite-header-style-plain.infinite-style-splitted-menu .infinite-navigation .sf-menu > li > a{ padding-top: 0; }  .infinite-header-style-plain.infinite-style-splitted-menu .infinite-main-menu-left-wrap,.infinite-header-style-plain.infinite-style-splitted-menu .infinite-main-menu-right-wrap{ padding-top: 0; }
.infinite-header-style-boxed.infinite-style-splitted-menu .infinite-navigation .sf-menu > li > a{ padding-top: 0; }  .infinite-header-style-boxed.infinite-style-splitted-menu .infinite-main-menu-left-wrap,.infinite-header-style-boxed.infinite-style-splitted-menu .infinite-main-menu-right-wrap{ padding-top: 0; }
.infinite-navigation .sf-menu > li{ padding-left: 20px; padding-right: 20px; }
.infinite-navigation .infinite-main-menu{ margin-left: 0; }
.infinite-navigation .infinite-navigation-slide-bar{ margin-top: -28px; }
.infinite-header-side-nav{ width: 305px; }.infinite-header-side-content.infinite-style-left{ margin-left: 305px; }.infinite-header-side-content.infinite-style-right{ margin-right: 305px; }
.infinite-header-side-nav.infinite-style-side{ padding-left: 70px; padding-right: 70px; }.infinite-header-side-nav.infinite-style-left .sf-vertical > li > ul.sub-menu{ padding-left: 70px; }.infinite-header-side-nav.infinite-style-right .sf-vertical > li > ul.sub-menu{ padding-right: 70px; }
 .infinite-navigation .sf-vertical > li{ padding-top: 16px; padding-bottom: 16px; }
.infinite-header-style-bar .infinite-logo-right-text{ padding-top: 30px; }
.infinite-header-style-plain{ box-shadow: 0 0 rgba(204, 204, 204, 0.1); -webkit-box-shadow: 0 0 rgba(204, 204, 204, 0.1); -moz-box-shadow: 0 0 rgba(204, 204, 204, 0.1); }
.infinite-logo{ padding-top: 18px; }
.infinite-logo{ padding-bottom: 1px; }
.infinite-logo-inner{ max-width: 113px; }
@media only screen and (max-width: 767px){ .infinite-mobile-header .infinite-logo-inner{ max-width: 120px; } }
.infinite-navigation{ padding-top: 38px; }.infinite-navigation-top{ top: 54px; }
.infinite-navigation .sf-menu > li > a{ padding-bottom: 34px; }
.infinite-navigation .infinite-main-menu{ padding-right: 0; }
.infinite-main-menu-right-wrap{ margin-top: 5px; }
.infinite-animate-fixed-navigation.infinite-header-style-plain .infinite-logo, .infinite-animate-fixed-navigation.infinite-header-style-boxed .infinite-logo{ padding-top: 25px; }
.infinite-animate-fixed-navigation.infinite-header-style-plain .infinite-logo, .infinite-animate-fixed-navigation.infinite-header-style-boxed .infinite-logo{ padding-bottom: 20px; }
.infinite-animate-fixed-navigation.infinite-header-style-plain .infinite-navigation, .infinite-animate-fixed-navigation.infinite-header-style-boxed .infinite-navigation{ padding-top: 26px; }.infinite-animate-fixed-navigation.infinite-header-style-plain .infinite-navigation-top, .infinite-animate-fixed-navigation.infinite-header-style-boxed .infinite-navigation-top{ top: 26px; }
.infinite-animate-fixed-navigation.infinite-header-style-plain .infinite-navigation .sf-menu > li > a, .infinite-animate-fixed-navigation.infinite-header-style-boxed .infinite-navigation .sf-menu > li > a{ padding-bottom: 28px; }
.infinite-page-title-wrap.infinite-style-custom .infinite-page-title-content{ padding-top: 93px; }
.infinite-page-title-wrap.infinite-style-custom .infinite-page-title-content{ padding-bottom: 87px; }
.infinite-page-title-wrap.infinite-style-custom .infinite-page-caption{ margin-top: 13px; }
.infinite-page-title-wrap.infinite-style-custom .infinite-page-title{ font-size: 37px; }
.infinite-page-title-wrap.infinite-style-custom .infinite-page-title{ letter-spacing: 0; }
.infinite-page-title-wrap.infinite-style-custom .infinite-page-caption{ font-size: 16px; }
.infinite-page-title-wrap.infinite-style-custom .infinite-page-caption{ letter-spacing: 0; }
.infinite-page-title-wrap .infinite-page-title-top-gradient{ height: 413px; }
.infinite-page-title-wrap .infinite-page-title-bottom-gradient{ height: 413px; }
.infinite-page-title-wrap .infinite-page-title-overlay{ opacity: 0.48; }
.infinite-not-found-wrap .infinite-not-found-background{ opacity: 0.27; }
.infinite-blog-title-wrap.infinite-style-custom .infinite-blog-title-content{ padding-top: 93px; }
.infinite-blog-title-wrap.infinite-style-custom .infinite-blog-title-content{ padding-bottom: 87px; }
.infinite-blog-title-wrap.infinite-feature-image .infinite-blog-title-top-overlay{ height: 413px; }
.infinite-blog-title-wrap.infinite-feature-image .infinite-blog-title-bottom-overlay{ height: 413px; }
.infinite-blog-title-wrap .infinite-blog-title-overlay{ opacity: 0.75; }
body.single-post .infinite-sidebar-style-none, body.blog .infinite-sidebar-style-none, .infinite-blog-style-2 .infinite-comment-content{ max-width: 980px; }
.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-icon{ font-size: 22px; }
.infinite-footer-wrapper{ padding-top: 70px; }
.infinite-footer-wrapper{ padding-bottom: 50px; }
.infinite-copyright-container{ padding-top: 29px; }
.infinite-copyright-container{ padding-bottom: 29px; }
.infinite-body h1, .infinite-body h2, .infinite-body h3, .infinite-body h4, .infinite-body h5, .infinite-body h6, .infinite-body .infinite-title-font,.infinite-body .gdlr-core-title-font{ font-family: "Raleway", sans-serif; }.woocommerce-breadcrumb, .woocommerce span.onsale, .single-product.woocommerce div.product p.price .woocommerce-Price-amount, .single-product.woocommerce #review_form #respond label{ font-family: "Raleway", sans-serif; }
.infinite-navigation .sf-menu > li > a, .infinite-navigation .sf-vertical > li > a, .infinite-navigation-font{ font-family: "Karla", sans-serif; }
.infinite-body, .infinite-body .gdlr-core-content-font, .infinite-body input, .infinite-body textarea, .infinite-body button, .infinite-body select, .infinite-body .infinite-content-font, .gdlr-core-audio .mejs-container *{ font-family: "Comfortaa", display; }
.infinite-body .gdlr-core-info-font, .infinite-body .infinite-info-font{ font-family: "Comfortaa", display; }
.infinite-body .gdlr-core-blog-info-font, .infinite-body .infinite-blog-info-font{ font-family: "Comfortaa", display; }
.infinite-body .gdlr-core-quote-font, blockquote{ font-family: Georgia, serif; }
.infinite-body .gdlr-core-testimonial-content{ font-family: "Comfortaa", display; }
.infinite-body h1{ font-size: 52px; }
.infinite-body h2, #poststuff .gdlr-core-page-builder-body h2{ font-size: 48px; }
.infinite-body h3{ font-size: 36px; }
.infinite-body h4{ font-size: 28px; }
.infinite-body h5{ font-size: 22px; }
.infinite-body h6{ font-size: 18px; }
.infinite-body{ font-size: 15px; }
.infinite-body, .infinite-body p, .infinite-line-height, .gdlr-core-line-height{ line-height: 1.7; }
.infinite-navigation .sf-menu > li > a, .infinite-navigation .sf-vertical > li > a{ font-size: 16px; }
.infinite-navigation .sf-menu > li > a, .infinite-navigation .sf-vertical > li > a{ font-weight: 500; }
.infinite-navigation .sf-menu > li > a, .infinite-navigation .sf-vertical > li > a{ letter-spacing: 0; }
.infinite-navigation .sf-menu > li > a, .infinite-navigation .sf-vertical > li > a{ text-transform: none; }
.infinite-main-menu-right-button{ font-size: 11px; }
.infinite-main-menu-right-button{ text-transform: uppercase; }
.infinite-sidebar-area .infinite-widget-title{ font-size: 13px; }
.infinite-sidebar-area .infinite-widget-title{ text-transform: uppercase; }
.infinite-footer-wrapper .infinite-widget-title{ font-size: 14px; }
.infinite-footer-wrapper .infinite-widget-title{ text-transform: uppercase; }
.infinite-footer-wrapper{ font-size: 15px; }
.infinite-footer-wrapper .widget_text{ text-transform: none; }
.infinite-copyright-text, .infinite-copyright-left, .infinite-copyright-right{ font-size: 14px; }
.infinite-copyright-text, .infinite-copyright-left, .infinite-copyright-right{ font-weight: 600; }
.infinite-copyright-text, .infinite-copyright-left, .infinite-copyright-right{ text-transform: uppercase; }
.infinite-top-bar-background{ background-color: #222222; }
.infinite-body .infinite-top-bar{ border-bottom-color: #ffffff; }
.infinite-top-bar{ color: #ffffff; }
.infinite-body .infinite-top-bar a{ color: #ffffff; }
.infinite-body .infinite-top-bar a:hover{ color: #ffffff; }
.infinite-top-bar .infinite-top-bar-right-social a{ color: #ffffff; }
.infinite-top-bar .infinite-top-bar-right-social a:hover{ color: #ffffff; }
.infinite-header-background, .infinite-sticky-menu-placeholder, .infinite-header-style-boxed.infinite-fixed-navigation{ background-color: #757575; }
.infinite-header-wrap.infinite-header-style-plain{ border-color: #e8e8e8; }
.infinite-header-side-nav.infinite-style-side-toggle .infinite-logo{ background-color: #ffffff; }
.infinite-top-menu-button i, .infinite-mobile-menu-button i{ color: #ffffff; }.infinite-mobile-button-hamburger:before, .infinite-mobile-button-hamburger:after, .infinite-mobile-button-hamburger span{ background: #ffffff; }
.infinite-main-menu-right .infinite-top-menu-button, .infinite-mobile-menu .infinite-mobile-menu-button{ border-color: #dddddd; }
.infinite-top-search-wrap{ background-color: #000000; background-color: rgba(0, 0, 0, 0.88); }
.infinite-top-cart-content-wrap .infinite-top-cart-content{ background-color: #303030; }
.infinite-top-cart-content-wrap .infinite-top-cart-title, .infinite-top-cart-item .infinite-top-cart-item-title, .infinite-top-cart-item .infinite-top-cart-item-remove{ color: #000000; }
.infinite-top-cart-content-wrap .woocommerce-Price-amount.amount{ color: #6c6c6c; }
.infinite-body .infinite-top-cart-button-wrap .infinite-top-cart-button{ color: #ffffff; }
.infinite-body .infinite-top-cart-button-wrap .infinite-top-cart-button{ background-color: #f4f4f4; }
.infinite-body .infinite-top-cart-button-wrap .infinite-top-cart-button-2{ color: #bd584e; }
.infinite-body .infinite-top-cart-button-wrap .infinite-top-cart-button-2{ background-color: #000000; }
.infinite-body .infinite-breadcrumbs, .infinite-body .infinite-breadcrumbs a span, .gdlr-core-breadcrumbs-item, .gdlr-core-breadcrumbs-item a span{ color: #c0c0c0; }
.infinite-body .infinite-breadcrumbs span, .infinite-body .infinite-breadcrumbs a:hover span, .gdlr-core-breadcrumbs-item span, .gdlr-core-breadcrumbs-item a:hover span{ color: #777777; }
.infinite-navigation-background{ background-color: #f4f4f4; }
.infinite-navigation-bar-wrap{ border-color: #e8e8e8; }
.infinite-navigation .infinite-navigation-slide-bar{ border-color: #ffffff; }.infinite-navigation .infinite-navigation-slide-bar:before{ border-bottom-color: #ffffff; }
.sf-menu > li > a, .sf-vertical > li > a{ color: #ffffff; }
.sf-menu > li > a:hover, .sf-menu > li.current-menu-item > a, .sf-menu > li.current-menu-ancestor > a, .sf-vertical > li > a:hover, .sf-vertical > li.current-menu-item > a, .sf-vertical > li.current-menu-ancestor > a{ color: #ffffff; }
.sf-menu > .infinite-normal-menu li, .sf-menu > .infinite-mega-menu > .sf-mega, .sf-vertical ul.sub-menu li, ul.sf-menu > .menu-item-language li{ background-color: #081826; }
.sf-menu > li > .sub-menu a, .sf-menu > .infinite-mega-menu > .sf-mega a, .sf-vertical ul.sub-menu li a{ color: #bebebe; }
.sf-menu > li > .sub-menu a:hover, .sf-menu > li > .sub-menu .current-menu-item > a, .sf-menu > li > .sub-menu .current-menu-ancestor > a, .sf-menu > .infinite-mega-menu > .sf-mega a:hover, .sf-menu > .infinite-mega-menu > .sf-mega .current-menu-item > a, .sf-vertical > li > .sub-menu a:hover, .sf-vertical > li > .sub-menu .current-menu-item > a, .sf-vertical > li > .sub-menu .current-menu-ancestor > a{ color: #ffffff; }
.sf-menu > li > .sub-menu a:hover, .sf-menu > li > .sub-menu .current-menu-item > a, .sf-menu > li > .sub-menu .current-menu-ancestor > a, .sf-menu > .infinite-mega-menu > .sf-mega a:hover, .sf-menu > .infinite-mega-menu > .sf-mega .current-menu-item > a, .sf-vertical > li > .sub-menu a:hover, .sf-vertical > li > .sub-menu .current-menu-item > a, .sf-vertical > li > .sub-menu .current-menu-ancestor > a{ background-color: #1e1e1e; }
.infinite-navigation .sf-menu > .infinite-mega-menu .sf-mega-section-inner > a{ color: #ffffff; }
.infinite-navigation .sf-menu > .infinite-mega-menu .sf-mega-section{ border-color: #2d2d2d; }
.mm-navbar .mm-title, .mm-navbar .mm-btn, ul.mm-listview li > a, ul.mm-listview li > span{ color: #979797; }ul.mm-listview li a{ border-color: #979797; }.mm-arrow:after, .mm-next:after, .mm-prev:before{ border-color: #979797; }
.mm-navbar .mm-title:hover, .mm-navbar .mm-btn:hover, ul.mm-listview li a:hover, ul.mm-listview li > span:hover, ul.mm-listview li.current-menu-item > a, ul.mm-listview li.current-menu-ancestor > a, ul.mm-listview li.current-menu-ancestor > span{ color: #ffffff; }
.mm-menu{ background-color: #1f1f1f; }
ul.mm-listview li{ border-color: #626262; }
.infinite-overlay-menu-content{ background-color: #000000; background-color: rgba(0, 0, 0, 0.88); }
.infinite-overlay-menu-content ul.menu > li, .infinite-overlay-menu-content ul.sub-menu ul.sub-menu{ border-color: #424242; }
.infinite-overlay-menu-content ul li a, .infinite-overlay-menu-content .infinite-overlay-menu-close{ color: #263955; }
.infinite-overlay-menu-content ul li a:hover{ color: #a8a8a8; }
.infinite-bullet-anchor a:before{ background-color: #dfdfdf; }
.infinite-bullet-anchor a:hover, .infinite-bullet-anchor a.current-menu-item{ border-color: #f36e29; }.infinite-bullet-anchor a:hover:before, .infinite-bullet-anchor a.current-menu-item:before{ background: #f36e29; }
.infinite-main-menu-search i, .infinite-main-menu-cart i{ color: #ffffff; }
.infinite-main-menu-cart > .infinite-top-cart-count{ background-color: #bd584e; }
.infinite-main-menu-cart > .infinite-top-cart-count{ color: #ffffff; }
.infinite-body .infinite-main-menu-right-button{ color: #333333; }
.infinite-body .infinite-main-menu-right-button:hover{ color: #555555; }
.infinite-body .infinite-main-menu-right-button{ border-color: #333333; }
.infinite-body .infinite-main-menu-right-button:hover{ border-color: #555555; }
.infinite-main-menu-right-button.infinite-style-round-with-shadow{ box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11); -webkit-box-shadow: 0 4px 18px rgba(0, 0, 0, 0.11); }
.infinite-page-preload{ background-color: #ffffff; }
.infinite-body-outer-wrapper, body.infinite-full .infinite-body-wrapper, .infinite-header-side-content{ background-color: #ffffff; }
body.infinite-boxed .infinite-body-wrapper, .gdlr-core-page-builder .gdlr-core-page-builder-body.gdlr-core-pb-livemode{ background-color: #ffffff; }
.infinite-page-title-wrap .infinite-page-title{ color: #ffffff; }
.infinite-page-title-wrap .infinite-page-caption{ color: #ffffff; }.infinite-page-title-wrap .infinite-page-caption .woocommerce-breadcrumb, .infinite-page-title-wrap .infinite-page-caption .woocommerce-breadcrumb a{ color: #ffffff; }
.infinite-page-title-wrap .infinite-page-title-overlay{ background-color: #2d2d2d; }
.infinite-page-title-wrap .infinite-page-title-top-gradient{ background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: -o-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: -moz-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); }.infinite-page-title-wrap .infinite-page-title-bottom-gradient{ background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); }
.infinite-body, .infinite-body span.wpcf7-not-valid-tip{ color: #9b9b9b; }
.infinite-body h1, .infinite-body h2, .infinite-body h3, .infinite-body h4, .infinite-body h5, .infinite-body h6{ color: #383838; }.woocommerce table.shop_attributes th, .woocommerce table.shop_table th, .single-product.woocommerce div.product .product_meta .infinite-head{ color: #383838; }
.infinite-body a{ color: #143655; }.infinite-blog-style-3 .infinite-comments-area .comment-reply a, .infinite-blog-style-3 .infinite-comments-area .comment-reply a:hover{ color: #0e2b42; }
.infinite-body a:hover{ color: #6f75e8; }
.infinite-body *{ border-color: #e2e2e2; }.infinite-body .gdlr-core-portfolio-single-nav-wrap{ border-color: #e2e2e2; }.gdlr-core-columnize-item .gdlr-core-columnize-item-content{ column-rule-color: #e2e2e2; -moz-column-rule-color: #e2e2e2; -webkit-column-rule-color: #e2e2e2; }
.infinite-body input, .infinite-body textarea, .woocommerce form .select2-selection{ background-color: #f3f3f3; }
.infinite-body input, .infinite-body textarea, .woocommerce form .select2-selection{ border-color: #f3f3f3; }
.infinite-body input, .infinite-body textarea, .woocommerce form .select2-selection{ color: #4e4e4e; }
.infinite-body ::-webkit-input-placeholder{  color: #4e4e4e; }.infinite-body ::-moz-placeholder{  color: #4e4e4e; }.infinite-body :-ms-input-placeholder{  color: #4e4e4e; }.infinite-body :-moz-placeholder{  color: #4e4e4e; }
.infinite-not-found-wrap{ background-color: #23618e; }
.infinite-not-found-wrap .infinite-not-found-head{ color: #ffffff; }
.infinite-not-found-wrap .infinite-not-found-title{ color: #ffffff; }
.infinite-not-found-wrap .infinite-not-found-caption{ color: #c3e7ff; }
.infinite-not-found-wrap form.search-form input.search-field{ background-color: #000000; background-color: rgba(0, 0, 0, 0.4) }
.infinite-not-found-wrap form.search-form input.search-field, .infinite-not-found-wrap .infinite-top-search-submit{ color: #ffffff; } .infinite-not-found-wrap input::-webkit-input-placeholder { color: #ffffff; } .infinite-not-found-wrap input:-moz-placeholder{ color: #ffffff; } .infinite-not-found-wrap input::-moz-placeholder{ color: #ffffff; } .infinite-not-found-wrap input:-ms-input-placeholder{ color: #ffffff; }
.infinite-not-found-wrap .infinite-not-found-back-to-home a, .infinite-not-found-wrap .infinite-not-found-back-to-home a:hover{ color: #ffffff; }
.infinite-sidebar-area .infinite-widget-title{ color: #383838; }
.infinite-sidebar-area a{ color: #484848; }.widget_recent_entries ul li:before, .widget_recent_comments ul li:before, .widget_pages ul li:before, .widget_rss ul li:before, .widget_archive ul li:before, .widget_categories ul li:before, .widget_nav_menu ul li:before, .widget_meta ul li:before{ color: #484848; }
.infinite-sidebar-area a:hover, .infinite-sidebar-area .current-menu-item > a{ color: #323232; }
.gdlr-core-recent-post-widget .gdlr-core-blog-info i{ color: #9c9c9c; }
.gdlr-core-recent-post-widget .gdlr-core-blog-info, .gdlr-core-recent-post-widget .gdlr-core-blog-info a, .gdlr-core-recent-post-widget .gdlr-core-blog-info a:hover{ color: #a0a0a0; }
.gdlr-core-post-slider-widget-overlay .gdlr-core-post-slider-widget-title{ color: #ffffff; }
.gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info, .gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info i, .gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info a, .gdlr-core-post-slider-widget-overlay .gdlr-core-blog-info a:hover{ color: #9c9c9c; }
.widget_search input.search-field, .widget_archive select, .widget_categories select, .widget_text select{ color: #b5b5b5; }.widget_search input::-webkit-input-placeholder { color: #b5b5b5; }.widget_search input:-moz-placeholder{ color: #b5b5b5; }.widget_search input::-moz-placeholder{ color: #b5b5b5; }.widget_search input:-ms-input-placeholder{ color: #b5b5b5; }
.widget_search input.search-field, .widget_archive select, .widget_categories select, .widget_text select{ border-color: #e0e0e0; }
.widget_search form:after{ border-color: #c7c7c7; }
ul.gdlr-core-twitter-widget-wrap li:before{ color: #303030; }
ul.gdlr-core-twitter-widget-wrap li .gdlr-core-twitter-widget-date a, ul.gdlr-core-twitter-widget-wrap li .gdlr-core-twitter-widget-date a:hover{ color: #b5b5b5; }
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li a{ color: #6774a2; }
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li a:hover,ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li.current-menu-item a,ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li.current-menu-ancestor a{ color: #1c3375; }
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li a{ border-color: #233c85; }ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-list li a:before{ color: #233c85; }
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box li a{ color: #474747; }
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box li a:hover, ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box li.current-menu-item a, ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box li.current-menu-ancestor a{ background-color: #143369; }
ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box li a:hover, ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box li.current-menu-item a, ul.gdlr-core-custom-menu-widget.gdlr-core-menu-style-box li.current-menu-ancestor a{ color: #ffffff; }
.infinite-footer-wrapper{ background-color: #282828; }
.infinite-footer-wrapper .infinite-widget-title{ color: #ffffff; }.infinite-footer-wrapper h1, .infinite-footer-wrapper h3, .infinite-footer-wrapper h3, .infinite-footer-wrapper h4, .infinite-footer-wrapper h5, .infinite-footer-wrapper h6{ color: #ffffff; }
.infinite-footer-wrapper{ color: #a3a3a3; }.infinite-footer-wrapper .widget_recent_entries ul li:before, .infinite-footer-wrapper .widget_recent_comments ul li:before, .infinite-footer-wrapper .widget_pages ul li:before, .infinite-footer-wrapper .widget_rss ul li:before, .infinite-footer-wrapper .widget_archive ul li:before, .infinite-footer-wrapper .widget_categories ul li:before, .widget_nav_menu ul li:before, .infinite-footer-wrapper .widget_meta ul li:before{ color: #a3a3a3; }
.infinite-footer-wrapper a{ color: #ffffff; }
.infinite-footer-wrapper a:hover{ color: #ffffff; }
.infinite-footer-wrapper, .infinite-footer-wrapper *{ border-color: #494949; }
.infinite-copyright-wrapper{ background-color: #181818; }
.infinite-copyright-wrapper{ color: #9e9e9e; }
.infinite-copyright-wrapper a{ color: #ffffff; }
.infinite-copyright-wrapper a:hover{ color: #838383; }
.infinite-footer-back-to-top-button{ background-color: #dbdbdb; }
.infinite-body .infinite-footer-back-to-top-button, .infinite-body .infinite-footer-back-to-top-button:hover{ color: #313131; }
.infinite-body .infinite-blog-title-wrap .infinite-single-article-title{ color: #ffffff; }
.infinite-blog-title-wrap .infinite-blog-info-wrapper, .infinite-blog-title-wrap .infinite-blog-info-wrapper a, .infinite-blog-title-wrap .infinite-blog-info-wrapper a:hover, .infinite-blog-title-wrap .infinite-blog-info-wrapper i{ color: #ffffff; }
.infinite-blog-title-wrap .infinite-single-article-date-day{ color: #ffffff; }
.infinite-blog-title-wrap .infinite-single-article-date-month, .infinite-single-article-date-wrapper .infinite-single-article-date-year{ color: #b1b1b1; }
.infinite-blog-title-wrap .infinite-single-article-date-wrapper{ border-color: #ffffff; }
.infinite-blog-title-wrap .infinite-blog-title-overlay{ background-color: #000000; }
.infinite-blog-title-wrap.infinite-feature-image .infinite-blog-title-top-overlay{ background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: -o-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: -moz-linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); }.infinite-blog-title-wrap.infinite-feature-image .infinite-blog-title-bottom-overlay{ background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: -moz-linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)); }
.infinite-single-author .infinite-single-author-title a, .infinite-single-author .infinite-single-author-title a:hover{ color: #464646; }
.infinite-single-author .infinite-single-author-caption{ color: #b1b1b1; }
.infinite-single-author-tags a, .infinite-single-author-tags a:hover{ color: #343de9; border-color: #343de9; }
.infinite-single-nav a, .infinite-single-nav a:hover, .infinite-blog-style-3 .infinite-single-nav .infinite-text{ color: #bcbcbc; }
.infinite-blog-style-2 .infinite-single-nav-area-left .infinite-single-nav-area-overlay{ background-color: #343de9; }
.infinite-blog-style-2 .infinite-single-nav-area-right .infinite-single-nav-area-overlay{ background-color: #2a2dc1; }
.infinite-blog-style-2 .infinite-single-related-post-wrap{ background-color: #f5f5f5; }
.infinite-single-magazine-author-tags a, .infinite-single-magazine-author-tags a:hover{ color: #272727; }
.infinite-single-magazine-author-tags a{ background-color: #f2f2f2; }
.infinite-blog-magazine .infinite-single-author{ background-color: #f7f7f7; }
.infinite-comments-area .infinite-comments-title, .infinite-comments-area .comment-reply-title, .infinite-single-related-post-wrap .infinite-single-related-post-title{ color: #464646; }
.infinite-comments-area .comment-respond{ background-color: #f5f5f5; }
.infinite-comments-area .comment-reply a, .infinite-comments-area .comment-reply a:hover{ color: #bcbcbc; }
.infinite-comments-area .comment-time a, .infinite-comments-area .comment-time a:hover{ color: #b1b1b1; }
.gdlr-core-blog-title a, .infinite-body .infinite-single-article-title, .infinite-body .infinite-single-article-title a{ color: #343434; }
.gdlr-core-blog-title a:hover, .infinite-body .infinite-single-article-title a:hover{ color: #343434; }
.gdlr-core-sticky-banner, .infinite-sticky-banner{ color: #444444; }
.gdlr-core-sticky-banner, .infinite-sticky-banner{ background-color: #f3f3f3; }
.infinite-single-article .infinite-blog-info-wrapper, .infinite-single-article .infinite-blog-info-wrapper a, .infinite-single-article .infinite-blog-info-wrapper a:hover, .infinite-single-article .infinite-blog-info-wrapper i, .gdlr-core-blog-info-wrapper, .gdlr-core-blog-info-wrapper a, .gdlr-core-blog-info-wrapper a:hover, .gdlr-core-blog-info-wrapper i, .gdlr-core-blog-grid .gdlr-core-blog-info-date a{ color: #959595; }
.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-day, .infinite-single-article .infinite-single-article-date-day{ color: #353535; }
.gdlr-core-blog-date-wrapper .gdlr-core-blog-date-month, .infinite-single-article .infinite-single-article-date-month{ color: #8a8a8a; }
.gdlr-core-blog-grid.gdlr-core-blog-grid-with-frame, .gdlr-core-blog-full-frame, .gdlr-core-blog-list-frame, .gdlr-core-blog-link-format{ background-color: #ffffff; }
.gdlr-core-opacity-on-hover{ background: #081826; }
.gdlr-core-style-2 .gdlr-core-blog-thumbnail .gdlr-core-blog-info-category, .gdlr-core-style-3 .gdlr-core-blog-info-category{ background: #343de2; }.gdlr-core-blog-feature .gdlr-core-blog-info-category{ background: #343de2; }.gdlr-core-recent-post-widget-thumbnail .gdlr-core-blog-info-category{ background: #343de2; }
.gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper, .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper a, .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i, .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-title a{ color: #ffffff; } .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-content{ color: #ffffff; }.gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-info-wrapper, .gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-info-wrapper a, .gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-info-wrapper i, .gdlr-core-blog-metro.gdlr-core-with-image .gdlr-core-blog-title a{ color: #ffffff; }
.infinite-blog-aside-format .infinite-single-article-content, .gdlr-core-blog-aside-format{ background-color: #331fce; }.infinite-blog-quote-format .infinite-single-article-content, .gdlr-core-blog-quote-format{ background-color: #331fce; }
.infinite-blog-aside-format .infinite-single-article-content, .gdlr-core-blog-aside-format .gdlr-core-blog-content{ color: #ffffff; }.infinite-blog-quote-format .infinite-single-article-content blockquote, .infinite-blog-quote-format .infinite-single-article-content q, .infinite-blog-quote-format .infinite-single-article-content, .gdlr-core-blog-quote-format .gdlr-core-blog-content blockquote,.gdlr-core-blog-quote-format .gdlr-core-blog-content q,.gdlr-core-blog-quote-format .gdlr-core-blog-content{ color: #ffffff; }
.gdlr-core-pagination a{ background-color: #f0f0f0; }body .page-links > a, body .page-links > span.screen-reader-text, body .nav-links > a, body .nav-links > span.dots, body .page-links > span.page-links-title{ background-color: #f0f0f0; }
.gdlr-core-pagination a{ color: #929292; }body .page-links > a, body .page-links > span.screen-reader-text, body .nav-links > a, body .nav-links > span.dots, body .page-links > span.page-links-title{ color: #929292; }
.gdlr-core-pagination a:hover, .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-pagination span{ background-color: #353535; }body .page-links > span, body .page-links > a:hover, body .nav-links > span.current, body .nav-links > a:hover{ background-color: #353535; }
.gdlr-core-pagination a:hover, .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-pagination span{ color: #ffffff; }body .page-links > span, body .page-links > a:hover, body .nav-links > span.current, body .nav-links > a:hover{ color: #ffffff; }
.gdlr-core-pagination.gdlr-core-with-border a{ color: #b4b4b4; border-color: #b4b4b4; }.gdlr-core-pagination.gdlr-core-style-plain a, .gdlr-core-pagination.gdlr-core-style-plain a:before, .gdlr-core-pagination.gdlr-core-style-plain span:before{ color: #b4b4b4; }
.gdlr-core-pagination.gdlr-core-with-border a:hover, .gdlr-core-pagination.gdlr-core-with-border a.gdlr-core-active, .gdlr-core-pagination.gdlr-core-with-border span{ color: #424242; border-color: #424242; }.gdlr-core-pagination.gdlr-core-style-plain a:hover, .gdlr-core-pagination.gdlr-core-style-plain a.gdlr-core-active, .gdlr-core-pagination.gdlr-core-style-plain span{ color: #424242; }
.gdlr-core-portfolio-single-nav, .gdlr-core-portfolio-single-nav a, .gdlr-core-portfolio-single-nav a:hover{ color: #bcbcbc; }
.gdlr-core-portfolio-grid.gdlr-core-style-with-frame .gdlr-core-portfolio-grid-frame, .gdlr-core-portfolio-grid2{ background-color: #ffffff; }
.gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-title a{ color: #191919; }
.gdlr-core-port-info-item .gdlr-core-port-info-key, .gdlr-core-port-info2 .gdlr-core-port-info2-key{ color: #343434; }
.gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info, .gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info a, .gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info a:hover{ color: #b1b1b1; }
.gdlr-core-portfolio-grid2 .gdlr-core-portfolio-content-wrap .gdlr-core-portfolio-info{ background-color: #3d3ac2; }
.gdlr-core-portfolio-badge{ color: #ffffff; }
.gdlr-core-portfolio-badge{ background-color: #e24a3b; }
.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-icon, .gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-title a, .gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-title a:hover{ color: #ffffff; }
.gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info, .gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info a, .gdlr-core-portfolio-thumbnail .gdlr-core-portfolio-info a:hover{ color: #cecece; }
.gdlr-core-filterer-wrap.gdlr-core-style-text a{ color: #777777; }
.gdlr-core-filterer-wrap.gdlr-core-style-text a:hover, .gdlr-core-filterer-wrap.gdlr-core-style-text a.gdlr-core-active{ color: #2533c6; }.gdlr-core-filterer-wrap.gdlr-core-style-text .gdlr-core-filterer-slide-bar{ border-bottom-color: #2533c6 }
.gdlr-core-filterer-wrap.gdlr-core-style-button a{ color: #969696; }
.gdlr-core-filterer-wrap.gdlr-core-style-button a{ background-color: #f1f1f1; }
.gdlr-core-filterer-wrap.gdlr-core-style-button a:hover, .gdlr-core-filterer-wrap.gdlr-core-style-button a.gdlr-core-active{ color: #ffffff; }
.gdlr-core-filterer-wrap.gdlr-core-style-button a:hover, .gdlr-core-filterer-wrap.gdlr-core-style-button a.gdlr-core-active{ background-color: #331fce; }
.gdlr-core-price-table-item .gdlr-core-price-table{ background-color: #f8f8f8; }
.gdlr-core-price-table .gdlr-core-price-table-head{ background-color: #3e3e3e; background: -webkit-linear-gradient(#525252, #3e3e3e); background: -o-linear-gradient(#525252, #3e3e3e); background: -moz-linear-gradient(#525252, #3e3e3e); background: linear-gradient(#525252, #3e3e3e); }
.gdlr-core-price-table .gdlr-core-price-table-icon{ color: #ffffff; }
.gdlr-core-price-table .gdlr-core-price-table-title{ color: #ffffff; }
.gdlr-core-price-table .gdlr-core-price-table-caption{ color: #acacac; }
.gdlr-core-price-table .gdlr-core-price-table-price{ background-color: #ebebeb; }
.gdlr-core-price-table .gdlr-core-price-table-price-number, .gdlr-core-price-table .gdlr-core-price-prefix{ color: #323232; }
.gdlr-core-price-table .gdlr-core-price-suffix{ color: #acacac; }
body .gdlr-core-price-table .gdlr-core-price-table-button, body .gdlr-core-price-table .gdlr-core-price-table-button:hover{ color: #ffffff; }
body .gdlr-core-price-table .gdlr-core-price-table-button, body .gdlr-core-price-table .gdlr-core-price-table-button:hover{ background-color: #575757; background: -webkit-linear-gradient(#414141, #575757); background: -o-linear-gradient(#414141, #575757); background: -moz-linear-gradient(#414141, #575757); background: linear-gradient(#414141, #575757); }
.gdlr-core-price-table .gdlr-core-price-table-content *{ border-color: #e5e5e5; }
.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-head{ background-color: #331fce; background: -webkit-linear-gradient(#6658ce, #331fce); background: -o-linear-gradient(#6658ce, #331fce); background: -moz-linear-gradient(#6658ce, #331fce); background: linear-gradient(#6658ce, #331fce); }
.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-icon{ color: #ffffff; }
.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-title{ color: #ffffff; }
.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-caption{ color: #a6a3ce; }
.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-price{ background-color: #ffffff; }
.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-price-number, .gdlr-core-price-table .gdlr-core-price-prefix{ color: #323232; }
.gdlr-core-price-table.gdlr-core-active .gdlr-core-price-suffix{ color: #acacac; }
body .gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-button, body .gdlr-core-price-table .gdlr-core-price-table-button:hover{ color: #ffffff; }
body .gdlr-core-price-table.gdlr-core-active .gdlr-core-price-table-button, body .gdlr-core-price-table .gdlr-core-price-table-button:hover{ background-color: #331fce; background: -webkit-linear-gradient(#6a5cce, #331fce); background: -o-linear-gradient(#6a5cce, #331fce); background: -moz-linear-gradient(#6a5cce, #331fce); background: linear-gradient(#6a5cce, #331fce); }
.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-icon, .gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon{ color: #191919; }
.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-title .gdlr-core-head, .gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-title .gdlr-core-head, .gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-title .gdlr-core-head, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-title .gdlr-core-head{ color: #191919; }
.gdlr-core-accordion-style-icon .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-icon .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-title{ color: #191919; }
.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon{ background-color: #f3f3f3; }.gdlr-core-accordion-style-box-icon .gdlr-core-accordion-item-icon, .gdlr-core-toggle-box-style-box-icon .gdlr-core-toggle-box-item-icon{ border-color: #f3f3f3; }
.gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title:before, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-accordion-item-title:before{ color: #191919; }
.gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-title .gdlr-core-head, .gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title .gdlr-core-head, .gdlr-core-toggle-box-style-background-title .gdlr-core-toggle-box-item-title .gdlr-core-head, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-toggle-box-item-title .gdlr-core-head{ color: #191919; }
.gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-toggle-box-item-title{ color: #191919; }
.gdlr-core-accordion-style-background-title .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-toggle-box-item-title{ background-color: #f3f3f3; }
.gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title:before, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title:before{ color: #ffffff; }
.gdlr-core-accordion-style-background-title .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-active .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-toggle-box-item-title{ color: #ffffff; }
.gdlr-core-accordion-style-background-title .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-accordion-style-background-title-icon .gdlr-core-active .gdlr-core-accordion-item-title, .gdlr-core-toggle-box-style-background-title .gdlr-core-active .gdlr-core-toggle-box-item-title, .gdlr-core-toggle-box-style-background-title-icon .gdlr-core-active .gdlr-core-toggle-box-item-title{ background-color: #303030; }
.gdlr-core-audio, .gdlr-core-audio .mejs-container .mejs-controls{ background-color: #e7e7e7; }
.gdlr-core-audio .mejs-container .mejs-controls .mejs-volume-button:before, .gdlr-core-audio .mejs-container .mejs-controls .mejs-playpause-button:before, .gdlr-core-audio .mejs-container .mejs-controls .mejs-time{ color: #202020; }
.gdlr-core-audio .mejs-controls .mejs-time-rail .mejs-time-total, .gdlr-core-audio .mejs-controls .mejs-time-rail .mejs-time-loaded{ background-color: #afafaf; }
.gdlr-core-audio .mejs-controls .mejs-time-rail .mejs-time-current{ background-color: #331fce; }
.gdlr-core-audio .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total{ background-color: #afafaf; }
.gdlr-core-audio .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current{ background-color: #646464; }
.gdlr-core-alert-box-item .gdlr-core-alert-box-item-inner{ background-color: #ef5e68; }
.gdlr-core-alert-box-item .gdlr-core-alert-box-item-inner{ border-color: #cd515a; }
.gdlr-core-alert-box-item .gdlr-core-alert-box-item-inner{ color: #ffffff; }
.gdlr-core-alert-box-item .gdlr-core-alert-box-item-title{ color: #ffffff; }
blockquote, q{ color: #343de9; }
blockquote, q, pre{ background-color: #ffffff; }
.infinite-body blockquote, .infinite-body q{ border-color: #343de9; }
.gdlr-core-blockquote-item-quote{ color: #4e4e4e; }
.gdlr-core-blockquote-item-content, .gdlr-core-blockquote-item-author, pre{ color: #4e4e4e; }
.gdlr-core-body .gdlr-core-load-more, .gdlr-core-body .gdlr-core-button, .infinite-body .infinite-button, .infinite-body input[type="button"], .infinite-body input[type="submit"]{ color: #ffffff; }
.gdlr-core-body .gdlr-core-load-more:hover, .gdlr-core-body .gdlr-core-button:hover{ color: #ffffff; }
.gdlr-core-body .gdlr-core-load-more, .gdlr-core-body .gdlr-core-button, .infinite-body .infinite-button, .infinite-body input[type="button"], .infinite-body input[type="submit"]{ background-color: #143655; }.infinite-blog-style-4 .infinite-comments-area .form-submit input[type="submit"]{ box-shadow: 5px 5px 20px rgba(52, 51, 184, 0.4); -webkit-box-shadow: 5px 5px 20px rgba(52, 51, 184, 0.4); -moz-box-shadow: 5px 5px 20px rgba(52, 51, 184, 0.4); }
.gdlr-core-body .gdlr-core-load-more:hover, .gdlr-core-body .gdlr-core-button:hover{ background-color: #2f6698; }
.gdlr-core-body .gdlr-core-button-with-border{ border-color: #383838; } .gdlr-core-body .gdlr-core-button-with-border.gdlr-core-button-transparent{ color: #383838; }
.gdlr-core-body .gdlr-core-button-with-border:hover{ border-color: #000000; }.gdlr-core-body .gdlr-core-button-with-border.gdlr-core-button-transparent:hover{ color: #000000; }
.gdlr-core-body .gdlr-core-button.gdlr-core-button-gradient{ background-color: #3433b8; background: -webkit-linear-gradient(#6161b7, #3433b8); background: -o-linear-gradient(#6161b7, #3433b8); background: -moz-linear-gradient(#6161b7, #3433b8); background: linear-gradient(#6161b7, #3433b8); }.gdlr-core-body .gdlr-core-button.gdlr-core-button-gradient-v{ background-color: #3433b8; background: -webkit-linear-gradient(to right, #6161b7, #3433b8); background: -o-linear-gradient(to right, #6161b7, #3433b8); background: -moz-linear-gradient(to right, #6161b7, #3433b8); background: linear-gradient(to right, #6161b7, #3433b8); }
.gdlr-core-call-to-action-item-title{ color: #2c2c2c; }
.gdlr-core-call-to-action-item-caption{ color: #535353; }
.gdlr-core-counter-item-top-text{ color: #828282; }
.gdlr-core-counter-item-top-icon{ color: #393939; }
.gdlr-core-counter-item-number{ color: #393939; }
.gdlr-core-counter-item-divider{ border-color: #393939; }
.gdlr-core-counter-item-bottom-text{ color: #393939; }
.gdlr-core-column-service-item .gdlr-core-column-service-icon{ color: #9d9d9d; }
.gdlr-core-column-service-item .gdlr-core-icon-style-round i{ background-color: #f3f3f3; }
.gdlr-core-column-service-item .gdlr-core-column-service-title{ color: #383838; }
.gdlr-core-column-service-item .gdlr-core-column-service-caption{ color: #747474; }
.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-title, .gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-head-wrap{ background-color: #309cea; }
.gdlr-core-dropdown-tab .gdlr-core-dropdown-tab-title{ color: #ffffff; }
.gdlr-core-flipbox-item .gdlr-core-flipbox-front, .gdlr-core-flipbox-item .gdlr-core-flipbox-back, .gdlr-core-feature-box-item .gdlr-core-feature-box{ background-color: #2d9bea; }
.gdlr-core-flipbox-item .gdlr-core-flipbox-front, .gdlr-core-flipbox-item .gdlr-core-flipbox-back, .gdlr-core-flipbox-item .gdlr-core-flipbox-frame, .gdlr-core-feature-box-item .gdlr-core-feature-box, .gdlr-core-feature-box-item .gdlr-core-feature-box-frame{ border-color: #2a80be; }
.gdlr-core-flipbox-item .gdlr-core-flipbox-item-icon, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-icon{ color: #ffffff; }
.gdlr-core-flipbox-item .gdlr-core-flipbox-item-title, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-title{ color: #ffffff; }
.gdlr-core-flipbox-item .gdlr-core-flipbox-item-caption, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-caption{ color: #ffffff; }
.gdlr-core-flipbox-item .gdlr-core-flipbox-item-content, .gdlr-core-feature-box-item .gdlr-core-feature-box-item-content{ color: #ffffff; }
.gdlr-core-image-overlay.gdlr-core-gallery-image-overlay .gdlr-core-image-overlay-title{ color: #ffffff; }
.gdlr-core-image-overlay.gdlr-core-gallery-image-overlay .gdlr-core-image-overlay-caption{ color: #cecece; }
.gdlr-core-image-overlay{ background-color: #000000; background-color: rgba(0, 0, 0, 0.6); }
.gdlr-core-image-overlay-content, .gdlr-core-image-overlay-content a, .gdlr-core-image-overlay-icon{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin] .gdlr-core-image-overlay-icon, .gdlr-core-page-builder-body .gdlr-core-pbf-column[data-skin] .gdlr-core-image-overlay-icon{ color: #ffffff; }
.gdlr-core-image-overlay.gdlr-core-round-icon .gdlr-core-image-overlay-icon{ background-color: #ffffff; }
.gdlr-core-body .gdlr-core-image-item-wrap{ border-color: #000000; }
.gdlr-core-block-item-title-wrap .gdlr-core-block-item-title{ color: #343434; }
.gdlr-core-block-item-title-wrap .gdlr-core-block-item-caption{ color: #747474; }
.gdlr-core-block-item-title-wrap a, .gdlr-core-block-item-title-wrap a:hover{ color: #747474; }
.gdlr-core-icon-list-item i{ color: #222222; }
.gdlr-core-icon-list-with-background-round .gdlr-core-icon-list-icon-wrap, .gdlr-core-icon-list-with-background-circle .gdlr-core-icon-list-icon-wrap{ color: #f3f3f3; }
.gdlr-core-opening-hour-item .gdlr-core-opening-hour-day{ color: #a5a5a5; }
.gdlr-core-opening-hour-item .gdlr-core-opening-hour-open{ color: #3433b8; }
.gdlr-core-opening-hour-item .gdlr-core-opening-hour-close{ color: #c8c8c8; }
.gdlr-core-opening-hour-item .gdlr-core-opening-hour-time i{ color: #8a8989; }
.gdlr-core-opening-hour-item .gdlr-core-opening-hour-list-item{ border-color: #a6a6a6; }
.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-title, .gdlr-core-personnel-style-grid .gdlr-core-personnel-list-title a{ color: #383838; }
.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-position{ color: #888888; }
.gdlr-core-personnel-style-grid .gdlr-core-personnel-list-divider{ color: #cecece; }
.gdlr-core-personnel-style-grid.gdlr-core-with-background .gdlr-core-personnel-list-content-wrap{ background-color: #f9f9f9; }
.gdlr-core-personnel-style-modern .gdlr-core-personnel-list-title, .gdlr-core-personnel-style-modern .gdlr-core-personnel-list-title a{ color: #ffffff; }
.gdlr-core-personnel-style-modern .gdlr-core-personnel-list-position{ color: #ffffff; }.gdlr-core-personnel-style-modern .gdlr-core-social-network-item a{ color: #ffffff; }
.gdlr-core-promo-box-item .gdlr-core-promo-box-item-title{ color: #383838; }
.gdlr-core-promo-box-item .gdlr-core-promo-box-content-wrap{ border-color: #e8e7e7; }
.gdlr-core-post-slider-item .gdlr-core-post-slider-title a{ color: #ffffff; }
.gdlr-core-post-slider-item .gdlr-core-blog-info, .gdlr-core-post-slider-item .gdlr-core-blog-info a{ color: #c5c5c5; }
.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-title{ color: #a6aafb; }
.gdlr-core-roadmap-item-head.gdlr-core-active .gdlr-core-roadmap-item-head-title{ color: #ffffff; }
.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-caption{ color: #c1caf6; }
.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-count{ color: #c5c5c5; }
.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-count{ background-color: #223077; }
.gdlr-core-roadmap-item-head.gdlr-core-active .gdlr-core-roadmap-item-head-count{ color: #223077; }
.gdlr-core-roadmap-item-head.gdlr-core-active .gdlr-core-roadmap-item-head-count{ color: #ffffff; }
.gdlr-core-roadmap-item .gdlr-core-roadmap-item-head-divider{ border-color: #ffffff; }
.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content-title{ color: #ffffff; }
.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content-caption{ color: #c1caf6; }
.gdlr-core-roadmap-item .gdlr-core-roadmap-item-content{ color: #babdff; }
.gdlr-core-skill-bar-item .gdlr-core-skill-bar-title, .gdlr-core-skill-bar-item .gdlr-core-skill-bar-right{ color: #505050; }
.gdlr-core-skill-bar-item .gdlr-core-skill-bar-icon{ color: #616161; }
.gdlr-core-skill-bar-item .gdlr-core-skill-bar-progress{ background-color: #f3f3f3; }
.gdlr-core-skill-bar-item .gdlr-core-skill-bar-filled{ background-color: #3433b8; }
.gdlr-core-flexslider-nav .flex-direction-nav li a, .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a{ color: #ffffff; border-color: #ffffff; }.gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a{ color: #ffffff; border-color: #ffffff; }
.gdlr-core-flexslider-nav.gdlr-core-round-style li a, .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a{ background-color: #143655; }.gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a{ background-color: #143655; }
.gdlr-core-flexslider .flex-control-nav li a{ border-color: #143655; }.gdlr-core-flexslider .flex-control-nav li a.flex-active{ background-color: #143655; }.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder .flex-control-nav li a.flex-active,.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder-left .flex-control-nav li a.flex-active{ background-color: #143655; }
.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder .flex-control-nav li a,.gdlr-core-flexslider.gdlr-core-bullet-style-cylinder-left .flex-control-nav li a{ background-color: #d8d8d8; }
.gdlr-core-social-share-item a{ color: #202020; }.gdlr-core-social-share-item.gdlr-core-style-round a, .gdlr-core-social-share-item.gdlr-core-style-round a:hover{ background-color: #202020; }
.gdlr-core-social-share-item .gdlr-core-divider{ border-color: #e5e5e5; }
.gdlr-core-social-share-item .gdlr-core-social-share-count{ color: #202020; }
.gdlr-core-stunning-text-item-caption{ color: #747474; }
.gdlr-core-stunning-text-item-title{ color: #383838; }
.gdlr-core-tab-item-title{ color: #8d8d8d; }
.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title, .gdlr-core-tab-style1-vertical .gdlr-core-tab-item-title{ background-color: #f7f7f7; }
.gdlr-core-tab-item-title-wrap, .gdlr-core-tab-item-content-wrap, .gdlr-core-tab-item-title{ border-color: #ebebeb; }
.gdlr-core-tab-item-title-line{ border-color: #3433b8; }
.gdlr-core-tab-item-title.gdlr-core-active{ color: #464646; }
.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title.gdlr-core-active, .gdlr-core-tab-style1-vertical .gdlr-core-tab-item-title.gdlr-core-active{ background-color: #ffffff; }
table tr th{ background-color: #6a6ab7; }
table tr th, .infinite-body table tr th a, .infinite-body table tr th a:hover{ color: #ffffff; }
table tr:nth-child(odd){ background-color: #f9f9f9; }
table tr:nth-child(odd){ color: #949494; }
table tr:nth-child(even){ background-color: #f3f3f3; }
table tr:nth-child(even){ color: #949494; }
.gdlr-core-testimonial-item .gdlr-core-testimonial-item-title{ color: #232323; }
.gdlr-core-testimonial-item .gdlr-core-testimonial-content{ color: #707070; }
.gdlr-core-testimonial-item .gdlr-core-testimonial-title{ color: #3b3b3b; }
.gdlr-core-testimonial-item .gdlr-core-testimonial-position .gdlr-core-rating i{ color: #ffa127; }
.gdlr-core-testimonial-item .gdlr-core-testimonial-position{ color: #3b3b3b; }
.gdlr-core-testimonial-item .gdlr-core-testimonial-quote{ color: #282828; }
.gdlr-core-title-item .gdlr-core-title-item-title, .gdlr-core-title-item .gdlr-core-title-item-title a{ color: #383838; }
.gdlr-core-title-item .gdlr-core-title-item-caption{ color: #747474; }
.woocommerce .star-rating span, .single-product.woocommerce #review_form #respond p.stars a, .single-product.woocommerce div.product .product_meta, .single-product.woocommerce div.product .product_meta a{ color: #bd584e; }.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt, .woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, html .woocommerce input.button, html .woocommerce span.onsale{ background-color: #bd584e; }
span.woocommerce-Price-amount.amount{ color: #bd584e; }.infinite-top-cart-content-wrap .infinite-highlight, .infinite-top-cart-item-wrap .infinite-top-cart-price-wrap .woocommerce-Price-amount.amount{ color: #bd584e; }
.woocommerce .price del, .gdlr-core-product-price del, del span.woocommerce-Price-amount.amount{ color: #949494; }
.woocommerce #respond input#submit.alt:hover, .woocommerce a.button.alt:hover, .woocommerce button.button.alt:hover, .woocommerce input.button.alt:hover, .woocommerce #respond input#submit:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover{ background-color: #d4786e; }
.woocommerce #respond input#submit.alt, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt, .woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button, .woocommerce #respond input#submit.disabled, .woocommerce #respond input#submit:disabled, .woocommerce #respond input#submit:disabled[disabled], .woocommerce a.button.disabled, .woocommerce a.button:disabled, .woocommerce a.button:disabled[disabled], .woocommerce button.button.disabled, .woocommerce button.button:disabled, .woocommerce button.button:disabled[disabled], .woocommerce input.button.disabled, .woocommerce input.button:disabled, .woocommerce input.button:disabled[disabled]{ color: #ffffff; }
.woocommerce #respond input#submit.alt:hover, .woocommerce a.button.alt:hover, .woocommerce button.button.alt:hover, .woocommerce input.button.alt:hover, .woocommerce #respond input#submit:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover{ color: #ffffff; }
.single-product.woocommerce div.product .quantity .qty, #add_payment_method #payment, .woocommerce-checkout #payment, .single-product.woocommerce #reviews #comments ol.commentlist li{ background-color: #f3f3f3; }
.gdlr-core-product-grid .gdlr-core-product-title a{ color: #191919; }
.gdlr-core-product-grid .gdlr-core-product-title a:hover{ color: #434343; }
.gdlr-core-product-thumbnail .gdlr-core-product-view-detail, .gdlr-core-product-thumbnail .gdlr-core-product-view-detail:hover{ color: #ffffff; }
.gdlr-core-product-thumbnail .gdlr-core-product-view-detail{ background-color: #000000; background-color: rgba(0, 0, 0, 0.9); }
.gdlr-core-product-thumbnail .added_to_cart, .gdlr-core-product-thumbnail .added_to_cart:hover, .gdlr-core-product-thumbnail .gdlr-core-product-add-to-cart, .gdlr-core-product-thumbnail .gdlr-core-product-add-to-cart:hover{ color: #ffffff; }
.gdlr-core-product-thumbnail .added_to_cart, .gdlr-core-product-thumbnail .gdlr-core-product-add-to-cart{ background-color: #bd584e; background-color: rgba(189, 88, 78, 0.9); }
.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content{ background-color: #e6e6e6; }
.woocommerce .widget_price_filter .ui-slider .ui-slider-range{ background-color: #824141; }
.woocommerce .widget_price_filter .ui-slider .ui-slider-handle{ background-color: #b3564e; }
.gdlr-core-page-builder-body [data-skin="Dark"] h1, .gdlr-core-page-builder-body [data-skin="Dark"] h2, .gdlr-core-page-builder-body [data-skin="Dark"] h3, .gdlr-core-page-builder-body [data-skin="Dark"] h4, .gdlr-core-page-builder-body [data-skin="Dark"] h5, .gdlr-core-page-builder-body [data-skin="Dark"] h6, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-title a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-title a:hover{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-caption a:hover{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark"], .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-content{ color: #cecece }
.gdlr-core-page-builder-body [data-skin="Dark"] i, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-icon, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-icon:before, .gdlr-core-page-builder-body [data-skin="Dark"] .infinite-widget ul li:before{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a{ border-color: #ffffff; }
.gdlr-core-page-builder-body [data-skin="Dark"] a, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-link{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark"] a:hover, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-link:hover{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-divider{ border-color: #777777; column-rule-color: #777777; -moz-column-rule-color: #777777; -webkit-column-rule-color: #777777; }.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a{ border-color: #777777 }
.gdlr-core-page-builder-body [data-skin="Dark"] *, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-border{ border-color: #777777 }.gdlr-core-page-builder-body [data-skin="Dark"] input:not([type="button"]):not([type="submit"]):not([type="reset"]){ border-color: #777777; }
.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-skin-e-content{ color: #3d3d3d }.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a i, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a i{ color: #3d3d3d; }.gdlr-core-page-builder-body [data-skin="Dark"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="Dark"] textarea{ color: #3d3d3d; }.gdlr-core-page-builder-body [data-skin="Dark"] ::-webkit-input-placeholder{ color: #3d3d3d; }.gdlr-core-page-builder-body [data-skin="Dark"] ::-moz-placeholder{ color: #3d3d3d; }.gdlr-core-page-builder-body [data-skin="Dark"] :-ms-input-placeholder{ color: #3d3d3d; }.gdlr-core-page-builder-body [data-skin="Dark"] :-moz-placeholder{ color: #3d3d3d; }.gdlr-core-page-builder-body [data-skin="Dark"]  .gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a{ color: #3d3d3d; }
.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button-color{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Dark"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Dark"] input[type="submit"]{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-pagination a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button-color.gdlr-core-active{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Dark"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Dark"] input[type="submit"]:hover{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-pagination span{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button-color{ background-color: #f26c29 }.gdlr-core-page-builder-body [data-skin="Dark"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Dark"] input[type="submit"]{ background-color: #f26c29; }.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-pagination a{ background-color: #f26c29 }
.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button-color.gdlr-core-active{ background-color: #ff9c2b }.gdlr-core-page-builder-body [data-skin="Dark"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Dark"] input[type="submit"]:hover{ background-color: #ff9c2b; }.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-pagination span{ background-color: #ff9c2b }
.gdlr-core-page-builder-body [data-skin="White Text"] h1, .gdlr-core-page-builder-body [data-skin="White Text"] h2, .gdlr-core-page-builder-body [data-skin="White Text"] h3, .gdlr-core-page-builder-body [data-skin="White Text"] h4, .gdlr-core-page-builder-body [data-skin="White Text"] h5, .gdlr-core-page-builder-body [data-skin="White Text"] h6, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-title a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-caption a:hover{ color: #e5e5e5 }
.gdlr-core-page-builder-body [data-skin="White Text"], .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-content{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="White Text"] i, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-icon, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-icon:before, .gdlr-core-page-builder-body [data-skin="White Text"] .infinite-widget ul li:before{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a{ border-color: #ffffff; }
.gdlr-core-page-builder-body [data-skin="White Text"] a, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-link{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="White Text"] a:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-link:hover{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-divider{ border-color: #ffffff; column-rule-color: #ffffff; -moz-column-rule-color: #ffffff; -webkit-column-rule-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a{ border-color: #ffffff }
.gdlr-core-page-builder-body [data-skin="White Text"] *, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-border{ border-color: #ffffff }.gdlr-core-page-builder-body [data-skin="White Text"] input:not([type="button"]):not([type="submit"]):not([type="reset"]){ border-color: #ffffff; }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-e-background{ background-color: #ffffff }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider .flex-control-nav li a{ border-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider .flex-control-nav li a.flex-active{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="White Text"] textarea{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a{ background-color: #ffffff; }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-skin-e-content{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a i, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a i{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="White Text"] textarea{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] ::-webkit-input-placeholder{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] ::-moz-placeholder{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] :-ms-input-placeholder{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] :-moz-placeholder{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"]  .gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a{ color: #ffffff; }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="White Text"] input[type="button"], .gdlr-core-page-builder-body [data-skin="White Text"] input[type="submit"]{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color.gdlr-core-active{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="White Text"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="White Text"] input[type="submit"]:hover{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination span{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color{ background-color: #000000 }.gdlr-core-page-builder-body [data-skin="White Text"] input[type="button"], .gdlr-core-page-builder-body [data-skin="White Text"] input[type="submit"]{ background-color: #000000; }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a{ background-color: #000000 }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color.gdlr-core-active{ background-color: #ff9c2b }.gdlr-core-page-builder-body [data-skin="White Text"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="White Text"] input[type="submit"]:hover{ background-color: #ff9c2b; }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination span{ background-color: #ff9c2b }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color{ border-color: #ffffff }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a{ border-color: #ffffff }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button-color.gdlr-core-active{ border-color: #ffffff }.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-pagination span{ border-color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Grey"] h1, .gdlr-core-page-builder-body [data-skin="Grey"] h2, .gdlr-core-page-builder-body [data-skin="Grey"] h3, .gdlr-core-page-builder-body [data-skin="Grey"] h4, .gdlr-core-page-builder-body [data-skin="Grey"] h5, .gdlr-core-page-builder-body [data-skin="Grey"] h6, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-title a{ color: #3a3a3a }
.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-caption a:hover{ color: #727272 }
.gdlr-core-page-builder-body [data-skin="Grey"], .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-content{ color: #494949 }
.gdlr-core-page-builder-body [data-skin="Grey"] i, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-icon, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-icon:before, .gdlr-core-page-builder-body [data-skin="Grey"] .infinite-widget ul li:before{ color: #595959 }.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i{ color: #595959; }.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a{ border-color: #595959; }
.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-divider{ border-color: #cecece; column-rule-color: #cecece; -moz-column-rule-color: #cecece; -webkit-column-rule-color: #cecece; }.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a{ border-color: #cecece }
.gdlr-core-page-builder-body [data-skin="Grey"] *, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-border{ border-color: #cecece }.gdlr-core-page-builder-body [data-skin="Grey"] input:not([type="button"]):not([type="submit"]):not([type="reset"]){ border-color: #cecece; }
.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-skin-e-background{ background-color: #ffffff }.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a, .gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-flexslider .flex-control-nav li a{ border-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-flexslider .flex-control-nav li a.flex-active{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Grey"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="Grey"] textarea{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Grey"] .gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a{ background-color: #ffffff; }
.gdlr-core-page-builder-body [data-skin="Blue"] h1, .gdlr-core-page-builder-body [data-skin="Blue"] h2, .gdlr-core-page-builder-body [data-skin="Blue"] h3, .gdlr-core-page-builder-body [data-skin="Blue"] h4, .gdlr-core-page-builder-body [data-skin="Blue"] h5, .gdlr-core-page-builder-body [data-skin="Blue"] h6, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-skin-title a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-skin-caption a:hover{ color: #d9eef7 }
.gdlr-core-page-builder-body [data-skin="Blue"], .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-skin-content{ color: #d9eef7 }
.gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button-color{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Blue"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Blue"] input[type="submit"]{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-pagination a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button-color.gdlr-core-active{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Blue"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Blue"] input[type="submit"]:hover{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-pagination span{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button-color{ background-color: #232323 }.gdlr-core-page-builder-body [data-skin="Blue"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Blue"] input[type="submit"]{ background-color: #232323; }.gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-pagination a{ background-color: #232323 }
.gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button-color.gdlr-core-active{ background-color: #232323 }.gdlr-core-page-builder-body [data-skin="Blue"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Blue"] input[type="submit"]:hover{ background-color: #232323; }.gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-pagination span{ background-color: #232323 }
.gdlr-core-page-builder-body [data-skin="Blue Divider"] .gdlr-core-skin-divider{ border-color: #51b0f3; column-rule-color: #51b0f3; -moz-column-rule-color: #51b0f3; -webkit-column-rule-color: #51b0f3; }.gdlr-core-page-builder-body [data-skin="Blue Divider"] .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a{ border-color: #51b0f3 }
.gdlr-core-page-builder-body [data-skin="Dark Personnel"] h1, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] h2, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] h3, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] h4, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] h5, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] h6, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-title a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-title a:hover{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-caption a:hover{ color: #dddddd }
.gdlr-core-page-builder-body [data-skin="Dark Personnel"], .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-content{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark Personnel"] i, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-icon, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-icon:before, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .infinite-widget ul li:before{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-blog-modern.gdlr-core-with-image .gdlr-core-blog-info-wrapper i{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-flexslider-nav.gdlr-core-plain-circle-style li a{ border-color: #ffffff; }
.gdlr-core-page-builder-body [data-skin="Dark Personnel"] a, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-link{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark Personnel"] a:hover, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-link:hover{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-divider{ border-color: #b2b2b2; column-rule-color: #b2b2b2; -moz-column-rule-color: #b2b2b2; -webkit-column-rule-color: #b2b2b2; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a{ border-color: #b2b2b2 }
.gdlr-core-page-builder-body [data-skin="Dark Personnel"] *, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-border{ border-color: #bcbcbc }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] input:not([type="button"]):not([type="submit"]):not([type="reset"]){ border-color: #bcbcbc; }
.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-e-background{ background-color: #191919 }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a{ background-color: #191919; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-flexslider .flex-control-nav li a{ border-color: #191919; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-flexslider .flex-control-nav li a.flex-active{ background-color: #191919; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="Dark Personnel"] textarea{ background-color: #191919; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a{ background-color: #191919; }
.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-skin-e-content{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a i, .gdlr-core-page-builder-body [data-skin="Dark Personnel"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a i{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="Dark Personnel"] textarea{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] ::-webkit-input-placeholder{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] ::-moz-placeholder{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] :-ms-input-placeholder{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"] :-moz-placeholder{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Dark Personnel"]  .gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a{ color: #ffffff; }
.gdlr-core-page-builder-body [data-skin="Blue Title"] h1, .gdlr-core-page-builder-body [data-skin="Blue Title"] h2, .gdlr-core-page-builder-body [data-skin="Blue Title"] h3, .gdlr-core-page-builder-body [data-skin="Blue Title"] h4, .gdlr-core-page-builder-body [data-skin="Blue Title"] h5, .gdlr-core-page-builder-body [data-skin="Blue Title"] h6, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-skin-title a{ color: #2d95e1 }
.gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-skin-caption a:hover{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Blue Title"], .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-skin-content{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-button-color{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Blue Title"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Blue Title"] input[type="submit"]{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-pagination a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-button-color.gdlr-core-active{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Blue Title"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Blue Title"] input[type="submit"]:hover{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-pagination span{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-button-color{ border-color: #ffffff }.gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-pagination a{ border-color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-button-color.gdlr-core-active{ border-color: #ffffff }.gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="Blue Title"] .gdlr-core-pagination span{ border-color: #ffffff }
.gdlr-core-page-builder-body [data-skin="About Me Top"] h1, .gdlr-core-page-builder-body [data-skin="About Me Top"] h2, .gdlr-core-page-builder-body [data-skin="About Me Top"] h3, .gdlr-core-page-builder-body [data-skin="About Me Top"] h4, .gdlr-core-page-builder-body [data-skin="About Me Top"] h5, .gdlr-core-page-builder-body [data-skin="About Me Top"] h6, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-skin-title a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-skin-caption a:hover{ color: #acc3ff }
.gdlr-core-page-builder-body [data-skin="About Me Top"], .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-skin-content{ color: #95a3bc }
.gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-skin-divider{ border-color: #ffffff; column-rule-color: #ffffff; -moz-column-rule-color: #ffffff; -webkit-column-rule-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a{ border-color: #ffffff }
.gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-button-color{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="About Me Top"] input[type="button"], .gdlr-core-page-builder-body [data-skin="About Me Top"] input[type="submit"]{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-pagination a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-button-color.gdlr-core-active{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="About Me Top"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="About Me Top"] input[type="submit"]:hover{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-pagination span{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-button-color{ border-color: #ffffff }.gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-pagination a{ border-color: #ffffff }
.gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-button-color.gdlr-core-active{ border-color: #ffffff }.gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="About Me Top"] .gdlr-core-pagination span{ border-color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Maintenance"] h1, .gdlr-core-page-builder-body [data-skin="Maintenance"] h2, .gdlr-core-page-builder-body [data-skin="Maintenance"] h3, .gdlr-core-page-builder-body [data-skin="Maintenance"] h4, .gdlr-core-page-builder-body [data-skin="Maintenance"] h5, .gdlr-core-page-builder-body [data-skin="Maintenance"] h6, .gdlr-core-page-builder-body [data-skin="Maintenance"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Maintenance"] .gdlr-core-skin-title a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Maintenance"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Maintenance"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Maintenance"] .gdlr-core-skin-caption a:hover{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Maintenance"], .gdlr-core-page-builder-body [data-skin="Maintenance"] .gdlr-core-skin-content{ color: #d9eef7 }
.gdlr-core-page-builder-body [data-skin="Maintenance"] .gdlr-core-skin-divider{ border-color: #74abd2; column-rule-color: #74abd2; -moz-column-rule-color: #74abd2; -webkit-column-rule-color: #74abd2; }.gdlr-core-page-builder-body [data-skin="Maintenance"] .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a{ border-color: #74abd2 }
.gdlr-core-page-builder-body [data-skin="Maintenance"] *, .gdlr-core-page-builder-body [data-skin="Maintenance"] .gdlr-core-skin-border{ border-color: #74abd2 }.gdlr-core-page-builder-body [data-skin="Maintenance"] input:not([type="button"]):not([type="submit"]):not([type="reset"]){ border-color: #74abd2; }
.gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] h1, .gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] h2, .gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] h3, .gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] h4, .gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] h5, .gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] h6, .gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] .gdlr-core-skin-title a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] .gdlr-core-skin-caption a:hover{ color: #d6d6d6 }
.gdlr-core-page-builder-body [data-skin="Blue Divider White Text"], .gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] .gdlr-core-skin-content{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] .gdlr-core-skin-divider{ border-color: #2d9bea; column-rule-color: #2d9bea; -moz-column-rule-color: #2d9bea; -webkit-column-rule-color: #2d9bea; }.gdlr-core-page-builder-body [data-skin="Blue Divider White Text"] .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a{ border-color: #2d9bea }
.gdlr-core-page-builder-body [data-skin="Light Border"] .gdlr-core-skin-divider{ border-color: #ebebeb; column-rule-color: #ebebeb; -moz-column-rule-color: #ebebeb; -webkit-column-rule-color: #ebebeb; }.gdlr-core-page-builder-body [data-skin="Light Border"] .gdlr-core-flexslider.gdlr-core-nav-style-middle-large .flex-direction-nav li a{ border-color: #ebebeb }
.gdlr-core-page-builder-body [data-skin="Light Border"] *, .gdlr-core-page-builder-body [data-skin="Light Border"] .gdlr-core-skin-border{ border-color: #ebebeb }.gdlr-core-page-builder-body [data-skin="Light Border"] input:not([type="button"]):not([type="submit"]):not([type="reset"]){ border-color: #ebebeb; }
.gdlr-core-page-builder-body [data-skin="Purple Button"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Purple Button"] .gdlr-core-button-color{ background-color: #f36e29 }.gdlr-core-page-builder-body [data-skin="Purple Button"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Purple Button"] input[type="submit"]{ background-color: #f36e29; }.gdlr-core-page-builder-body [data-skin="Purple Button"] .gdlr-core-pagination a{ background-color: #f36e29 }
.gdlr-core-page-builder-body [data-skin="Purple Button"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="Purple Button"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Purple Button"] .gdlr-core-button-color.gdlr-core-active{ background-color: #ff9c2b }.gdlr-core-page-builder-body [data-skin="Purple Button"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Purple Button"] input[type="submit"]:hover{ background-color: #ff9c2b; }.gdlr-core-page-builder-body [data-skin="Purple Button"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Purple Button"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="Purple Button"] .gdlr-core-pagination span{ background-color: #ff9c2b }
.gdlr-core-page-builder-body [data-skin="Purple section 1"] h1, .gdlr-core-page-builder-body [data-skin="Purple section 1"] h2, .gdlr-core-page-builder-body [data-skin="Purple section 1"] h3, .gdlr-core-page-builder-body [data-skin="Purple section 1"] h4, .gdlr-core-page-builder-body [data-skin="Purple section 1"] h5, .gdlr-core-page-builder-body [data-skin="Purple section 1"] h6, .gdlr-core-page-builder-body [data-skin="Purple section 1"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Purple section 1"] .gdlr-core-skin-title a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Purple section 1"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Purple section 1"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Purple section 1"] .gdlr-core-skin-caption a:hover{ color: #bfbdff }
.gdlr-core-page-builder-body [data-skin="Purple section 1"], .gdlr-core-page-builder-body [data-skin="Purple section 1"] .gdlr-core-skin-content{ color: #b8b7e7 }
.gdlr-core-page-builder-body [data-skin="Portfolio"] h1, .gdlr-core-page-builder-body [data-skin="Portfolio"] h2, .gdlr-core-page-builder-body [data-skin="Portfolio"] h3, .gdlr-core-page-builder-body [data-skin="Portfolio"] h4, .gdlr-core-page-builder-body [data-skin="Portfolio"] h5, .gdlr-core-page-builder-body [data-skin="Portfolio"] h6, .gdlr-core-page-builder-body [data-skin="Portfolio"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Portfolio"] .gdlr-core-skin-title a{ color: #111111 }
.gdlr-core-page-builder-body [data-skin="Portfolio"] .gdlr-core-skin-e-background{ background-color: #ffffff }.gdlr-core-page-builder-body [data-skin="Portfolio"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a, .gdlr-core-page-builder-body [data-skin="Portfolio"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Portfolio"] .gdlr-core-flexslider .flex-control-nav li a{ border-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Portfolio"] .gdlr-core-flexslider .flex-control-nav li a.flex-active{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Portfolio"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="Portfolio"] textarea{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Portfolio"] .gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a{ background-color: #ffffff; }
.gdlr-core-page-builder-body [data-skin="Contact Homepage"] h1, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] h2, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] h3, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] h4, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] h5, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] h6, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-skin-title a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-skin-e-background{ background-color: #ffffff }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-flexslider .flex-control-nav li a{ border-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-flexslider .flex-control-nav li a.flex-active{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="Contact Homepage"] textarea{ background-color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a{ background-color: #ffffff; }
.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-skin-e-content{ color: #6b6b6b }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-flexslider-nav.gdlr-core-round-style li a i, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-flexslider-nav.gdlr-core-rectangle-style li a i{ color: #6b6b6b; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] input:not([type="button"]):not([type="submit"]):not([type="reset"]), .gdlr-core-page-builder-body [data-skin="Contact Homepage"] textarea{ color: #6b6b6b; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] ::-webkit-input-placeholder{ color: #6b6b6b; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] ::-moz-placeholder{ color: #6b6b6b; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] :-ms-input-placeholder{ color: #6b6b6b; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] :-moz-placeholder{ color: #6b6b6b; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"]  .gdlr-core-flexslider.gdlr-core-bottom-nav-1 .flex-direction-nav li a{ color: #6b6b6b; }
.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button-color{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Contact Homepage"] input[type="submit"]{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-pagination a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button-color.gdlr-core-active{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] input[type="submit"]:hover{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-pagination span{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button-color{ background-color: #000000 }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Contact Homepage"] input[type="submit"]{ background-color: #000000; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-pagination a{ background-color: #000000 }
.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button:hover, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button-color:hover, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button-color.gdlr-core-active{ background-color: #3433b8 }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] input[type="button"]:hover, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] input[type="submit"]:hover{ background-color: #3433b8; }.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-pagination a:hover, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-pagination a.gdlr-core-active, .gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-pagination span{ background-color: #3433b8 }
.gdlr-core-page-builder-body [data-skin="Purple Title"] h1, .gdlr-core-page-builder-body [data-skin="Purple Title"] h2, .gdlr-core-page-builder-body [data-skin="Purple Title"] h3, .gdlr-core-page-builder-body [data-skin="Purple Title"] h4, .gdlr-core-page-builder-body [data-skin="Purple Title"] h5, .gdlr-core-page-builder-body [data-skin="Purple Title"] h6, .gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-skin-title, .gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-skin-title a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-skin-caption, .gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-skin-caption a, .gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-skin-caption a:hover{ color: #e5e5e5 }
.gdlr-core-page-builder-body [data-skin="Purple Title"], .gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-skin-content{ color: #dddddd }
.gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-button-color{ color: #ffffff }.gdlr-core-page-builder-body [data-skin="Purple Title"] input[type="button"], .gdlr-core-page-builder-body [data-skin="Purple Title"] input[type="submit"]{ color: #ffffff; }.gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-pagination a{ color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-button, .gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-button-color{ border-color: #ffffff }.gdlr-core-page-builder-body [data-skin="Purple Title"] .gdlr-core-pagination a{ border-color: #ffffff }
.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button.gdlr-core-button-gradient{ background: -webkit-linear-gradient(#f26c29, #ff9c2b); background: -o-linear-gradient(#f26c29, #ff9c2b);  background: -moz-linear-gradient(#f26c29, #ff9c2b); background: linear-gradient(#f26c29, #ff9c2b); }
.gdlr-core-page-builder-body [data-skin="Dark"] .gdlr-core-button.gdlr-core-button-gradient-v{ background: -webkit-linear-gradient(to right, #f26c29, #ff9c2b); background: -o-linear-gradient(to right, #f26c29, #ff9c2b);  background: -moz-linear-gradient(to right, #f26c29, #ff9c2b); background: linear-gradient(to right, #f26c29, #ff9c2b); }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button.gdlr-core-button-gradient{ background: -webkit-linear-gradient(#000000, #ff9c2b); background: -o-linear-gradient(#000000, #ff9c2b);  background: -moz-linear-gradient(#000000, #ff9c2b); background: linear-gradient(#000000, #ff9c2b); }
.gdlr-core-page-builder-body [data-skin="White Text"] .gdlr-core-button.gdlr-core-button-gradient-v{ background: -webkit-linear-gradient(to right, #000000, #ff9c2b); background: -o-linear-gradient(to right, #000000, #ff9c2b);  background: -moz-linear-gradient(to right, #000000, #ff9c2b); background: linear-gradient(to right, #000000, #ff9c2b); }
.gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button.gdlr-core-button-gradient{ background: -webkit-linear-gradient(#232323, #232323); background: -o-linear-gradient(#232323, #232323);  background: -moz-linear-gradient(#232323, #232323); background: linear-gradient(#232323, #232323); }
.gdlr-core-page-builder-body [data-skin="Blue"] .gdlr-core-button.gdlr-core-button-gradient-v{ background: -webkit-linear-gradient(to right, #232323, #232323); background: -o-linear-gradient(to right, #232323, #232323);  background: -moz-linear-gradient(to right, #232323, #232323); background: linear-gradient(to right, #232323, #232323); }
.gdlr-core-page-builder-body [data-skin="Purple Button"] .gdlr-core-button.gdlr-core-button-gradient{ background: -webkit-linear-gradient(#f36e29, #ff9c2b); background: -o-linear-gradient(#f36e29, #ff9c2b);  background: -moz-linear-gradient(#f36e29, #ff9c2b); background: linear-gradient(#f36e29, #ff9c2b); }
.gdlr-core-page-builder-body [data-skin="Purple Button"] .gdlr-core-button.gdlr-core-button-gradient-v{ background: -webkit-linear-gradient(to right, #f36e29, #ff9c2b); background: -o-linear-gradient(to right, #f36e29, #ff9c2b);  background: -moz-linear-gradient(to right, #f36e29, #ff9c2b); background: linear-gradient(to right, #f36e29, #ff9c2b); }
.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button.gdlr-core-button-gradient{ background: -webkit-linear-gradient(#000000, #3433b8); background: -o-linear-gradient(#000000, #3433b8);  background: -moz-linear-gradient(#000000, #3433b8); background: linear-gradient(#000000, #3433b8); }
.gdlr-core-page-builder-body [data-skin="Contact Homepage"] .gdlr-core-button.gdlr-core-button-gradient-v{ background: -webkit-linear-gradient(to right, #000000, #3433b8); background: -o-linear-gradient(to right, #000000, #3433b8);  background: -moz-linear-gradient(to right, #000000, #3433b8); background: linear-gradient(to right, #000000, #3433b8); }

.infinite-item-mgb, .gdlr-core-item-mgb{ margin-bottom: 40px; }

.infinite-body-outer-wrapper, body.infinite-full .infinite-body-wrapper, .infinite-header-side-content {
 background-color: #f5f6fb;
}


