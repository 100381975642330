@media only screen and (max-width: 767px) {
    .gdlr-core-pbf-wrapper-1 {
        margin-top: 0 !important;
    }
}

a.gdlr-read-more {
    font-size: 13px;
    letter-spacing: 0;
    padding: 0 0 0 0;
    color: #f36e29 !important;
    text-transform: none;
    border-radius: 0 !important;
}

a.gdlr-read-more:hover {
    color: #f28752;
}

.ql-indent-1 {
    margin-left: 28px;
    list-style-type: circle;
    margin-bottom: 2px;
}

.infinite-header-background, .infinite-sticky-menu-placeholder, .infinite-header-style-boxed.infinite-fixed-navigation {
    background-color: #143655;
}

.infinite-header-background-transparent .infinite-header-background {
    background-color: #143655;

    opacity: .65;
    border-bottom: 1px solid #dee1e3;
    box-shadow: 0 1px rgb(42 55 69 / 65%);
}

.sf-menu > .infinite-normal-menu li, .sf-menu > .infinite-mega-menu > .sf-mega, .sf-vertical ul.sub-menu li, ul.sf-menu > .menu-item-language li {
    background-color: rgba(20, 54, 85, 0.65);
    border: 1px solid #dee1e3;
    border-top: none;
}

.infinite-page-wrapper {
    padding-top: 0;
}

.infinite-body {
    color: #000;
}

h3 {
    font-size: 36px;
    letter-spacing: 1px;
    color: #000
}

h1, h2, h3, h4, h5, h6 {
    color: #000
}

.white-logo {
    display: none;
}

.infinite-header-background, .infinite-sticky-menu-placeholder, .infinite-header-style-boxed.infinite-fixed-navigation {
    .blue-logo {
        display: none;
    }

    .white-logo {
        display: inline;
    }
}

.blue-logo {
    display: none;
}

.white-logo {
    display: inline;
}

table {
    td, th {
        color: #000;
    }
}

.gdlr-core-title-item .gdlr-core-title-item-title-wrap {
    text-align: left;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title {
    padding: 18px 27px 14px;
}

.gdlr-core-tab-style1-horizontal .gdlr-core-tab-item-title {
    padding: 18px 27px 14px;
}

.ql-align-center {
    text-align: center !important;
}

.infinite-body input, .infinite-body textarea, .woocommerce form .select2-selection {
    background-color: #fff;
}

.gdlr-core-pbf-wrapper {
    padding: 60px 0 30px;
}

.gdlr-core-pbf-wrapper {
    padding: 60px 0 0;
}

.gdlr-core-body .gdlr-core-button-with-border {
    border-color: #fff;
}

.gdlr-core-body .gdlr-core-button-with-border:hover {
    border-color: #fff;
    background: #fff;
    color: #143655;
}

.infinite-footer-wrapper {
    background-color: #0d253b;
    color: #fff;
}

.infinite-copyright-wrapper {
    background-color: #081826;
}

.top_warning_bar {
    width: 100%;
    height: 40px;
    overflow: hidden;
    position: relative;
    line-height: 40px;
    font-weight: bold;
    font-size: 17px;
    color: #fff;
    background: #A53131;

    a {
        font-weight: bold;
        font-size: 14px;
        color: #fff;
    }

    .warning-banner {
        background: #A53131;
        height: 50px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 0;

        a:hover {
            color: #ff9494;
        }
    }
}

.top-menu-search {
    padding-top: 2px;

    .infinite-main-menu-search {
        margin-top: 0;
        margin-left: 0;
    }
}

.top-menu-port {
    margin-left: 150px;
}

.r0 {
    right: 0;
}

.pr {
    position: relative;
}

.contact-page {
    .block-html-body {
        padding: 40px 0 0 0 !important;
    }

    .block-icons-with-text {
        padding: 30px 0 0 !important;

        .icons-with-text-row {
            padding-top: 0 !important;
        }
    }
    .gdlr-core-icon-item {
        padding-bottom: 35px !important;
    }

    .gdlr-core-item-pdb {
        padding-bottom: 16px !important;
    }

    .gdlr-core-text-box-item {
        padding-bottom: 0 !important;
    }

    .gdlr-core-text-box-item-content {
        p:last-child {
            margin-bottom: 0 !important;
        }
    }
}


.infinite-body .block-html-body-bg {
    * {
        color: inherit ;
    }
}


